<script lang="ts" setup>
import { useUserInfoStore } from '@/store/user/userInfo'
import { selectRandomTip } from '@/areas/dashboard/helpers/tips'
import HighlightPartial from '@/areas/dashboard/pages/index/partial/HighlightPartial.vue'
import ClipForm from '@/areas/dashboard/components/ClipForm.vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import ResultVideosList from '@/areas/dashboard/components/ResultVideos/ResultVideosList.vue'
import { useTimeBasedGreetings } from '@/areas/dashboard/hooks/useTimeBasedGreetings'
import ScheduledPostsList from '@/areas/dashboard/components/ScheduledPosts/ScheduledPostsList.vue'
import { videosFilter, videosFilterOptions } from '@/Hooks/useFilterVideos'
import { useUpcomingPosts } from '@/areas/dashboard/hooks/useUpcomingPosts'
import { useHead } from '@unhead/vue'
import WithUpgradeOverlay from '@/areas/dashboard/components/WithUpgradeOverlay.vue'
import settings from '@/data/settings'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import BlogPostsSection from '@/areas/dashboard/components/BlogPosts/BlogPostsList.vue'
import DashboardTwitchClipsGrid from '@/areas/dashboard/components/DashboardTwitchClipsGrid.vue'
import PatchNotesDialog from '@/components-v2/data-display/PatchNotesDialog.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'

const userInfo = useUserInfoStore()

const greeting = useTimeBasedGreetings()
const { isLoadingPosts, upcomingPosts } = useUpcomingPosts()

useHead({
  title: 'Dashboard',
  meta: [
    {
      name: 'description',
      content: 'Keep track of your clips, schedule posts and more.',
    },
  ],
})
</script>
<template>
  <main class="flex flex-col gap-8 p-4 lg:gap-16 lg:p-12">
    <div class="flex flex-wrap items-stretch gap-6">
      <header class="flex w-full shrink-0 flex-col">
        <h1 class="text-3xl lg:text-5xl">
          {{ greeting }},
          <span class="font-bold">{{ userInfo.userName }}!</span>
        </h1>
        <p class="font-light text-opacity-50">
          {{ selectRandomTip() }}
        </p>
      </header>

      <section
        class="min-w-3/4 flex max-w-full shrink-0 grow flex-col gap-4 rounded-xl bg-gradient-to-br from-purple-600 to-indigo-500 p-6 md:p-10 lg:p-14"
      >
        <header>
          <h3 class="leading-1 py-0 font-semibold !text-white">Clip Editor</h3>
          <p class="font-light text-white">Convert your clips to viral TikTok, Reels and YouTube Shorts</p>
        </header>
        <ClipForm />
      </section>

      <a
        class="block h-full w-full max-w-full flex-1 lg:min-w-[400px]"
        href="https://emotes.streamladder.com/"
        target="_blank"
      >
        <HighlightPartial
          badge="Free!"
          call-to-action="Create animated emotes"
          gradient="bg-gradient-to-br from-[#F66565] to-[#FFB21D]"
          subtitle="Perfect to add that personal touch to your stream"
          title="EmoteMaker"
        />
      </a>

      <RouterLink
        :to="{ name: dashboardRouteNames.montageMaker }"
        class="block h-full w-full max-w-full flex-1 lg:min-w-[400px]"
      >
        <HighlightPartial
          badge="BETA"
          call-to-action="Create a montage"
          gradient="bg-gradient-to-br from-[#65A8F6] to-[#2F1DFF]"
          subtitle="Combine your favorite video clips into a montage"
          title="MontageMaker"
        />
      </RouterLink>
    </div>

    <section class="flex flex-col items-center gap-4">
      <DashboardTwitchClipsGrid :rows="2" />
    </section>

    <WithUpgradeOverlay
      feature="scheduler"
      subtitle="Effortlessly plan and schedule your content with a simple click. It's that straightforward!"
      title="Plan and schedule your content"
    >
      <div class="flex flex-col gap-4">
        <section class="flex flex-wrap items-center justify-between gap-2">
          <header class="flex flex-col">
            <h2 class="text-2xl lg:text-3xl">Scheduled posts</h2>
            <p class="font-light text-opacity-50">These posts are scheduled and waiting to be published.</p>
          </header>

          <RouterLink
            v-if="!isLoadingPosts && upcomingPosts.length > 0"
            :to="{ name: dashboardRouteNames.contentPublisher }"
            class="shadow-lg"
          >
            <GradientButton>
              New post
              <IconSaxAdd />
            </GradientButton>
          </RouterLink>
        </section>

        <ScheduledPostsList />
      </div>
    </WithUpgradeOverlay>

    <WithUpgradeOverlay
      feature="save-video"
      subtitle="Unlock extended clip storage. With Silver & Gold Membership, your clips are safe for a whole week!"
      title="Upgrade today and save your clips for 7 days"
    >
      <div class="flex flex-col gap-4">
        <section class="flex flex-wrap items-center justify-between gap-2">
          <header class="flex flex-col">
            <h2 class="text-2xl lg:text-3xl">My Videos</h2>
            <p class="font-light text-opacity-50">Below are the videos you created in the last 7 days.</p>
          </header>

          <label class="flex items-center gap-2">
            <span class="text-sm font-light">Filter:</span>
            <SelectDropdown v-model="videosFilter" :options="videosFilterOptions" class="ml-auto" />
          </label>
        </section>

        <ResultVideosList single-row />
      </div>
    </WithUpgradeOverlay>

    <section
      class="flex flex-col justify-between gap-12 rounded-xl bg-gradient-to-br from-[#F66565] to-[#B665F6] px-12 py-8 text-white lg:flex-row-reverse lg:justify-center lg:px-16 lg:py-12"
    >
      <div class="flex flex-col justify-between gap-3 sm:flex-row lg:flex-col">
        <div class="flex flex-col">
          <p class="text-5xl">300k+</p>
          <p class="whitespace-nowrap text-sm font-light uppercase">in our community</p>
        </div>
        <div class="flex flex-col">
          <p class="text-5xl">200k+</p>
          <p class="whitespace-nowrap text-sm font-light uppercase">monthly clips created</p>
        </div>
        <div class="flex flex-col">
          <p class="text-5xl">968M+</p>
          <p class="whitespace-nowrap text-sm font-light uppercase">views on social media</p>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <ol class="avatar-group -space-x-6">
          <li class="layer-1 avatar aspect-square h-16 w-16">
            <img alt="Ninja" height="72" loading="lazy" src="/images/user-avatars/ninja.png" width="72" />
          </li>
          <li class="layer-1 avatar aspect-square h-16 w-16">
            <img alt="gmhikaru" height="70" loading="lazy" src="/images/user-avatars/gmhikaru.png" width="70" />
          </li>
          <li class="layer-1 avatar aspect-square h-16 w-16">
            <img alt="phonecats" height="70" loading="lazy" src="/images/user-avatars/phonecats.png" width="70" />
          </li>
          <li class="layer-1 avatar aspect-square h-16 w-16">
            <img alt="Teddy127" height="70" loading="lazy" src="/images/user-avatars/teddy-127.png" width="70" />
          </li>
        </ol>

        <header class="flex max-w-[50ch] flex-col gap-2">
          <h3 class="m-0 p-0 text-3xl leading-10 !text-white">Join the community of Streamers that wants to grow.</h3>
          <p class="font-light !text-white">
            Connect with fellow Streamers! Explore creative insights, share ideas, and grow your channel.
          </p>
        </header>

        <a
          :href="settings.discordInviteUrl"
          class="flex items-center gap-2 self-start rounded-xl bg-white px-6 py-2 font-bold text-indigo-500 transition-all hover:text-indigo-600 active:scale-90"
          target="_blank"
        >
          <DiscordLogo class="h-6 w-6 fill-current" />
          Join our Discord
        </a>
      </div>
    </section>

    <BlogPostsSection />

    <PatchNotesDialog />
  </main>
</template>

<style lang="scss" scoped></style>
