import { defineStore, acceptHMRUpdate } from 'pinia'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'

export const FocusTypes = {
  NONE: 'none',
  TEXTSTICKER: 'textsticker',
  CAPTION: 'caption',
  FEED: 'feed',
  SEGMENT: 'segment',
  STICKER: 'sticker',
  ZOOM: 'zoom',
  CROP: 'crop',
} as const
export type FocusType = (typeof FocusTypes)[keyof typeof FocusTypes]

type FocusTarget = {
  type: FocusType
  key?: string
}

interface EditorFocusState {
  focus: FocusTarget | null
  selectedCaptions: string[]
}

export const useEditorFocusStore = defineStore('editorFocus', {
  state: (): EditorFocusState => {
    return {
      focus: null,
      selectedCaptions: [],
    }
  },
  getters: {
    canDeleteFocusModel(state) {
      if (state.focus?.type === FocusTypes.FEED) return false
      const editorFeedDataStore = useEditorFeedDataStore()
      if (state.focus?.type === FocusTypes.SEGMENT && state.focus.key && editorFeedDataStore.segments.length <= 1)
        return false
      return state.focus?.key !== undefined
    },
  },
  actions: {
    setFocus(type: FocusType, key?: string) {
      this.focus = { type, key }
      if (type === FocusTypes.CAPTION && key) {
        this.selectedCaptions = [key]
      }
    },
    unFocus() {
      // console.trace('unFocus')
      this.focus = null
    },
    deleteFocusModel() {
      if (this.focus?.type === FocusTypes.CAPTION && this.focus.key) {
        const editorCaptionsStore = useEditorCaptionsStore()
        editorCaptionsStore.deleteCaption(this.focus.key)
        this.unFocus()
      }
      if ((this.focus?.type === FocusTypes.STICKER || this.focus?.type === FocusTypes.TEXTSTICKER) && this.focus.key) {
        const editorStickerStore = useEditorStickersStore()
        editorStickerStore.removeSticker(this.focus.key)
        this.unFocus()
      }
      if (this.focus?.type === FocusTypes.SEGMENT && this.focus.key) {
        const editorFeedStore = useEditorFeedDataStore()
        editorFeedStore.removeSegment(this.focus.key)
        this.unFocus()
      }
      if (this.focus?.type === FocusTypes.ZOOM && this.focus.key) {
        const editorFeedStore = useEditorFeedDataStore()
        editorFeedStore.removeZoom(this.focus.key)
      }
    },
  },
})

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useEditorFocusStore, import.meta.hot))
}
