<script lang="ts" setup>
import type { Clip } from '@/Hooks/useTwitchClips'
import logging from '@/logging'
import { ref } from 'vue'

defineProps<{
  clip?: Clip | null | undefined
  isLoading?: boolean
  disablePreview?: boolean
  overlayScaling?: string
}>()

const emit = defineEmits<{
  (name: 'select', clip: Clip): void
  (name: 'preview', clip: Clip): void
}>()

function select(clip: Clip) {
  logging.trackEvent('Twitch Clip Selected')
  emit('select', clip)
}

function preview(clip: Clip) {
  logging.trackEvent('Twitch Clip Previewed')
  emit('preview', clip)
}

const loaded = ref(false)
</script>

<template>
  <article class="flex flex-col gap-3">
    <figure
      :class="{ skeleton: isLoading }"
      class="layer-0 group relative flex aspect-video flex-col overflow-hidden rounded-xl transition-transform hover:scale-105"
    >
      <img
        v-if="clip"
        :alt="`Thumbnail of '${clip.title}'`"
        :class="loaded ? 'scale-100 opacity-100' : 'scale-90 opacity-0'"
        :src="clip.thumbnailUrl"
        class="aspect-video h-full w-full rounded-xl object-contain transition-all"
        @load="loaded = true"
      />

      <span
        v-if="clip && !disablePreview"
        class="pointer-events-none absolute inset-1 top-auto flex items-end justify-between text-xs text-zinc-200"
      >
        <span class="flex rounded-lg bg-zinc-950/75 px-2 py-1">
          {{ clip.viewCount }} {{ clip.viewCount === 1 ? 'view' : 'views' }}
        </span>

        <span v-if="clip.dateFromNow" class="flex rounded-lg bg-zinc-950/75 px-2 py-1">
          {{ clip.dateFromNow }}
        </span>
      </span>

      <div
        v-if="clip"
        class="pointer-events-none absolute inset-0 h-full w-full rounded-[10px] bg-black/25 opacity-0 transition-all group-hover:pointer-events-auto group-hover:opacity-100"
      >
        <div
          :class="overlayScaling ?? 'scale-[0.8]'"
          class="flex h-full w-full origin-center flex-col items-center justify-center gap-2"
        >
          <div
            class="hover:shadow-gradient group h-12 w-48 rounded-full shadow-xl transition-all before:transition-all"
          >
            <div class="bg-gradient w-full rounded-full">
              <button
                class="btn-lg btn w-full gap-2 bg-black text-lg font-bold text-white mix-blend-lighten"
                @click="select(clip)"
              >
                Select clip
              </button>
            </div>
          </div>

          <button
            v-if="!disablePreview"
            class="btn-outline btn-lg btn w-48 border-white text-white"
            @click="preview(clip)"
          >
            Preview
          </button>
        </div>
      </div>
    </figure>

    <header v-if="!disablePreview" class="flex flex-col gap-2">
      <h6
        :class="{
          skeleton: isLoading,
          'layer-0 w-4/5 shrink-0 overflow-hidden rounded-xl': !clip,
        }"
        class="line-clamp-2 min-h-[1em] text-lg font-semibold leading-[1] dark:text-zinc-100"
      >
        {{ clip?.title }}
      </h6>
    </header>
  </article>
</template>

<style lang="scss" scoped></style>
