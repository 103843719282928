<script lang="ts" setup>
import { useUserInfoStore } from '@/store/user/userInfo'
import ClipForm from '@/areas/dashboard/components/ClipForm.vue'
import { useRouter } from 'vue-router'
import { useTimeBasedGreetings } from '@/areas/dashboard/hooks/useTimeBasedGreetings'
import { useHead } from '@unhead/vue'
import DashboardTwitchClipsGrid from '@/areas/dashboard/components/DashboardTwitchClipsGrid.vue'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import IconSaxLoginCurve from '@/components/Icons/iconsax/IconSaxLoginCurve.vue'
import EventBus from '@/eventBus'
import authEvents from '@/events/authEvents'

const greeting = useTimeBasedGreetings()
const router = useRouter()
const userInfo = useUserInfoStore()

useHead({
  title: 'Clip Editor',
  meta: [
    {
      name: 'description',
      content: 'Convert your clips to viral TikToks, Instagram Reels and YouTube Shorts',
    },
  ],
})

function login() {
  EventBus.$emit(authEvents.OPEN_LOGIN_DIALOG)
}
</script>

<template>
  <main>
    <header
      class="flex w-full flex-col items-center gap-8 rounded-t-xl bg-gradient-to-br from-purple-600 to-indigo-500 p-4 sm:p-8 lg:p-24"
    >
      <div class="flex flex-col items-center gap-1">
        <h1 v-if="userInfo.isAuthenticated" class="text-center text-3xl !text-zinc-100 lg:text-5xl">
          {{ greeting }},
          <span class="font-bold">{{ userInfo.userName }}!</span>
        </h1>
        <h1 v-else class="text-center text-3xl !text-zinc-100 lg:text-5xl">
          {{ greeting }}!
        </h1>
        <p class="font-light text-zinc-100">Start by selecting a clip</p>
      </div>
      <ClipForm class="mx-auto w-full max-w-[720px] self-stretch" />
    </header>

    <section class="flex flex-col gap-4 px-6 py-8 lg:p-12">
      <DashboardTwitchClipsGrid v-if="userInfo.isAuthenticated" />
      <NoContentSection
        v-else
        subtitle="Log in to access your Twitch clips and start editing!"
        title="Please login to view your clips"
      >
        <div class="flex items-center">
          <GradientButton @click="login">
            Login
            <IconSaxLoginCurve class="shrink-0" />
          </GradientButton>
        </div>
      </NoContentSection>
    </section>
  </main>
</template>

<style lang="scss" scoped></style>
