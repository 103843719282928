import { streamLadderAxios, publicAxios } from '@/services/axios'
import type {S3RequestUploadResult} from "@/apis/swagger-api-streamladder";

export default {
  /**
   * @returns {Promise<SignedS3Result>} signedUrlResult
   */
  async getUploadResultSignedUrl() {
    const res = await streamLadderAxios.get<S3RequestUploadResult>('/api/Upload/ResultUploadUrl')
    if (res.status === 200) {
      return res.data
    } else {
      throw new Error(`Get SignedURL for ResultUpload failed with code '${res.status}', message: ${res.data}`)
    }
  },
  /**
   * @returns {Promise<SignedS3Result>} signedUrlResult
   */
  async getDebugSignedUrl() {
    const res = await streamLadderAxios.get('api/Upload/DebugBucketUploadUrl')
    if (res.status === 200) {
      return res.data
    } else {
      throw new Error(`Get SignedURL for ResultUpload failed with code '${res.status}', message: ${res.data}`)
    }
  },
  /**
   * @returns {Promise<SignedS3Result>} signedUrlResult
   */
  async getImportFileSignedUrl() {
    const res = await streamLadderAxios.get('/api/Upload/ImportUploadUrl')
    if (res.status === 200) {
      return res.data
    } else {
      throw new Error(`Get SignedURL for ImportUpload failed with code '${res.status}', message: ${res.data}`)
    }
  },
  /**
   * @returns {Promise<SignedS3Result>} signedUrlResult
   */
  async getUploadCustomStickerSignedUrl() {
    const res = await streamLadderAxios.get('/api/Upload/CustomStickerUploadUrl')
    if (res.status === 200) {
      return res.data
    } else {
      throw new Error(`Get SignedURL for StickerUpload failed with code '${res.status}', message: ${res.data}`)
    }
  },
  /**
   * @returns {Promise<SignedS3Result>} signedUrlResult
   */
  async getClipAudioUploadSignedUrl(hash: string) {
    const res = await streamLadderAxios.get(`/api/Upload/ClipAudioUploadUrl?hash=${hash}`)
    if (res.status === 200) {
      return res.data
    } else {
      throw new Error(`Get SignedURL for AudioUpload failed with code '${res.status}', message: ${res.data}`)
    }
  },

  /**
   * @param {string} blobSasUrl
   * @param fileData
   * @param {function} onProgressCallback
   * @param {string} contentType
   * @param {string} attachmentFilename
   * @param {object} axiosOptions
   * @returns {Promise<AxiosResponse<any>>}
   */
  async uploadFileS3(
    blobSasUrl: string,
    fileData: any,
    onProgressCallback: (progress: number) => void,
    contentType = 'application/x-www-form-urlencoded',
    attachmentFilename = '',
    axiosOptions = {}
  ) {
    attachmentFilename = encodeURIComponent(attachmentFilename)

    const res = await publicAxios.put(blobSasUrl, fileData, {
      ...axiosOptions,
      withCredentials: false,
      headers: {
        'Content-Disposition': 'attachment' + (attachmentFilename ? `; filename="${attachmentFilename}"` : ''),
        'Content-Type': contentType,
      },
      onUploadProgress: (event) => onProgressCallback(Math.round((event.loaded / (event.total || 1)) * 100)),
    })

    return res
  },
}

/**
 * @typedef {Object} SignedS3Result
 * @prop {string} signedUrl
 * @prop {string} filename
 * @prop {string} resultUrl
 * */
