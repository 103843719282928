/**
 * Adds tracking capabilities to the Vue app.
 *
 * Note that the folder isn't called tracking, and files are not "ga.js" or "mixpanel.js", otherwise ad-blockers interfere with local development
 */

import { MixPanel } from './mix-panel'
import type { App } from 'vue'
import type { Router, NavigationHookAfter } from 'vue-router'
import type { UserInfoState, ApiUser } from '@/store/user/userInfo'
import { useUserInfoStore } from '@/store/user/userInfo'
import { GA } from '@/logging/g-a'
import { PostHog } from '@/logging/post-hog'
import type { UserDto } from '@/apis/streamladder-accounts/model'

const clients = [MixPanel, GA, PostHog] as Readonly<LoggingAdapter<unknown>[]>

export default {
  initialize(app, router) {
    if (window.location.pathname === '/sticker-render') {
      return
    }

    for (const client of clients) {
      client.initialize(app, router)
    }

    this.pageView(router.currentRoute.value, router.currentRoute.value)
    router.afterEach(this.pageView)

    this.router = router
  },

  trackEvent(eventName, properties) {
    const state = useUserInfoStore()
    const currentRoute = this.router?.currentRoute.value

    const propsWithPath = {
      ...properties,
      pageName: currentRoute?.name,
      pagePath: currentRoute?.fullPath,
    }

    for (const client of clients) {
      client.trackEvent(eventName, propsWithPath, state)
    }
  },

  /**
   * TODO identifyUser and registerUser are too similar, combine them?
   */
  identifyUser(id) {
    for (const client of clients) {
      client.identifyUser(id)
    }
  },

  registerUser(state) {
    for (const client of clients) {
      client.registerUser(state)
    }
  },

  reset() {
    for (const client of clients) {
      client.reset()
    }
  },

  pageView: (to, from, failure) => {
    if (typeof to.name !== 'string' || ['Generate', 'GenerateServerSide', 'StickerRender'].includes(to.name)) return
    for (const client of clients) {
      client.pageView(to, from, failure)
    }
  },
} as Logging

export interface Logging {
  /**
   * @private
   */
  router: Router

  /**
   * Initialize the tracking adapters
   */
  readonly initialize: (app: App<Element>, router: Router) => void

  /**
   * Track an event
   */
  readonly trackEvent: (eventName: string, properties?: Record<string, unknown>) => void

  /**
   * Identify a user by id
   */
  readonly identifyUser: (id: string) => void

  /**
   * Register user data
   */
  readonly registerUser: (user: ApiUser) => void

  /**
   * Reset user tracking when a person logs out for example
   */
  readonly reset: () => void

  /**
   * Track a page view
   */
  readonly pageView: NavigationHookAfter
}

export interface LoggingAdapter<T> extends Omit<Logging, 'trackEvent' | 'router'> {
  readonly trackEvent: (eventName: string, properties: T, state: UserInfoState) => void
}
