import { defineStore } from 'pinia'
import { useEntityStore } from './useEntityStore'
import { computed } from 'vue'
import { v4 as uuid } from 'uuid'

export function useLayoutsStoreSetup(initialState: Record<string, Layout> = {}) {

  const { state, ids, entities, operations } = useEntityStore(initialState)

  return {
    state: state,
    ids: ids,
    entities: entities,
    ...operations,

    selectNameById(id: string) {
      return computed({
        get() {
          return state[id].name
        },
        set(name: string) {
          state[id].name = name
        },
      })
    },

    selectCustomLayouts() {
      return this.where(l => l.isCustom)
    },

    updateEmojiById(id: string, emoji: string) {
      state[id].emoji = emoji
    },
  }
}

export const useLayoutsStore = defineStore('layouts', useLayoutsStoreSetup)
export type LayoutsStore = ReturnType<typeof useLayoutsStoreSetup>

const colorOptions = [
  '#fbf8cc', '#fde4cf', '#ffcfd2',
  '#f1c0e8', '#cfbaf0', '#a3c4f3',
  '#90dbf4', '#8eecf5', '#98f5e1',
  '#b9fbc0',
] as const

export function pickRandomColor() {
  return colorOptions[Math.floor(Math.random() * colorOptions.length)]
}

export function newLayout(): Layout {
  return {
    id: uuid(),
    name: 'New Layout',
    emoji: '🔥',
    color: pickRandomColor(),
    isCustom: true,
  }
}

export type Layout = {
  id: string
  name: string
  emoji: string
  color: typeof colorOptions[number]
  isCustom: boolean
}
