<script lang="ts" setup>
import IconSaxDocumentUpload from '@/components/Icons/iconsax/IconSaxDocumentUpload.vue'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import DashboardButton from '@/areas/dashboard/components/DashboardButton.vue'
import IconSaxTwitch from '@/components/Icons/iconsax/IconSaxTwitch.vue'
import { ref, onMounted } from 'vue'
import ClipsDialog from '@/components/Dialog/ClipsDialog.vue'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useRouter, useRoute } from 'vue-router'
import { useClipFormManager } from '@/Hooks/clip-form/useClipFormManager'
import ClipGoatPromotionModal from '@/pages/modals/ClipGoatPromotionModal.vue'
import LocalClipDialog from '@/components-v2/modules/HomePage/LocalClipDialog.vue'
import unwrap from '@/helpers/unwrap'
import AlertBox from '@/components-v2/data-display/AlertBox.vue'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import ClipUrlError from '@/Hooks/clip-form/ClipUrlError.vue'

const clipUrl = ref<string>()
const { requestLocalFileUpload, submitClipUrl, error, youtubeVideoId } = useClipFormManager()

const dialog = ref<ClipsDialog>()
const resetEditor = useResetEditor()
const router = useRouter()

async function onClipSelected(clipId: string) {
  resetEditor()
  await router.push({ name: 'editor', params: { clipId, source: 'twitch-clip' } })
}

const Query = {
  // Clip URL is given by WebFlow.
  CLIP_URL: 'clipUrl',
  // Search clips from Twitch is given by WebFlow
  SEARCH_FROM_TWITCH: 'searchFromTwitch',
  // Upload local file button is clicked on WebFlow.
  LOCAL_FILE: 'localFile',
}

const localClipDialog = ref(false)

function openClipsDialog() {
  if (dialog.value) {
    dialog.value.open()
  }
}

const route = useRoute()
onMounted(() => {

  const queryKeys = unwrap.keys(route.query) as (typeof Query)[keyof typeof Query][]
  const clipUrlQuery = route.query[Query.CLIP_URL] as string | undefined

  if (clipUrlQuery) {
    submitClipUrl(clipUrlQuery)
  } else if (queryKeys.includes(Query.SEARCH_FROM_TWITCH)) {
    openClipsDialog()
  } else if (queryKeys.includes(Query.LOCAL_FILE)) {
    localClipDialog.value = true
  }
})
</script>

<template>
  <section class="flex flex-col gap-4">
    <form
      class="flex items-stretch rounded-lg shadow-lg relative text-zinc-900"
      :class="{ 'outline outline-2 outline-offset-1 outline-rose-500': error }"
      @submit.prevent.stop="submitClipUrl(clipUrl)"
    >
      <div class="shrink-0 pr-0 absolute left-3 top-3 text-rose-500" v-if="error">
        <IconSaxInfoCircle />
      </div>
      <input
        v-model="clipUrl"
        :class="{ 'pl-11 text-rose-500': error }"
        class="min-w-0 p-3 flex-auto rounded-l-lg rounded-r-none bg-zinc-100 text-left font-light bg-transparent"
        placeholder="Paste the URL of a Twitch, YouTube, or Kick clip here"
        type="text"
      />
      <button
        class="group flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-l-none rounded-r-lg bg-emerald-500 px-6 py-3 text-zinc-900 transition-colors"
        type="submit"
      >
        <span class="hidden md:inline">Get clip</span>
        <IconSaxArrowRight2 class="h-4 w-4 transition-transform group-hover:translate-x-2 md:mr-2" />
      </button>
    </form>

    <ClipUrlError :error="error" />

    <div class="flex flex-wrap gap-2">
      <DashboardButton class="flex-auto" @click="requestLocalFileUpload">
        <IconSaxDocumentUpload />
        Upload from computer
      </DashboardButton>
      <DashboardButton class="flex-auto" @click="openClipsDialog">
        <IconSaxTwitch />
        Upload from Twitch
      </DashboardButton>
    </div>
    <ClipsDialog ref="dialog" @clipSelected="(clip) => onClipSelected(clip.id)" />

    <teleport to="body">
      <LocalClipDialog v-model="localClipDialog" />
    </teleport>
    <ClipGoatPromotionModal v-model="youtubeVideoId" />
  </section>
</template>

<style lang="scss" scoped>
input:placeholder-shown {
  text-overflow: ellipsis;
}
</style>
