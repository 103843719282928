<script setup lang="ts">
import type { TypedOutboundSocialAccount } from '@/store/user/userInfo'
import { computed, ref } from 'vue'
import ConnectionTypes from '@/enums/connectionTypes'
import { AccountState } from '@/apis/streamladder-accounts/model'
import IconSaxWarning2 from '@/components/Icons/iconsax/IconSaxWarning2.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import accountService from '@/services/accountService'
import { useConfirmDialog } from '@/components-v2/actions/dialog/useConfirmDialog'
import ProfileImage from '@/areas/dashboard/components/ProfileImage.vue'
import IconSaxRefresh from '@/components/Icons/iconsax/IconSaxRefresh.vue'
import DashboardButton from '@/areas/dashboard/components/DashboardButton.vue'
import useCreateSocialConnection from '@/Hooks/useCreateSocialConnection'
import connectionTypes from '@/enums/connectionTypes'

const props = defineProps<{ account: TypedOutboundSocialAccount }>()

const mustReconnectTikTokAccount = computed(() => {
  return props.account.type === ConnectionTypes.TIKTOK && !props.account.scopes.includes('user.info.stats')
})

const mustUpgradeToInstagramBusinessAccount = computed(() => {
  return props.account.type === ConnectionTypes.INSTAGRAM && props.account.state !== AccountState.active
})

const requiresAction = computed(() => mustReconnectTikTokAccount.value || mustUpgradeToInstagramBusinessAccount.value)

const confirmDialog = useConfirmDialog()

const isRemoving = ref<boolean>(false)

confirmDialog.onConfirm(async () => {
  isRemoving.value = true
  await accountService.revokeSocial(props.account.id, props.account.type)
  isRemoving.value = false
})

confirmDialog.onCancel(() => {
  isRemoving.value = false
})

async function remove() {
  await confirmDialog.reveal({
    title: `Are you sure you want to remove this account?`,
    message: 'This action cannot be undone, \nyou will have to reconnect your account manually.',
  })
}

const { connect } = useCreateSocialConnection()
async function reconnect() {
  await accountService.revokeSocial(props.account.id, props.account.type)
  await connect(connectionTypes.TIKTOK)
}
</script>

<template>
  <article class="layer-2 flex flex-col gap-4 rounded-xl px-6 py-4">
    <div class="grid grid-cols-2 items-center gap-4 md:grid-cols-3">
      <div class="col-span-2 flex items-center gap-4 lg:col-span-1">
        <ProfileImage :account="account" />

        <header class="flex flex-col">
          <h3 class="m-0 p-0 text-lg font-normal">
            <template v-if="account.type === ConnectionTypes.YOUTUBE">{{ account.displayName }}</template>
            <template v-else>@{{ account.displayName }}</template>
          </h3>
          <span class="text-sm font-light">
            <template v-if="account.type === ConnectionTypes.YOUTUBE">YouTube</template>
            <template v-if="account.type === ConnectionTypes.INSTAGRAM">Instagram</template>
            <template v-if="account.type === ConnectionTypes.TIKTOK">TikTok</template>
          </span>
          <span class="text-zinc-500 lg:hidden">
            <template v-if="account.state === 'active'">Connected</template>
            <template v-if="account.state === 'inactive'">Disconnected</template>
          </span>
        </header>
      </div>

      <span class="hidden place-self-center text-zinc-500 lg:inline">
        <template v-if="account.state === 'active'">Connected</template>
        <template v-if="account.state === 'inactive'">Disconnected</template>
      </span>

      <div class="col-span-2 flex items-center justify-between gap-2 md:col-span-1 lg:justify-self-end">
        <DashboardButton class="py-2.5" v-if="mustReconnectTikTokAccount" @click="reconnect">
          <IconSaxRefresh class="h-4 w-4" />
          Reconnect
        </DashboardButton>
        <button
          :disabled="isRemoving"
          @click="remove"
          data-tip="Remove social media connection"
          class="tooltip tooltip-left flex h-12 w-12 flex-col items-center justify-center rounded-xl text-red-400 transition-all hover:bg-zinc-100 active:scale-90 disabled:pointer-events-none dark:hover:bg-zinc-800"
        >
          <span v-if="isRemoving" class="spinner spinner-border !h-6 !w-6 border-2" />
          <IconSaxTrash v-else />
        </button>
      </div>
    </div>

    <div v-if="requiresAction" class="alert alert-warning mb-3 w-full bg-yellow-100 shadow-lg">
      <div class="flex w-full flex-row items-center gap-4">
        <IconSaxWarning2 class="shrink-0" />

        <p class="w-full max-w-full whitespace-pre-line break-all text-sm">
          <template v-if="mustReconnectTikTokAccount">
            Reconnect your TikTok account to keep publishing TikToks.
          </template>

          <template v-if="mustUpgradeToInstagramBusinessAccount">
            To post or schedule from Streamladder to Instagram, you need an Instagram Business account.
          </template>
        </p>
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss"></style>
