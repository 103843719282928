import EventBus from '@/eventBus'
import toastEvents from '@/events/toastEvents'
import ToastEvents from '@/events/toastEvents'

type ValueOf<T> = T[keyof T]

export const useToast = () => {
  const toast = (title: string, description: string, type: ValueOf<typeof ToastEvents> = ToastEvents.TOAST) => {
    EventBus.$emit(type, {
      title,
      description,
    })
  }

  return {
    toast,
  }
}
