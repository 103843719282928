import type { NotificationAdapter, Notification } from '@/modules/NotificationService/NotificationAdapter'
import { useQuery } from '@tanstack/vue-query'
import { SLVideoRendersAdapter } from '@/modules/NotificationService/adapters/SLVideoRendersAdapter'
import { useUserInfoStore } from '@/store/user/userInfo'
import { computed, type ComputedRef } from 'vue'
import { queryClient } from '@/services/QueryClient'
import { MontageAdapter } from '@/modules/NotificationService/adapters/MontageAdapter'

const adapters = [new SLVideoRendersAdapter(), new MontageAdapter()] satisfies NotificationAdapter[]

type NotificationTypes = (typeof adapters)[number]['name']

export const NotificationQueryKey = ['notifications']

export const useNotifications = () => {
  const userInfoStore = useUserInfoStore()
  const { data, isLoading, error } = useQuery<Notification[]>(
    NotificationQueryKey,
    async () => {
      const notifications = await Promise.all(adapters.map((adapter) => adapter.getUnreadNotifications()))
      return notifications.flat() as Notification[]
    },
    {
      enabled: computed(() => userInfoStore.isAuthenticated),
    }
  )

  const notifications = computed(() => data.value || []) as ComputedRef<Notification[]>

  return { notifications, isLoading, error }
}

export const useMarkNotificationAsReadByType = (type: NotificationTypes) => {
  const userInfoStore = useUserInfoStore()
  const adapter = adapters.find((a) => a.name === type)
  if (!adapter) throw new Error(`No adapter found for notification type: ${type}`)

  return async (id: string) => {
    if (userInfoStore.isAuthenticated) {
      return !(adapter instanceof MontageAdapter) && adapter?.markNotificationAsRead(id)
    }
  }
}

export const useMarkNotificationAsRead = () => {
  const userInfoStore = useUserInfoStore()
  return async (notificationId: string) => {
    if (userInfoStore.isAuthenticated) {
      for (const adapter of adapters) {
        if (notificationId.startsWith(adapter.getIdentifier())) {
          if (adapter instanceof MontageAdapter) {
            return
          } else {
            await adapter.markNotificationAsRead(notificationId)
            await queryClient.invalidateQueries(NotificationQueryKey)
          }
        }
      }
    }
  }
}

export const useMarkAllNotificationsAsRead = () => {
  const userInfoStore = useUserInfoStore()
  return async () => {
    if (userInfoStore.isAuthenticated) {
      for (const adapter of adapters) {
        if (!(adapter instanceof MontageAdapter)) {
          await adapter.markAllNotificationsAsRead()
        }
      }
      await queryClient.invalidateQueries(NotificationQueryKey)
    }
  }
}
