<script lang="ts" setup>
import { computed, ref } from 'vue'
import { range } from 'lodash-es'
import VideoPreview from '@/areas/dashboard/components/ResultVideos/ResultVideoPreview.vue'
import { type VideoResultDto } from '@/apis/streamladder-api/model'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import { videosFilter } from '@/Hooks/useFilterVideos'
import IconSaxVideo from '@/components/Icons/iconsax/IconSaxVideo.vue'
import { useGuard } from '@/Hooks/useGuard'
import MontageMakerVideo from '@/areas/dashboard/components/MontageMaker/MontageMakerVideo.vue'

const props = defineProps<{
  singleRow?: boolean
  selectedVideoUrls: string[]
  isLoadingVideos: boolean
  isLoadingPosts: boolean
  filteredVideos: VideoResultDto[]
  dataUpdatedAt: number
}>()

const emit = defineEmits<{
  (name: 'addToMontage', url: string): void
  (name: 'removeFromMontage', url: string): void
}>()

const previews = ref<VideoPreview[]>()
const previewVideos = computed<HTMLVideoElement[]>(() => {
  return (previews.value ?? []).map((p) => p.video).filter(Boolean)
})

function pauseOthers(id: string | null | undefined) {
  for (const video of previewVideos.value) {
    if (video.id !== id) {
      video.pause()
    }
  }
}

const isLoading = computed(() => props.isLoadingVideos)
const amountOfVideos = computed(() => {
  if (isLoading.value) {
    return 4
  } else if (props.singleRow) {
    return Math.min(4, props.filteredVideos.length)
  } else {
    return props.filteredVideos.length
  }
})

const canUseMontageMaker = useGuard('montage-maker')
</script>

<template>
  <div>
    <section v-if="!canUseMontageMaker">
      <ol class="single-row grid grid-cols-2 gap-4 lg:grid-cols-4 2xl:grid-cols-5">
        <li class="block">
          <MontageMakerVideo placeholder-src="/images/posters/placeholder-1.jpg" />
        </li>
        <li class="block">
          <MontageMakerVideo placeholder-src="/images/posters/placeholder-2.jpg" />
        </li>
        <li class="block">
          <MontageMakerVideo placeholder-src="/images/posters/placeholder-3.jpg" />
        </li>
        <li class="block">
          <MontageMakerVideo placeholder-src="/images/posters/placeholder-4.jpg" />
        </li>
      </ol>
    </section>

    <section v-else-if="amountOfVideos >= 2">
      <TransitionGroup
        :key="videosFilter + props.dataUpdatedAt"
        :class="{ 'grid-single-row': singleRow || isLoading }"
        appear
        class="grid grid-cols-2 gap-4 lg:grid-cols-4 2xl:grid-cols-5"
        name="grid"
        tag="ol"
      >
        <li v-for="i in range(amountOfVideos)" :key="i" class="block">
          <MontageMakerVideo
            ref="previews"
            :video="filteredVideos[i]"
            :selectedVideoUrls="selectedVideoUrls"
            @addToMontage="(e) => emit('addToMontage', e)"
            @removeFromMontage="(e) => emit('removeFromMontage', e)"
            @play="pauseOthers"
          />
        </li>
      </TransitionGroup>

      <div v-if="singleRow && !isLoading && amountOfVideos > 4" class="mt-4 flex flex-row justify-center gap-4">
        <RouterLink
          :to="{ name: dashboardRouteNames.videos }"
          class="mt-4 flex items-center gap-2 rounded-xl border border-current px-3 py-1.5 font-semibold transition-all hover:border-transparent hover:bg-zinc-800 hover:text-zinc-100 active:scale-90 active:bg-zinc-600/25 dark:hover:bg-zinc-200 dark:hover:text-zinc-800"
        >
          <IconSaxVideo />
          View all clips
        </RouterLink>
      </div>
    </section>

    <NoContentSection
      v-else
      title="You need at least 2 clips to create a montage."
      subtitle="Create additional clips with our editor to get your montage rolling!"
    >
      <RouterLink :to="{ name: dashboardRouteNames.editor }" class="shadow-lg">
        <GradientButton>
          Create a clip
          <IconSaxAdd />
        </GradientButton>
      </RouterLink>
    </NoContentSection>
  </div>
</template>

<style lang="scss"></style>
