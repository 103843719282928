import { mbToBytes } from '@/helpers/fileSize'
import compatabilityChecker from '@/services/compatabilityChecker'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import * as Sentry from '@sentry/browser'
import { last } from 'lodash-es'
import { useUserInfoStore } from '@/store/user/userInfo'
import { canGuard } from '@/Hooks/useGuard'
import localForage from 'localforage'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import type { RouteLocationRaw } from 'vue-router'

export function useLocalFileValidator() {
  const userInfoStore = useUserInfoStore()
  const editorClipInfoStore = useEditorClipInfoStore()

  return async function validateLocalFile(file: File) {
    const result = {
      error: null as { message: string; title: string } | null,
      route: null as RouteLocationRaw | null,
    }

    if (compatabilityChecker.canRenderClientside()) {
      if (file.size > mbToBytes(200)) {
        await userInfoStore.ensureUserIsLoggedIn()
        if (!canGuard('upload-large-files')) {
          upgradeDialog.open('upload-large-files')
          return result
        }
      }
    } else {
      await userInfoStore.ensureUserIsLoggedIn()
      if (!canGuard('server-side-rendering')) {
        upgradeDialog.open({
          title: 'Use StreamLadder on your phone',
          subtitle: 'To use StreamLadder on your phone, please upgrade to <strong>StreamLadder Silver</strong>',
          reason: 'Serverside Clip Upload',
        })
        return result
      }
    }

    try {
      await localForage.setItem('localFile', file)

      const blobUrl = URL.createObjectURL(file)
      const blobGuid = last(blobUrl.split('/')) ?? blobUrl

      editorClipInfoStore.$patch({
        id: blobGuid,
        title: file.name,
        mp4Url: blobUrl,
        isLocalFile: true,
        source: 'local-file',
      })

      result.route = { name: 'editor', params: { clipId: blobGuid, source: 'local-file' } }
    } catch (e) {
      Sentry.captureException(e)
      result.error = {
        title: 'Uh oh, something went wrong',
        message: "Could not store local file. Please check if the file you're uploading is not corrupt.",
      }
    }
    return result
  }
}
