<script lang="ts" setup>
import DashboardNavigation from '@/areas/dashboard/layout/nav/DashboardNavigation.vue'
import { useHead } from '@unhead/vue'
import { useColorMode } from '@vueuse/core'

useHead({
  bodyAttrs: {
    'data-theme': useColorMode(),
  },
})
</script>

<template>
  <main class="layer-0">
    <section class="dashboard mx-auto grid min-h-screen min-[1920px]:max-w-[1920px]">
      <section class="dashboard__view m-4 mt-0 lg:m-6 lg:ml-0">
        <div ref="view" class="layer-1 h-full w-full overflow-hidden rounded-2xl shadow-lg">
          <RouterView />
        </div>
      </section>

      <!-- Navigation is placed after view intentionally to ensure any flyouts overlap the view. Grid Area is used for placement -->
      <header class="dashboard__nav will-change-contents">
        <nav class="sticky top-0 z-[1] lg:h-screen">
          <DashboardNavigation class="p-4 lg:p-6" />
        </nav>
      </header>
    </section>
  </main>
</template>

<style lang="scss">
.dashboard {
  font-family: 'Metropolis', sans-serif;
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;
  grid-template-areas:
    'navigation'
    'view';

  @screen lg {
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'navigation view';
  }

  .dashboard__nav {
    grid-area: navigation;
  }

  .dashboard__view {
    grid-area: view;
    // For some reason this prevents the view from overflowing the grid.
    @screen lg {
      grid-column-end: none;
    }
  }

  .dashboard__footer {
    grid-area: footer;
  }
}
</style>
