<template>
  <Teleport to="body">
    <div
      v-if="showModal"
      :class="[$attrs.class, { scrollable: scrollable }]"
      class="modal-open modal"
      @click.stop="_onOverlayClick"
    >
      <div
        :class="{ 'max-w-md': size === 'small', 'max-w-4xl': size === 'large', [boxClass]: boxClass }"
        class="layer-1 modal-box max-h-[80vh] w-11/12"
      >
        <button
          v-if="closeable"
          class="btn-accent btn-ghost btn-sm btn-circle btn absolute right-2 top-2 dark:!text-white"
          type="button"
          @click="close"
        >
          x
        </button>
        <slot></slot>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  components: {},
  props: {
    class: {
      type: String,
      default: '',
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    blockEscape: {
      type: Boolean,
      default: false,
    },
    showDismissButton: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    boxClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
  mounted() {
    document.addEventListener('keyup', this._onDocumentKeyup)
  },
  methods: {
    open() {
      this.showModal = true
      document.body.classList.add('dialog-open')
    },
    close() {
      this.showModal = false
      document.body.classList.remove('dialog-open')
    },
    _onOverlayClick(event) {
      if (!this.closeable) return

      if (!event.target.classList || event.target.classList.contains('modal')) {
        this.close()
      }
    },
    _onDocumentKeyup(event) {
      if (!this.closeable || this.blockEscape) return

      if (event.keyCode == 27) {
        this.close()
      }
    },
  },
  unmounted() {
    document.removeEventListener('keyup', this._onDocumentKeyup)
  },
}
</script>

<style>
body.dialog-open {
  overflow: hidden;
}
</style>
