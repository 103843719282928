<template>
  <div
    v-show="shouldShow"
    @click="upgrade"
    :class="{
      'bg-gradient-to-br from-yellow-300 to-yellow-600 px-2': !small && requiredPlan === tiers.GOLD,
      'bg-gradient-to-br from-gray-100 to-gray-500 px-2': !small && requiredPlan === tiers.SILVER,
      'cursor-pointer': canClick,
    }"
    class="flex select-none items-center rounded-full text-sm text-black"
  >
    <svg
      v-if="requiredPlan === tiers.GOLD"
      :class="{ 'h-[1.5em] w-[1.5em]': small, 'h-4 w-4': !small }"
      id="Laag_1"
      data-name="Laag 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 28 28"
    >
      <defs>
        <linearGradient
          :id="`verloop_${id}`"
          data-name="Naamloos verloop"
          x1="14"
          y1="28.46"
          x2="14"
          y2="3.54"
          gradientTransform="translate(0 30) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#ffc600" />
          <stop offset="1" stop-color="#ffde69" />
        </linearGradient>
        <mask :id="`mask_${id}`" x="4.77" y="5.29" width="19.5" height="19.5" maskUnits="userSpaceOnUse" />
        <linearGradient
          :id="`verloop_${id}_2`"
          data-name="Naamloos verloop 2"
          x1="14.05"
          y1="22.78"
          x2="14.05"
          y2="10.16"
          gradientTransform="translate(0 30) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fffcdd" />
          <stop offset="1" stop-color="#ffe896" />
        </linearGradient>
      </defs>
      <circle cx="14" cy="14" r="13.5" :style="`fill: url(#verloop_${id}); stroke: #765c00;`" />
      <circle cx="14" cy="14" r="9.73" style="fill: #a88300" />
      <g :style="`mask: url(#mask_${id})`">
        <circle cx="14" cy="14" r="9.75" style="fill: #c09525" />
      </g>
      <path
        d="M14.05,7.22l2.1,4.21,4.21,.53-2.89,3.24,.79,4.65-4.21-2.1-4.21,2.1,.79-4.65-2.9-3.24,4.21-.53,2.1-4.21Z"
        :style="`fill: url(#verloop_${id}_2);`"
      />
    </svg>

    <svg
      v-else-if="requiredPlan === tiers.SILVER"
      :class="{ 'h-[1.5em] w-[1.5em]': small, 'h-4 w-4': !small }"
      id="Laag_1"
      data-name="Laag 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 27.96 27.96"
    >
      <defs>
        <mask id="mask" x="4.76" y="5.28" width="19.47" height="19.47" maskUnits="userSpaceOnUse" />
        <linearGradient
          :id="`silver_verloop_${id}`"
          data-name="silver_verloop"
          x1="13.98"
          y1="25.61"
          x2="13.98"
          y2="6.14"
          gradientTransform="translate(0 29.86) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#9ca1a3" />
          <stop offset="1" stop-color="#9ca1a3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          :id="`silver_verloop_${id}_2`"
          data-name="silver_verloop_2"
          x1="14.03"
          y1="22.65"
          x2="14.03"
          y2="10.05"
          gradientTransform="translate(0 29.86) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#f1f5f5" />
          <stop offset="0" stop-color="#fff" />
          <stop offset="1" stop-color="#f1f5f5" />
        </linearGradient>
      </defs>
      <circle cx="13.98" cy="13.98" r="13.48" style="fill: #e3e3e3; stroke: #404040" />
      <circle cx="13.98" cy="13.98" r="9.71" style="fill: #595959" />
      <g style="mask: url(#mask)">
        <circle cx="13.98" cy="13.98" r="9.73" :style="`fill: url(#silver_verloop_${id});`" />
      </g>
      <path
        d="M14.03,7.21l2.1,4.2,4.2,.52-2.88,3.23,.78,4.64-4.2-2.1-4.2,2.1,.79-4.64-2.89-3.23,4.2-.52,2.1-4.2Z"
        :style="`fill: url(#silver_verloop_${id}_2);`"
      />
    </svg>

    <span class="mb-0 ml-1 mt-0.5 whitespace-nowrap pr-2" v-if="!props.small"
    ><strong class="capitalize">{{ requiredPlanName }}</strong> <span class="text-base font-normal">plan</span></span
    >
  </div>
</template>
<script lang="ts" setup>
import type { Feature } from '@/data/features'
import features from '@/data/features'
import { useGuard } from '@/Hooks/useGuard'
import { computed, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { tiers } from '@/enums/tiers'
import { upgradeDialog } from '@/helpers/upgradeDialog'

const props = withDefaults(
  defineProps<{
    title?: string
    feature?: Feature
    subtitle?: string
    small?: boolean
    findFeature?: boolean
    forceShow?: boolean
    canClick?: boolean
  }>(),
  {
    small: false,
    findFeature: true,
    forceShow: false,
    canClick: true,
  },
)

const _feature = computed(() => features.find((f) => f.name === props.feature))

const requiredPlan = computed(() => _feature.value.tier)
const requiredPlanName = computed(() => tiers.toString(requiredPlan.value))

const title = computed(() => props.title || (props.feature && props.findFeature ? _feature.value.promo?.title : null))
const subtitle = computed(
  () => props.subtitle || (props.feature && props.findFeature ? _feature.value.promo?.subtitle : null),
)

const id = uuidv4()

const canUserUseFeature = props.feature ? useGuard(props.feature) : ref(false)
const shouldShow = computed(() => props.forceShow || !canUserUseFeature.value)
const canClick = computed(() => !props.forceShow && !canUserUseFeature.value)

const upgrade = () => {
  if (!canClick.value || (props.forceShow && canUserUseFeature.value)) {
    return
  }

  upgradeDialog.open(props.feature)
}
</script>
