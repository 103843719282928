<script setup lang="ts">
import IconSaxPlay from '@/components/Icons/iconsax/IconSaxPlay.vue'

const props = defineProps<{
  playing?: boolean
  title: string
  isSelected: boolean
}>()
</script>

<template>
  <article class="layer-2 group relative flex flex-col gap-1 rounded-xl p-1 transition-all border-0 lg:border-[1px] border-transparent lg:p-4 hover:border-blue-300 hover:bg-blue-50 dark:hover:border-[#67E8F9] dark:hover:bg-[#083344]">
    <div class="relative flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-lg">
      <h2
        class="mb-2 min-h-[1em] w-full cursor-default overflow-hidden text-ellipsis whitespace-nowrap py-1 pr-1.5 text-center text-sm text-white lg:text-left"
        :title="props.title"
      >
        {{ props.title }}
      </h2>
      <slot />

      <div
        class="pointer-events-none absolute inset-0 top-[36px] flex aspect-[9/16] flex-col items-center justify-center"
      >
        <button
          :class="{ 'invisible scale-90 opacity-0': props.playing || props.isSelected }"
          class="rounded-full bg-zinc-700/75 p-3 transition-all"
        >
          <IconSaxPlay class="h-8 w-8 fill-current stroke-0 text-white" />
        </button>
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss"></style>
