<script lang="ts" setup>
import WithUpgradeOverlay from '@/areas/dashboard/components/WithUpgradeOverlay.vue'
import MontageMakerVideosList from '@/areas/dashboard/components/MontageMaker/MontageMakerVideosList.vue'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { streamLadderAxios } from '@/services/axios'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import { type MontageDto, type VideoResultDto } from '@/apis/streamladder-api/model'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import Pusher from 'pusher-js'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useRoute } from 'vue-router'
import { getApiMontagesId } from '@/apis/streamladder-api/montages/montages'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import { useGetApiVideos } from '@/apis/streamladder-api/videos/videos'
import { useFilterVideos } from '@/Hooks/useFilterVideos'
import settings from '@/data/settings'
import { posthog } from 'posthog-js'

const route = useRoute()
const currentRouteTask = computed(() => route.query?.task || null)

watch(currentRouteTask, async (currentRouteTask) => {
  if (currentRouteTask) {
    await showProgressOnWatchOrMount(currentRouteTask as string)
  }
})

onMounted(async () => {
  posthog.capture('Montage Maker Opened', {
    task: currentRouteTask.value,
  })

  if (currentRouteTask.value) {
    await showProgressOnWatchOrMount(currentRouteTask.value as string)
  }
})

const showProgressOnWatchOrMount = async (currentRouteTask: string) => {
  progressPercentage.value = 0
  activeProgressTextIndex.value = 0
  removePusherListeners()
  showProgressOfMontage.value = currentRouteTask as string

  const montage = (await getApiMontagesId(route.query?.task as string)) as MontageDto

  if (montage.status === 'success') {
    progressPercentage.value = 100
  } else if (montage.status === 'processing') {
    bindProgressFromPusher(currentRouteTask as string)
  } else if (montage.status === 'failed') {
    returnToOverview()
    EventBus.$emit(
      mainEvents.ERROR,
      `We couldn't create your montage with ID:<pre class="py-1 px-2 rounded bg-zinc-200 my-2 select-all text-sm">${montage.id}</pre> Please contact our <a href="${settings.discordInviteUrl}" target="_blank" class="link text-indigo-500">support</a>!`
    )
    posthog.capture('Montage Failed on Show Progress', montage)
  }
}

const showProgressOfMontage = ref<MontageDto['id']>(null)
const selectedVideoUrls = ref<string[]>([])

const isAwaitingResponseFromNewMontageEndpoint = ref(false)

const progressPercentage = ref(0)
const pusherClient = ref<Pusher | null>(null)

const progressionTexts = ref<string[]>([
  "Hold tight while we’re creating your masterpiece! The duration may vary based on the number of clips you've included in your montage.",
  'Our hamster is warming up! Your montage is in the making.',
  'Taking it step by step! Your montage is gradually coming together.',
  'Progress is picking up! Your montage masterpiece is evolving nicely.',
  'Getting closer! Your montage is shaping up!',
  'Our hamster is running as fast as he can! Your montage is almost done!',
  "Just a little longer - we're adding the final touches to your montage gem!",
])

const activeProgressTextIndex = ref(0)

const removeClipFromMontage = (url: string) => {
  selectedVideoUrls.value = selectedVideoUrls.value.filter((v) => v !== url)
}

const addClipToMontage = (url: string) => {
  selectedVideoUrls.value = [...selectedVideoUrls.value, url]
}

const startNewMontage = async () => {
  try {
    isAwaitingResponseFromNewMontageEndpoint.value = true

    const response = await streamLadderAxios.post('api/montages', {
      videoUrls: selectedVideoUrls.value,
    })

    if (response.status === 200) {
      const montageDto = response.data as MontageDto
      showProgressOfMontage.value = montageDto.id as string
      bindProgressFromPusher(montageDto.id as string)
      isAwaitingResponseFromNewMontageEndpoint.value = false
      posthog.capture('Montage Created', {
        montageDto: montageDto,
        amountOfClipsSelected: selectedVideoUrls.value.length,
      })
    } else {
      console.error('An error occurred while starting a new montage', response)
      returnToOverview()
      EventBus.$emit(mainEvents.ERROR, "We couldn't create your montage. Please contact our support!")
      posthog.capture('Montage Failed on Creation', {
        response: response,
        selectedVideoUrls: selectedVideoUrls.value,
      })
    }
  } catch (error) {
    console.error('An error occurred while starting a new montage', error)
    returnToOverview()
    EventBus.$emit(mainEvents.ERROR, "We couldn't create your montage. Please contact our support!")
    posthog.capture('Montage Failed on Creation', {
      error: error,
      selectedVideoUrls: selectedVideoUrls.value,
    })
  }
}

const returnToOverview = () => {
  isAwaitingResponseFromNewMontageEndpoint.value = false
  showProgressOfMontage.value = null
  selectedVideoUrls.value = []

  removePusherListeners()
  progressPercentage.value = 0
  activeProgressTextIndex.value = 0
}

const bindProgressFromPusher = (montageId: string) => {
  if (pusherClient.value) {
    removePusherListeners()
  }

  pusherClient.value = new Pusher('ef0a10b651ed4adf46eb', {
    cluster: 'us3',
  })

  const channelName = `montage_${montageId}`
  const channel = pusherClient.value.subscribe(channelName)

  channel.bind('progress', async (data: { videoRender: { percentage: number } }) => {
    progressPercentage.value = Math.max(progressPercentage.value, data?.videoRender?.percentage || 0)

    activeProgressTextIndex.value = Math.min(
      Math.floor(progressPercentage.value / (100 / progressionTexts.value.length)),
      progressionTexts.value.length - 1
    )
  })

  channel.bind('error', () => {
    removePusherListeners()
    returnToOverview()
    EventBus.$emit(mainEvents.ERROR, "We couldn't create your montage. Please contact our support!")
    posthog.capture('Montage Pusher Failed', {
      montageId: montageId,
      details: 'Montage Pusher errored during listening for progress',
    })
  })
}

const removePusherListeners = () => {
  if (pusherClient.value) {
    pusherClient.value.disconnect()
    pusherClient.value = null
  }
}

onBeforeUnmount(() => {
  removePusherListeners()
})

const { data, isLoading: isLoadingVideos, dataUpdatedAt } = useGetApiVideos()
const videos = computed(() => (data.value ?? []) as unknown as VideoResultDto[])
const { filteredVideos, isLoadingPosts } = useFilterVideos(videos)

const hideCreateMontageButton = computed(() => {
  return filteredVideos.value.length < 2
})
</script>

<template>
  <WithUpgradeOverlay
    feature="montage-maker"
    subtitle="Combine your favorite video clips into a montage"
    title="Montage Maker"
  >
    <main
      v-if="!showProgressOfMontage && !isAwaitingResponseFromNewMontageEndpoint"
      class="min-h-32 flex flex-col gap-4 p-4 lg:p-12"
    >
      <div class="flex flex-row flex-wrap justify-between gap-2">
        <header class="flex w-full flex-col lg:w-auto">
          <h1 class="text-2xl lg:text-3xl">Select Your Clips</h1>
          <p class="font-light text-opacity-20">
            Ready to roll? Select 2 to 10 clips in sequential order. Let's get started!
          </p>
          <GradientButton
            v-if="!hideCreateMontageButton"
            :disabled="selectedVideoUrls.length < 2"
            @click="startNewMontage"
            class="mt-2 block w-full transition-all lg:hidden"
          >
            <span>Create Montage</span>
            <IconSaxArrowRight class="h-6 w-6" />
          </GradientButton>
        </header>
        <div class="flex flex-col justify-center">
          <GradientButton
            v-if="!hideCreateMontageButton"
            :disabled="selectedVideoUrls.length < 2"
            @click="startNewMontage"
            class="right-0 top-0 mt-0 hidden w-[250px] transition-all lg:block"
          >
            <span>Create Montage</span>
            <IconSaxArrowRight class="h-6 w-6" />
          </GradientButton>
        </div>
      </div>

      <MontageMakerVideosList
        @removeFromMontage="removeClipFromMontage"
        @addToMontage="addClipToMontage"
        :filteredVideos="filteredVideos"
        :selectedVideoUrls="selectedVideoUrls"
        :dataUpdatedAt="dataUpdatedAt"
        :isLoadingPosts="isLoadingPosts"
        :isLoadingVideos="isLoadingVideos"
      />
    </main>

    <RouterLink v-if="showProgressOfMontage" :to="{ name: dashboardRouteNames.montageMaker }">
      <div
        class="absolute m-4 flex cursor-pointer items-center gap-2 p-2 transition-all hover:text-gray-400"
        @click="returnToOverview"
      >
        <IconSaxArrowLeft class="h-4 w-4" />
        <p class="text-sm font-extralight">Go back</p>
      </div>
    </RouterLink>

    <Transition name="bounce" key="progress">
      <main
        v-if="showProgressOfMontage || isAwaitingResponseFromNewMontageEndpoint"
        class="h-full"
        id="montage-maker-survey"
      >
        <div class="flex h-full flex-col items-center justify-center gap-4 p-4 transition-all lg:p-12">
          <span class="mb-10 flex items-center justify-center" v-if="progressPercentage !== 100">
            <span v-if="progressPercentage" class="absolute mt-1">{{ progressPercentage + '%' }}</span>
            <lottie-animation class="w-36" url="/lottie/montage-maker-loader.json" :auto-play="true" :loop="true" />
          </span>

          <div v-if="progressPercentage !== 100">
            <h1 class="cursor-default text-center text-6xl font-bold">
              <span class="gradient-top">Creating your</span> <br />
              <span class="montage-color">Montage</span>
            </h1>
            <p class="mt-4 max-w-xl cursor-default text-center font-extralight text-gray-400">
              <span v-if="progressPercentage === 99">Hang tight!</span>
              <span v-else>{{ progressionTexts[activeProgressTextIndex] }}</span>
            </p>
          </div>
          <div v-else>
            <span class="mb-8 flex items-center justify-center">
              <lottie-animation class="w-36" url="/lottie/success-montage.json" :auto-play="true" :loop="false" />
            </span>
            <h1 class="mb-3 cursor-default text-center text-5xl font-bold">
              <span class="gradient-top mb-3 text-center">Your Montage is Ready!</span>
            </h1>
            <p class="mb-8 cursor-default text-center font-extralight text-gray-400">
              Your masterpiece has been created and added to your videos.
            </p>
            <RouterLink :to="{ name: dashboardRouteNames.videos }" class="flex w-full justify-center rounded-lg">
              <GradientButton
                class="gap-4 rounded-lg !px-4 !font-semibold tracking-wide transition-all hover:overflow-visible"
              >
                <span>Go to My Videos</span>
              </GradientButton>
            </RouterLink>
          </div>
          <div
            v-if="progressPercentage !== 100"
            class="mt-8 flex max-w-md rounded-lg border-2 border-green-700/50 bg-green-200/50 p-3 dark:border-green-400/80 dark:bg-green-800/20"
          >
            <IconSaxInfoCircle class="mr-3 h-full w-6 shrink-0 text-green-700/80 dark:text-green-400/80" />
            <span class="cursor-default font-light text-green-700/80 dark:text-green-400/80"
              >Feel free to close this tab. We will continue creating your montage.</span
            >
          </div>
        </div>
      </main>
    </Transition>
  </WithUpgradeOverlay>
</template>

<style lang="scss" scoped>
.gradient-top {
  background: linear-gradient(295.67deg, #e37c74 29.3%, #ef57ef 55.59%, #49bcbd 92.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.montage-color {
  color: #dbd0ff;
}

.bounce-enter-active {
  animation: bounce-in 0.75s;
}

.bounce-leave-active {
  animation: none;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
