import { ref, computed, readonly, watch, toRaw, type UnwrapRef, shallowRef, reactive } from 'vue'
import unwrap from '@/helpers/unwrap'
import { copyRef } from '@/store/entity-system/_copyRef'

/**
 * A store for managing entities by their ID
 *
 * Recommended implementation:
 * ```
 * defineStore('uniqueStoreName', () => {
 *
 *    const entityStore = useEntityStore<EntityType>()
 *
 *    return {
 *      state: readonly(entityStore.state),
 *      ids: entityStore.ids,
 *      entities: entityStore.entities,
 *      ...entityStore.operations,
 *
 *      // Custom operations
 *    }
 *  ```
 */
export function useEntityStore<T extends { id: string }>(initialState: Record<string, T> = {}) {

  const state = reactive<Record<string, T>>({ ...initialState })
  const ids = computed(() => unwrap.keys(state))
  const entities = computed(() => unwrap.values(state))

  return {
    /**
     * Do not expose this as anything other than Readonly, but rather write operations that modify the state in
     * a controlled way
     */
    state,

    ids,
    entities,

    operations: {

      reset() {

        for (const id of ids.value) {
          delete state[id]
        }

        for (const id of unwrap.keys(initialState)) {
          state[id] = initialState[id]
        }
      },

      /**
       * Select an entity by its ID, `id` is purposely not a ref, because an ID should never change
       */
      selectById(id: string) {
        return computed(() => state[id])
      },

      createById(id: string, entity: Omit<T, 'id'>) {

        if (state[id]) {
          throw new Error(`Entity with id ${id} already exists`)
        }

        state[id] = {
          id: id,
          ...entity,
        } as T
      },

      removeById(id: string): void {
        delete state[id]
      },

      upsertById(id: string, entity: T) {
        state[id] = {
          ...state[id],
          ...entity,
        }
      },

      where(predicate: (entity: T) => boolean) {
        return computed(() => readonly(entities.value.filter(predicate)))
      },

      idsWhere(predicate: (entity: T) => boolean) {
        const items = this.where(predicate)
        return computed(() => readonly(items.value.map((i) => i.id)))
      },

      whereIdIn(ids: string[]) {
        return computed(() => readonly(ids.map((id) => state[id]).filter(Boolean)))
      },
    },
  }
}
