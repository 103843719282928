<template>
  <Teleport to="body">
    <div
      v-if="shouldShowModal"
      :class="$attrs.class"
      class="relative"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="fixed inset-0 z-40 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 z-40 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center sm:items-center sm:py-0 md:p-4" ref="mask">
          <div
            v-click-outside="attemptClose"
            class="relative transform rounded-xl bg-white px-0 text-left shadow-xl transition-all sm:my-8"
            :class="styles"
          >
            <div class="relative clear-both rounded-xl" :class="background">
              <slot name="header" :close="close" v-if="closable">
                <div class="flex w-full justify-end">
                  <div class="cursor-pointer p-2 text-gray-300 hover:text-company-primary" @click="close">
                    <cross-icon class="h-6 w-6 fill-current" />
                  </div>
                </div>
              </slot>
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script>
import eventBus from '../../eventBus'
import CrossIcon from '../Icons/CrossIcon.vue'

export default {
  components: { CrossIcon },
  props: {
    // If set, this controls the visibility of the Dialog. @emit(inputs) are also emitted
    modelValue: { type: Boolean, default: null },

    // Otherwise we expect two EventBus eventNames to control the open/closing of this dialog
    openEvent: { type: String },
    closeEvent: { type: String },

    styles: { type: String, required: false, default: 'w-full' },
    background: { type: String, required: false, default: 'bg-white' },
    closable: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      // Internal state of the visibility when the EventBus is used
      visible: false,
      preventClickOutside: false,
    }
  },
  computed: {
    shouldShowModal() {
      if (this.modelValue !== null) return this.modelValue
      return this.visible
    },
  },
  methods: {
    open() {
      this.openEvent ? eventBus.$emit(this.openEvent) : this.$emit('update:modelValue', true)
    },
    close() {
      this.closeEvent ? eventBus.$emit(this.closeEvent) : this.$emit('update:modelValue', false)
    },
    attemptClose() {
      if (!this.closable || this.preventClickOutside) {
        return
      }

      this.closeEvent ? eventBus.$emit(this.closeEvent) : this.$emit('update:modelValue', false)
    },
    onKeydownHandler(e) {
      if (e.key === 'Escape') {
        this.close()
      }
    },
    onMouseDown(mouseEvent) {
      this.preventClickOutside = mouseEvent.target !== this.$refs.mask
    },
    registerEventListeners() {
      if (this.closable) {
        window.addEventListener('keydown', this.onKeydownHandler)
      }
      window.addEventListener('mousedown', this.onMouseDown)
    },
    removeEventListeners() {
      window.removeEventListener('mousedown', this.onMouseDown)
      window.removeEventListener('keydown', this.onKeydownHandler)
    },
  },
  mounted() {
    if (this.openEvent) {
      eventBus.$on(this.openEvent, () => {
        document.body.classList.add('overflow-y-hidden')
        this.visible = true
      })
    }

    if (this.closeEvent) {
      eventBus.$on(this.closeEvent, () => {
        this.removeEventListeners()
        document.body.classList.remove('overflow-y-hidden')
        this.visible = false
      })
    }
  },
  beforeUnmount() {
    if (this.openEvent) eventBus.$off(this.openEvent)
    if (this.closeEvent) eventBus.$off(this.closeEvent)
    this.removeEventListeners()
  },
  watch: {
    modelValue: {
      handler(curr) {
        if (curr) {
          document.body.classList.add('overflow-y-hidden')
          this.registerEventListeners()
        } else {
          document.body.classList.remove('overflow-y-hidden')
          this.removeEventListeners()
        }
      },
      immediate: true,
    },
  },
}
</script>
