<template>
  <div class="flex gap-1">
    <button
      @click="prev"
      :class="modelValue === 1 ? 'cursor-not-allowed opacity-50 hover:opacity-100' : 'cursor-pointer'"
      :disabled="modelValue < 2"
      class="sl-border flex min-w-[2em] items-center justify-center rounded p-2"
    >
      <IconSaxArrowLeft2 class="h-5 w-5" />
    </button>
    <button
      v-for="(page, index) in pages"
      :key="index"
      @click="page !== ellipsis && page !== modelValue ? $emit('update:modelValue', page) : null"
      :class="{
        'bg-company-primary text-white': page === modelValue,
        'bg-grey text-grey-800': page === ellipsis,
        'layer-2': page !== ellipsis && page !== modelValue,
      }"
      :disabled="page === ellipsis"
      class="sl-border flex min-w-[2em] items-center justify-center rounded p-2"
    >
      {{ page }}
    </button>
    <button
      @click="next"
      :class="modelValue === length ? 'cursor-not-allowed opacity-50 hover:opacity-100' : 'cursor-pointer'"
      :disabled="modelValue >= length"
      class="sl-border flex min-w-[2em] items-center justify-center rounded p-2"
    >
      <IconSaxArrowRight2 class="h-5 w-5" />
    </button>
  </div>
</template>
<script>
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import IconSaxArrowLeft2 from '@/components/Icons/iconsax/IconSaxArrowLeft2.vue'

export default {
  components: { IconSaxArrowLeft2, IconSaxArrowRight2 },
  props: {
    modelValue: { type: Number },
    length: { type: Number, required: true },
    maxButtons: { type: Number, default: 5 },
  },
  data() {
    return {
      ellipsis: '...',
    }
  },
  computed: {
    pages: function () {
      const currentPage = this.modelValue
      const totalPages = this.length
      const maxButtons = this.maxButtons - 2

      const buttons = []

      // Add first page button
      buttons.push(1)

      // If there are more than 1 pages, add extra buttons
      if (totalPages > 1) {
        // Calculate range of buttons to display around current page
        const startRange = Math.max(currentPage - Math.floor(maxButtons / 2), 2)
        const endRange = Math.min(currentPage + Math.floor(maxButtons / 2), totalPages - 1)

        // Add ellipsis if there are pages before the start range
        if (startRange > 2) {
          buttons.push(this.ellipsis)
        }

        // Add buttons in the range
        for (let i = startRange; i <= endRange; i++) {
          buttons.push(i)
        }

        // Add ellipsis if there are pages after the end range
        if (endRange < totalPages - 1) {
          buttons.push(this.ellipsis)
        }

        // Add last page button
        buttons.push(totalPages)
      }

      return buttons
    },
  },
  methods: {
    prev() {
      if (this.current <= 0) return
      this.$emit('update:modelValue', this.modelValue - 1)
    },
    next() {
      if (this.current >= this.length) return
      this.$emit('update:modelValue', this.modelValue + 1)
    },
  },
}
</script>
