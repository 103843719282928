import { onUserInfoReadyAsync } from '@/store/user/userInfo'
import {
  putApiUsersUserIdPreferences,
  getApiUsersUserIdPreferences,
} from '@/apis/streamladder-accounts/users-preferences/users-preferences'
import { z } from 'zod'
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import * as Sentry from '@sentry/vue'
import { useEventListener } from '@vueuse/core'

const preferences = z.object({
  lastProductUpdatesReceived: z.optional(z.string().datetime().default(new Date(0).toISOString())),
} as const)

type Preferences = z.infer<typeof preferences>
type PreferenceKeys = keyof Preferences
type PreferenceByKey<TKey extends PreferenceKeys> = Preferences[TKey]

type PreferencesCache = { [K in PreferenceKeys]: PreferenceByKey<K> }

export const useUserPreferencesStore = defineStore('user-preferences', () => {
  const cache: Ref<Partial<PreferencesCache>> = ref({})

  async function createCacheIfEmpty() {
    if (!Object.keys(cache.value).length) {
      await refreshCache()
    }
  }

  async function refreshCache() {
    const { userId } = await onUserInfoReadyAsync()
    if (!userId) {
      cache.value = {}
    } else {
      const response = await getApiUsersUserIdPreferences(userId)
      validateAndUpdate(response.value as PreferencesCache)
    }
  }

  function validateAndUpdate(payload: PreferencesCache) {
    const validated = preferences.safeParse(payload)
    if (validated.success) {
      cache.value = validated.data
    } else {
      Sentry.captureException(validated.error)
    }
  }

  function createUserPreferencesManager<TKey extends PreferenceKeys>(key: TKey) {
    async function get() {
      await createCacheIfEmpty()
      return cache.value[key]
    }

    async function set(value: PreferenceByKey<TKey>) {
      await createCacheIfEmpty()

      const { userId } = await onUserInfoReadyAsync()
      const payload = {
        ...cache.value,
        [key]: value,
      }

      if (!userId) return

      const response = await putApiUsersUserIdPreferences(userId, JSON.stringify(payload))
      validateAndUpdate(response.value as PreferencesCache)
    }

    return { get, set }
  }

  useEventListener(window, 'focus', refreshCache)

  return { preferences: cache, createUserPreferencesManager }
})
