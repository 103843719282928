import { createDialog } from '@/components-v2/actions/dialog/createDialog'
import ConfirmDialog from '@/components-v2/actions/dialog/ConfirmDialog.vue'
import type { ComponentProps } from '@/components-v2/actions/dialog/types'
import type { UseConfirmDialogRevealResult } from '@vueuse/core'

export const useConfirmDialog = () => {
  const { close, closeAll, reveal, isRevealed, onConfirm, onCancel } = createDialog(ConfirmDialog)

  const revealConfirmDialog: (props?: ComponentProps<typeof ConfirmDialog>) => Promise<boolean> = (props) => {
    return reveal(props).then((result: UseConfirmDialogRevealResult<any, boolean>) => {
      return !result.isCanceled
    })
  }

  return {
    close,
    closeAll,
    reveal: revealConfirmDialog,
    isRevealed,
    onConfirm,
    onCancel,
  }
}
