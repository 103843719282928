<script setup lang="ts">
import Accordion from '@/components-v2/modules/Accordion/Accordion.vue'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import settings from '@/data/settings'
import { useBlogItems } from '@/queries/useBlogItems'
import IconSaxCloseSquare from '@/components/Icons/iconsax/IconSaxCloseSquare.vue'
import { toRef } from 'vue'
import { useConditionalEventListener } from '@/Hooks/useConditionalEventListener'

const emit = defineEmits<{
  (event: 'confirm'): void
  (event: 'cancel'): void
  (event: 'update:modelValue', value: boolean): void
}>()

const props = defineProps<{ modelValue: boolean }>()
const { data: blogItems, isLoading } = useBlogItems(3)

useConditionalEventListener(toRef(props, 'modelValue'), 'keydown', (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    emit('update:modelValue', false)
  }
})
</script>

<template>
  <article class="modal" :class="{ 'modal-open': modelValue }" @click="emit('cancel')">
    <div class="layer-1 modal-box max-w-screen-lg rounded-2xl" @click.stop>
      <div class="relative flex flex-col gap-8">
        <button
          @click="emit('cancel')"
          class="absolute right-0 top-0 rounded-xl bg-transparent p-2 transition-all hover:bg-zinc-500/50 active:scale-90"
        >
          <IconSaxCloseSquare />
        </button>
        <h3 class="text-center text-3xl lg:text-4xl">Before you leave us</h3>

        <ol class="lg:max-w-2/3 flex flex-col gap-6">
          <li class="flex flex-row items-start gap-2">
            <span
              class="box-content flex h-4 w-4 shrink-0 items-center justify-center rounded-full bg-indigo-500 p-2 text-white"
            >
              1
            </span>
            <div class="flex flex-col gap-2">
              <p class="flex h-8 shrink-0 items-center">If you need help, you can find us here</p>
              <a
                target="_blank"
                :href="settings.discordInviteUrl"
                class="btn-lg btn items-center gap-4 self-start border-indigo-500 bg-indigo-500 text-stone-50"
              >
                Reach out to us on Discord
                <DiscordLogo class="h-6 w-6" />
              </a>
            </div>
          </li>
          <li class="flex flex-row items-start gap-2">
            <span
              class="box-content flex h-4 w-4 shrink-0 items-center justify-center rounded-full bg-indigo-500 p-2 text-white"
            >
              2
            </span>
            <div class="flex flex-col gap-2" v-if="!isLoading">
              <p class="flex h-8 shrink-0 items-center">Get better results with StreamLadder</p>
              <ol class="flex list-disc flex-col gap-1">
                <li class="ml-5 text-indigo-500" v-for="blogItem in blogItems.slice(0, 3)" :key="blogItem">
                  <a :href="blogItem.url" target="_blank">{{ blogItem.title }}</a>
                </li>
              </ol>
            </div>
          </li>
        </ol>

        <div>
          <h4>FAQ</h4>
          <p class="text-sm font-light">
            Are you getting the most out of StreamLadder? Don’t let your questions hold you back.
          </p>
          <ol class="mt-4 flex flex-col">
            <li>
              <Accordion title="Does StreamLadder reduce the quality of my clips?" class="layer-2 border-transparent">
                <p>
                  If you feel like your clips don’t look as good as you expected them to be, check out our
                  <a
                    class="link font-bold"
                    href="https://streamladder.com/user-guide/how-to-make-sure-you-get-the-highest-video-quality"
                    >user guide</a
                  >
                  to find out what the reason for this might be.
                </p>
              </Accordion>
            </li>
            <li>
              <Accordion title="Why aren’t my videos getting more views?" class="layer-2 border-transparent">
                <p>
                  TikTok and YouTube are pretty secretive about their algorithms and how to manipulate them to your
                  advantage. Luckily, we’ve done some research and are happy to share our findings. Try
                  <a
                    class="link font-bold"
                    href="https://streamladder.com/user-guide/how-to-make-the-tiktok-and-youtube-shorts-algorithm-work-for-you"
                    >these steps</a
                  >
                  to get more views.
                </p>
              </Accordion>
            </li>
            <li>
              <Accordion title="Why aren’t my videos getting published to TikTok?" class="layer-2 border-transparent">
                <p>
                  Using our Content Publisher, but your videos aren’t being published to TikTok? Check out what might be
                  the cause
                  <a
                    class="link font-bold"
                    href="https://streamladder.com/user-guide/publishing-to-tiktok-with-streamladder"
                    >here</a
                  >
                </p>
              </Accordion>
            </li>
            <li>
              <Accordion title="Why won't my videos render?" class="layer-2 border-transparent">
                <p>
                  We support a wide variety of input videos, but sometimes unexpected errors do occur. Try our
                  <a class="link font-bold" href="https://app.streamladder.com/video-support">video support tool</a>
                  why your video might fail to render.
                </p>
              </Accordion>
            </li>

            <li>
              <Accordion title="Is there an app that I can download?" class="layer-2 border-transparent">
                <p>
                  No, we currently see no added value in creating an app, but the StreamLadder website works very well
                  on a mobile device.
                </p>
              </Accordion>
            </li>
            <li>
              <Accordion title="How can I get access to all of the features?" class="layer-2 border-transparent">
                <p>
                  Some features are only available with a Silver or Gold subscription. If you do have a paid
                  subscription and still don’t have access to certain features, please contact us on
                  <a class="link font-bold" :href="settings.discordInviteUrl" target="_blank">Discord</a>.
                </p>
              </Accordion>
            </li>
          </ol>
        </div>

        <div class="flex justify-end gap-2">
          <button
            @click="emit('confirm')"
            class="flex items-center justify-center gap-2 rounded-xl border border-current px-6 py-2 text-rose-400 transition-all hover:border-rose-500 hover:bg-rose-500 hover:text-white active:scale-90"
          >
            Cancel my plan
          </button>
          <button
            class="flex items-center justify-center gap-2 rounded-xl border border-indigo-500 bg-indigo-500 px-6 py-2 text-white transition-all hover:border-indigo-600 hover:bg-indigo-600 active:scale-90"
            @click="emit('cancel')"
          >
            Keep subscription active
          </button>
        </div>
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss"></style>
