import { acceptHMRUpdate, defineStore } from 'pinia'
import uploadService from '../../services/uploadService'
import axios from 'axios'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'

interface editorClipInfoState extends ClipInfo {
  // Extra info about the clip
  source: string
  isLocalFile: boolean

  // Info about the upload status of the clip (when it is a local file).
  isUploadingLocalFileBackground: boolean
  uploadingPercentageBackground: number
  uploadedLocalFileBackgroundSuccess: boolean
  uploadedLocalFileResultUrl: string
  uploadRetryCount: number

  // Abort controller is used to cancel the axios request when needed.
  cancellationUploadFileRequest: any

  audioSkipped: boolean
  audioData: AudioBuffer | null

  // Used to signal if we're waiting for clip data. For example the YouTube clip download
  isLoadingClip: boolean
}

export const useEditorClipInfoStore = defineStore('editorClipInfo', {
  state: (): editorClipInfoState => {
    return {
      id: '',
      title: '',
      mp4Url: '',
      thumbnailUrl: '',
      viewCount: 0,
      languageCode: '',
      broadcasterName: '',
      broadcasterProfileImage: '',
      gameName: '',
      gameBoxArt: '',
      dateFromNow: '',
      source: '',
      isLocalFile: false,

      audioSkipped: false,
      audioData: null,

      isUploadingLocalFileBackground: false,
      uploadingPercentageBackground: 0,
      uploadedLocalFileBackgroundSuccess: false,
      uploadedLocalFileResultUrl: '',
      uploadRetryCount: 0,

      cancellationUploadFileRequest: null,

      isLoadingClip: true,
    }
  },
  actions: {
    /**
     * Sets the local ClipInfo state to the given ClipInfo.
     */
    setClip(clipInfo: ClipInfo) {
      this.id = clipInfo.id
      this.title = clipInfo.title
      this.mp4Url = clipInfo.mp4Url
      this.thumbnailUrl = clipInfo.thumbnailUrl
      this.viewCount = clipInfo.viewCount
      this.languageCode = clipInfo.languageCode
      this.broadcasterName = clipInfo.broadcasterName
      this.broadcasterProfileImage = clipInfo.broadcasterProfileImage
      this.gameName = clipInfo.gameName
      this.gameBoxArt = clipInfo.gameBoxArt
      this.dateFromNow = clipInfo.dateFromNow
      this.isLocalFile = clipInfo.isLocalFile
      this.audioData = null
      this.audioSkipped = false
      this.isLoadingClip = false
      this.uploadRetryCount = 0
    },

    /**
     * Upload the clip to the S3 storage.
     */
    async uploadClipInBackground(payload: any) {
      try {
        this.resetUploadingState()

        const cancelSource = axios.CancelToken.source()
        this.cancellationUploadFileRequest = cancelSource
        this.isUploadingLocalFileBackground = true
        this.uploadingPercentageBackground = 0

        const signResult = await uploadService.getImportFileSignedUrl()
        const uploadResponse = await uploadService.uploadFileS3(
          signResult.signedUrl,
          payload,
          (p) => (this.uploadingPercentageBackground = p),
          'video/mp4',
          '',
          {
            cancelToken: cancelSource.token,
          }
        )

        if (uploadResponse.status !== 200) {
          throw new Error(`Upload failed with code '${uploadResponse.status}', message: ${uploadResponse.data}`)
        }

        this.uploadedLocalFileResultUrl = signResult.resultUrl
        this.uploadedLocalFileBackgroundSuccess = true
        this.isUploadingLocalFileBackground = false
        this.cancellationUploadFileRequest = null
      } catch (e: any) {
        this.resetUploadingState()
        if (axios.isCancel(e)) {
          return
        }
      }

      if (!this.uploadedLocalFileBackgroundSuccess) {
        if (this.uploadRetryCount >= 3) {
          EventBus.$emit(
            mainEvents.ERROR,
            `Something went wrong while uploading the clip to the server
       <br/><br/>
      Please open a Ticket on Discord`
          )
          return
        }

        this.uploadRetryCount++
        await this.uploadClipInBackground(payload)
      }
    },
    async waitForUpload() {
      while (this.isUploadingLocalFileBackground) {
        await new Promise((resolve) => setTimeout(resolve, 200))
      }
      return this.uploadedLocalFileBackgroundSuccess
    },
    /**
     * Resets the uploading state to its initial values.
     */
    resetUploadingState() {
      this.uploadingPercentageBackground = 0
      this.uploadedLocalFileResultUrl = ''
      this.isUploadingLocalFileBackground = false
      this.uploadedLocalFileBackgroundSuccess = false
    },
    cancelPendingUploadRequest() {
      if (this.cancellationUploadFileRequest) {
        this.cancellationUploadFileRequest.cancel('Upload cancelled due leaving editor')
        this.cancellationUploadFileRequest = null
        this.resetUploadingState()
      }
    },
  },
})

export interface ClipInfo {
  id: string
  title: string
  // Can be a URL to R2, a blob URL to localforage or blob URL to a local file.
  mp4Url: string
  thumbnailUrl: string
  viewCount: number
  languageCode: string
  broadcasterName: string
  broadcasterProfileImage: string
  gameName: string
  gameBoxArt: string
  dateFromNow: string
  isLocalFile: boolean
}

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useEditorClipInfoStore, import.meta.hot))
}
