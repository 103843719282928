<script lang="ts" setup>
import type { Component, Ref } from 'vue'
import { ref } from 'vue'
import IconSaxArrowDown2 from '@/components/Icons/iconsax/IconSaxArrowDown2.vue'

defineProps<Props<string | number | undefined>>()
const emit = defineEmits<{ (event: 'update:modelValue', value: string | number | undefined): void }>()

type Props<T> = { options: Option<T>[]; modelValue: Ref<T> | ThisType<T>; fixed?: boolean }

type Option<T> = {
  label: Component | string
  value: T
}

const isOpen = ref<boolean>()

const onClick = (event: MouseEvent, value: string | number | undefined) => {
  const element = event.target as HTMLElement | null
  emit('update:modelValue', value)
  element?.blur()
  isOpen.value = false
}

const onKeyDown = (event: KeyboardEvent) => {
  const element = event.target as HTMLElement | null
  if (event.key === 'Escape') {
    event.preventDefault()
    element?.blur()
    isOpen.value = false
  }
}
</script>

<template>
  <div :class="{ 'relative h-10': fixed }" v-click-outside="() => (isOpen = false)">
    <div :class="{ fixed: fixed }" class="group relative w-48">
      <button
        class="flex w-full cursor-pointer items-center justify-between rounded-xl bg-white px-3 py-2 outline-none dark:bg-zinc-700 dark:text-zinc-100"
        @click="isOpen = !isOpen"
        @keydown="onKeyDown"
      >
        {{ options.find((o) => o.value === modelValue)!.label }}

        <IconSaxArrowDown2 class="h-4 w-4 transition-transform" :class="{ '-rotate-180': isOpen }" />
      </button>

      <ol
        class="absolute top-full z-[1] my-2 flex w-full flex-col gap-1 overflow-hidden rounded-xl bg-white shadow transition-all dark:bg-zinc-700 dark:text-zinc-100"
        :class="[{ 'invisible -translate-y-2 scale-95 opacity-0': !isOpen }]"
      >
        <li v-for="option in options" :key="option.value" class="whitespace-nowrap">
          <label
            class="flex cursor-pointer px-4 py-2 focus-within:bg-primary focus-within:text-white hover:bg-primary hover:text-white active:bg-violet-900"
            @keydown="onKeyDown"
          >
            <input
              :value="option.value"
              class="absolute opacity-0"
              type="radio"
              @click="(e) => onClick(e, option.value)"
            />
            {{ option.label }}
          </label>
        </li>
      </ol>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
