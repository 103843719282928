import { defineStore, acceptHMRUpdate } from 'pinia'
import { useRoute } from 'vue-router'
import { getLayoutNameByKey, LayoutKeys } from '@/data/layoutData'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { useEditorVideoStore } from '@/store/editor/editorVideo'

interface EditorMainState {
  // all times are in milliseconds
  videoDuration: number

  outputWidth: number
  outputHeight: number

  videoHeight: number
  videoWidth: number

  correctionState: 'none' | 'popup' | 'text'

  // TODO move to Captions store?
  isEditingCaption: boolean

  ffmpegStringArray: string[]
  templateId: string

  layoutId: string
  // Includes ratio for splits
  layoutName: string
  layoutArguments: Record<string, string | number>
  switchFeeds: boolean
}

export const useEditorMainStore = defineStore('editorMain', {
  state: (): EditorMainState => {
    const route = useRoute()

    // Set layout if available, from route meta-data + include ratio
    const layoutName = getLayoutNameByKey(route?.params?.layout as string)

    return {
      outputWidth: 720,
      outputHeight: 0,
      isMuted: false,
      isPlaying: false,
      videoHeight: 0,
      videoWidth: 0,
      correctionState: 'none',
      isEditingCaption: false,
      ffmpegStringArray: [],
      templateId: '',
      layoutId: layoutName === 'Custom' ? (route.params.layout as string) : '',
      layoutName: layoutName,
      layoutArguments: {},
      switchFeeds: false,
    }
  },
  getters: {
    trimmedStartTime: () => {
      const editorFeedDataStore = useEditorFeedDataStore()
      const firstSegment = editorFeedDataStore.segments[0]
      return firstSegment?.start || 0
    },
    trimmedEndTime: (state: EditorMainState) => {
      const editorFeedDataStore = useEditorFeedDataStore()
      const lastSegment = editorFeedDataStore.segments[editorFeedDataStore.segments.length - 1]
      return lastSegment?.end || state.videoDuration
    },
    trimmedDurationMs(): number {
      return this.trimmedEndTime - this.trimmedStartTime
    },
    transcodeLayoutName: (state: EditorMainState) => {
      let layoutName = state.layoutName.toLocaleLowerCase()
      if (layoutName.startsWith('split')) {
        layoutName = 'split'
      } else if (layoutName.startsWith('blurred')) {
        layoutName = 'blurred'
      }

      return layoutName
    },
    videoDuration: (state: EditorMainState) => {
      const editorVideoStore = useEditorVideoStore()
      return Math.round(editorVideoStore._duration * 1000 || 0)
    },
    layoutHasBlurredBackground: (state: EditorMainState) => {
      return ![
        LayoutKeys.Square,
        LayoutKeys.Split16x9,
        LayoutKeys.Split4x3,
        LayoutKeys.SplitFree,
        LayoutKeys.FullScreen,
      ].includes(state.layoutName)
    },
    aspectRatio: (state: EditorMainState) => {
      return state.videoWidth / state.videoHeight
    },
  },
  actions: {
    setVideoOutputDimensions(dimensions: { width: number; height: number }) {
      this.outputHeight = dimensions.height
      this.outputWidth = dimensions.width
    },
    getLoggingData() {
      return {
        videoDuration: this.videoDuration,
        trimmedDuration: this.trimmedDurationMs,
        layout: this.layoutName,
        layoutArguments: this.layoutArguments,
      }
    },
    reset() {},
  },
})

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useEditorMainStore, import.meta.hot))
}
