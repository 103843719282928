<script lang="ts" setup>
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import { useTwitchClips, type Clip } from '@/Hooks/useTwitchClips'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useTwitchClipsFilter } from '@/Hooks/useTwitchClipsFilter'
import { range } from 'lodash-es'
import ClipPreviewCard from '@/components/Clips/ClipPreviewCard.vue'
import TwitchClipPreviewDialog from '@/components-v2/modules/HomePage/TwitchClipPreviewDialog.vue'
import { ref, computed } from 'vue'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useRouter } from 'vue-router'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import { useClipFormManager } from '@/Hooks/clip-form/useClipFormManager'
import Pagination from '@/components-v2/actions/Pagination.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import IconSaxVideo from '@/components/Icons/iconsax/IconSaxVideo.vue'
import { useScreenName } from '@/Hooks/useScreen'
import AlertBox from '@/components-v2/data-display/AlertBox.vue'
import ClipUrlError from '@/Hooks/clip-form/ClipUrlError.vue'

const props = defineProps<{ rows?: number }>()

const userInfo = useUserInfoStore()
const { clipsOnPage, hasClips, clipsPerPage, clipsToShow, totalPages, pageNumber, isLoading, totalAmountOfClips } =
  useTwitchClips()

const { twitchClipFilterKey, twitchClipsOptions } = useTwitchClipsFilter()

const previewId = ref<string | null>(null)

const resetEditor = useResetEditor()
const router = useRouter()

async function onClipSelected(clip: Clip) {
  resetEditor()
  await router.push({ name: 'editor', params: { clipId: clip.id, source: 'twitch-clip' } })
}

function preview(clip: Clip) {
  previewId.value = clip.id
}

const screen = useScreenName()

const itemsPerRowByScreenSize = {
  xs: 2,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 3,
  '2xl': 4,
}

const { requestLocalFileUpload, error } = useClipFormManager()

const items = computed(() => {
  return range(
    props.rows ? Math.min(itemsPerRowByScreenSize[screen.value] * props.rows, clipsToShow.value) : clipsToShow.value
  )
})
</script>

<template>
  <div class="flex w-full flex-wrap items-center justify-between">
    <h2 class="mb-0 text-3xl font-normal">
      <template v-if="isLoading">
        Finding clips from <span class="font-bold">{{ userInfo.userName }}</span>
      </template>
      <template v-else>
        Showing {{ rows ? '' : totalAmountOfClips }} clips from
        <span class="font-bold">{{ userInfo.userName }}</span>
      </template>
    </h2>

    <SelectDropdown v-model="twitchClipFilterKey" :options="twitchClipsOptions" class="ml-auto" />
  </div>

  <template v-if="hasClips || isLoading">
    <div class="w-full">
      <TransitionGroup
        :key="twitchClipFilterKey + pageNumber"
        appear
        class="grid grid-cols-2 gap-2 md:gap-4 lg:grid-cols-3 2xl:grid-cols-4"
        name="grid"
        tag="ol"
      >
        <li v-for="i in items" :key="pageNumber * clipsPerPage + i">
          <ClipPreviewCard :clip="clipsOnPage[i]" :is-loading="isLoading" @preview="preview" @select="onClipSelected" />
        </li>
      </TransitionGroup>
    </div>

    <template v-if="!isLoading">
      <Pagination v-if="!rows && totalPages > 1" v-model="pageNumber" :length="totalPages" class="self-center" />

      <RouterLink
        v-if="rows && clipsToShow > 8"
        :to="{ name: dashboardRouteNames.editor }"
        class="mt-4 flex items-center gap-2 rounded-xl border border-current px-3 py-1.5 font-semibold transition-all hover:border-transparent hover:bg-zinc-800 hover:text-zinc-100 active:scale-90 active:bg-zinc-600/25 dark:hover:bg-zinc-200 dark:hover:text-zinc-800"
      >
        <IconSaxVideo />
        View all clips
      </RouterLink>
    </template>
  </template>

  <NoContentSection
    v-else-if="twitchClipFilterKey === 'views-all'"
    subtitle="Try uploading a local file"
    title="The selected channel does not have any clips yet"
  >
    <GradientButton @click="requestLocalFileUpload()">Upload a local file</GradientButton>

    <ClipUrlError :error="error" />
  </NoContentSection>

  <NoContentSection
    v-else
    subtitle="Check out all clips from this channel instead"
    title="No clips were created in this time period"
  >
    <GradientButton @click="twitchClipFilterKey = 'views-all'">Show all</GradientButton>
  </NoContentSection>

  <TwitchClipPreviewDialog v-model="previewId" :clips="clipsOnPage" @confirm="onClipSelected" />
</template>

<style lang="scss" scoped></style>
