<script lang="ts" setup>
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { computed } from 'vue'
import { IconExclamationCircle } from '@tabler/icons-vue'
import type { SocialMedia, TargetListingDto } from '@/apis/streamladder-publisher/model'
import { TargetState } from '@/apis/streamladder-publisher/model'
import InstagramIcon from '@/components/Icons/SocialMedia/InstagramIcon.vue'
import { useExternalApiErrorCodes } from './hooks/useExternalApiErrorCodes'

const props = defineProps<{ postId: string; target: TargetListingDto }>()

const userInfo = useUserInfoStore()

const userHandle = computed(() => {
  if (!props.target.accountId) return

  const account = userInfo.findSocialById(props.target.accountId)
  if (!account) return undefined

  const handles: Record<SocialMedia, string> = {
    Instagram: `@${account.displayName}`,
    Tiktok: `@${account.displayName}`,
    Youtube: account.displayName,
  }

  if (!props.target.socialMedia) return undefined

  return handles[props.target.socialMedia]
})

const status = computed<(typeof TargetState)[keyof typeof TargetState]>(
  () => props.target.status?.status ?? 'Scheduled',
)

const statusLabel = computed(() => {
  return {
    [TargetState.New]: 'New',
    [TargetState.Failed]: 'Failed',
    [TargetState.Queued]: 'In queue',
    [TargetState.Processing]: 'In progress',
    [TargetState.Published]: 'Published',
    [TargetState.Publishing]: 'Publishing',
    [TargetState.Scheduled]: 'Scheduled',
  }[status.value]
})

const isError = computed(() => status.value === 'Failed')
const errorFor = useExternalApiErrorCodes()
const error = computed(() => errorFor(props.target.status?.code))
</script>

<template>
  <li
    :class="status === 'Failed' ? 'border-rose-400 bg-rose-400/10' : 'border-transparent'"
    class="flex flex-col gap-2 rounded-lg border p-4"
  >
    <span class="flex items-center gap-4">
      <TikTokIcon
        v-if="target.socialMedia === 'Tiktok'"
        class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-black p-2 text-white"
      />
      <YoutubeIcon
        v-else-if="target.socialMedia === 'Youtube'"
        class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-[#F00] p-2 text-white"
      />
      <InstagramIcon
        v-else-if="target.socialMedia === 'Instagram'"
        class="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg p-2 text-white"
        style="background-image: linear-gradient(72.44deg, #ff7a00 11.92%, #ff0169 51.56%, #d300c5 85.69%)"
      />
      <span class="mr-4 font-semibold">{{ userHandle }}</span>
      <span
        :class="{
          'bg-rose-500': status === 'Failed',
          'bg-emerald-500': status === 'Published',
          'bg-[#B3B3B3]': ['Processing', 'Publishing', 'Queued'].includes(status),
          'bg-sky-600': ['New', 'Scheduled'].includes(status),
        }"
        class="ml-auto flex h-8 items-center gap-2 rounded px-2 py-1.5 leading-none text-white"
      >
        <IconExclamationCircle v-if="status === 'Failed'" :size="20" />
        {{ statusLabel }}
      </span>
    </span>
    <template v-if="isError">
      <p class="text-sm font-semibold text-rose-400">{{ error.title }}</p>
      <p class="text-sm font-light text-rose-400">
        {{ error.description }}
      </p>
      <a
        v-if="error.link"
        :href="error.link.href"
        class="link-hover link text-sm font-light text-blue-500"
        target="_blank"
      >
        {{ error.link.text }}
      </a>
    </template>
  </li>
</template>

<style lang="scss" scoped></style>
