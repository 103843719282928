<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { Clip } from '@/Hooks/useTwitchClips'
import { useElementSize } from '@vueuse/core'
import ArrowRightIcon from '@/components/Icons/Vuesax/ArrowRightIcon.vue'
import ArrowLeftIcon from '@/components/Icons/Vuesax/ArrowLeftIcon.vue'
import { useConditionalEventListener } from '@/Hooks/useConditionalEventListener'

const emit = defineEmits<{
  (event: 'confirm', clip: Clip): void
  (event: 'update:modelValue', value: string | null): void
}>()

const props = defineProps<{ clips: Clip[]; modelValue: string | null }>()
const clip = computed(() => props.clips.find(c => c.id === props.modelValue))
const isOpen = computed(() => clip.value !== undefined)

function close() {
  emit('update:modelValue', null)
}

function confirm() {
  if (clip.value) {
    close()
    emit('confirm', clip.value)
  }
}

function go(direction: number) {
  const index = props.clips.findIndex((c) => c.id === props.modelValue)
  const newIndex = (index + direction + props.clips.length) % props.clips.length
  emit('update:modelValue', props.clips[newIndex].id)
}

function next() {
  go(+1)
}

function previous() {
  go(-1)
}

function onKeyStroke(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    close()
  } else if (event.key === 'ArrowLeft') {
    previous()
  } else if (event.key === 'ArrowRight') {
    next()
  }
}

useConditionalEventListener(isOpen, 'keydown', onKeyStroke)

const src = computed(() => {
  return props.modelValue
    ? `https://clips.twitch.tv/embed?clip=${props.modelValue}&parent=${window.location.hostname}&autoplay=true`
    : null
})

const container = ref<HTMLElement>()
const { width, height } = useElementSize(container)
</script>

<template>
  <Teleport to="body">
    <div class="modal bg-zinc-950/10" :class="{ 'modal-open': isOpen }" @click="close">
      <div class="modal-box !max-w-screen-lg !p-0 layer-1" @click.prevent.stop>
        <figure class="group relative flex aspect-[16/9] overflow-hidden rounded-t" ref="container">
          <div class="bg-gradient absolute inset-0 h-full w-full opacity-10" />
          <Transition name="fade">
            <iframe
              :key="src"
              :src="src"
              :width="Math.round(Number(width))"
              :height="Math.round(Number(height))"
              class="absolute inset-0 h-full w-full"
            />
          </Transition>
          <button
            class="btn-ghost btn-circle btn absolute left-2 top-1/2 -translate-x-2 -translate-y-1/2 opacity-0 group-hover:translate-x-0 group-hover:opacity-100"
            @click="previous"
          >
            <ArrowLeftIcon class="text-white" />
          </button>
          <button
            class="btn-ghost btn-circle btn absolute right-2 top-1/2 -translate-y-1/2 translate-x-2 opacity-0 group-hover:translate-x-0 group-hover:opacity-100"
            @click="next"
          >
            <ArrowRightIcon class="text-white" />
          </button>
        </figure>
        <div class="flex flex-row gap-2 p-4">
          <div class="hover:shadow-gradient group flex-1 rounded-full shadow-xl transition-all before:transition-all">
            <div class="bg-gradient w-full rounded-full">
              <button
                @click="confirm"
                class="btn-lg btn w-full gap-2 bg-black text-lg font-bold text-white mix-blend-lighten"
              >
                Select clip
              </button>
            </div>
          </div>
          <button
            class="flex-1 self-stretch rounded-full border-2 border-current text-zinc-900 transition-all hover:bg-zinc-900/20 active:scale-90 dark:text-zinc-100 dark:hover:bg-zinc-100/20"
            @click="close"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
