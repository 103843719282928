<script lang="ts" setup>
import { ref, computed, defineAsyncComponent } from 'vue'
import { onUserInfoReady } from '@/store/user/userInfo'
import { queryProductUpdates, type ProductUpdate } from '@/queries/queryProductUpdates'
import { useUserPreferencesStore } from '@/queries/useUserPreferencesStore'
import IconSaxCloseCircle from '@/components/Icons/iconsax/IconSaxCloseCircle.vue'
import settings from '@/data/settings'

const { createUserPreferencesManager } = useUserPreferencesStore()
const data = ref<ProductUpdate>()

onUserInfoReady(async ({ userId }) => {
  if (!userId) return

  const timestampManager = createUserPreferencesManager('lastProductUpdatesReceived')

  const lastUpdateOn = await timestampManager.get()
  const response = await queryProductUpdates(new Date(lastUpdateOn ?? 0))

  if (!response || !response[0]) return
  await timestampManager.set(new Date().toISOString())
  data.value = response[0]
  isOpen.value = true
})

const attachment = computed(() => data.value?.attachments?.[0])
const isVideoAttachment = computed(() => attachment.value?.content_type?.startsWith('video/'))
const isImageAttachment = computed(() => attachment.value?.content_type?.startsWith('image/'))

const isOpen = ref(false)

const VueMarkdown = defineAsyncComponent(() => import('vue-markdown-render'))
</script>

<template>
  <teleport to="body">
    <div :class="{ 'modal-open': isOpen }" class="modal" @click="isOpen = false">
      <div class="modal-box p-0" v-if="data" @click.stop>
        <figure v-if="attachment">
          <img
            v-if="isImageAttachment"
            class="w-full"
            :src="attachment.url"
            :alt="attachment.filename"
            :width="attachment.width"
            :height="attachment.height"
            crossorigin="anonymous"
          />
          <video
            autoplay
            playsinline
            loop
            muted
            preload="auto"
            disableRemotePlayback
            disablePictureInPicture
            v-if="isVideoAttachment"
            class="w-full"
            :src="attachment.url.replace('cdn.discordapp.com', 'discord-productupdates.b-cdn.net')"
            :width="attachment.width"
            :height="attachment.height"
            crossorigin="anonymous"
          />
        </figure>
        <div class="prose prose-zinc m-8 hover:prose-a:no-underline">
          <VueMarkdown
            v-if="data.content"
            :source="data.content"
            :options="{
              breaks: true,
              linkify: true,
              typographer: true,
            }"
          />
          <a :href="settings.discordInviteUrl" target="_blank" class="text-indigo-500 underline">
            Got feedback? Let us know on Discord!
          </a>
        </div>

        <footer class="m-8 flex flex-row justify-between">
          <button class="btn btn-accent" @click="isOpen = false">Dismiss</button>
          <button class="btn btn-primary" @click="isOpen = false">Try it out</button>
        </footer>

        <button
          type="button"
          class="absolute right-2 top-2 rounded-lg bg-zinc-100 p-1.5 text-zinc-900 shadow transition-all hover:bg-zinc-200 hover:text-zinc-800 active:scale-90"
          @click="isOpen = false"
        >
          <IconSaxCloseCircle />
        </button>
      </div>
    </div>
  </teleport>
</template>

<style lang="scss" />
