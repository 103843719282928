<script setup lang="ts">
import IconSaxNotification from '@/components/Icons/iconsax/IconSaxNotification.vue'
import NavigationFlyout from '@/areas/dashboard/components/NavigationFlyout.vue'
import Spinner from '@/components/Icons/Spinner.vue'
import DashboardNotificationFlyoutItem from '@/areas/dashboard/layout/nav/DashboardNotificationFlyoutItem.vue'
import DashboardNavigationItem from '@/areas/dashboard/layout/nav/DashboardNavigationItem.vue'
import { useNotifications, useMarkAllNotificationsAsRead } from '@/modules/NotificationService/NotificationService'
import { ref } from 'vue'
import { useMutation } from '@tanstack/vue-query'

const isOpen = ref(false)

const { notifications, isLoading: loadingNotifications } = useNotifications()
const markAllNotificationsAsRead = useMarkAllNotificationsAsRead()
const { mutate: clearAll, isLoading: isMarkingAllAsRead } = useMutation(markAllNotificationsAsRead)
</script>

<template>
  <NavigationFlyout v-model="isOpen" class="flex w-full flex-col" arrow>
    <template #button="{ ...props }">
      <DashboardNavigationItem v-bind="props" :is-active="isOpen">
        <div class="relative">
          <IconSaxNotification />
          <span
            class="absolute right-0 top-0 h-2.5 w-2.5 rounded-full border-2 border-white bg-red-500"
            v-if="notifications.length > 0"
          />
        </div>
        <span class="flex w-full flex-row justify-between">
          <span class="flex-auto text-left">Notifications</span>
          <span
            class="flex h-6 min-w-[1.5rem] shrink flex-col items-center justify-center rounded-full bg-red-500 p-1 text-xs text-white"
            v-if="notifications.length > 0"
          >
            {{ Math.min(notifications.length, 999) }}
            <template v-if="notifications.length > 999">+</template>
          </span>
        </span>
      </DashboardNavigationItem>
    </template>

    <template #menu="{ ...props }">
      <div v-bind="props" class="min-w-32 flex max-w-lg">
        <template v-if="notifications.length === 0 || isMarkingAllAsRead">
          <p class="mb-0 flex min-w-[300px] items-center justify-center p-4">
            <template v-if="loadingNotifications || isMarkingAllAsRead">
              <Spinner class="h-8 w-8 animate-spin" />
            </template>
            <template v-else>You are all up to date with your notifications!</template>
          </p>
        </template>
        <div class="flex max-h-[50vh] flex-col items-stretch gap-2" v-else>
          <header class="flex items-center justify-between p-2">
            <h5 class="text-2xl font-semibold leading-[1]">Notifications</h5>
            <button
              v-if="notifications.length > 0"
              :disabled="isMarkingAllAsRead"
              @click="clearAll"
              class="text-sm text-sky-600 underline transition-all hover:no-underline active:scale-90"
            >
              <span class="loading" v-if="isMarkingAllAsRead" />
              Clear all
            </button>
          </header>
          <ol v-if="notifications.length > 0 && isOpen" class="flex flex-col items-stretch gap-2 overflow-y-auto">
            <DashboardNotificationFlyoutItem
              @click="isOpen = false"
              v-for="notification in notifications"
              :key="notification.id"
              :notification="notification"
            />
          </ol>
          <p v-else class="mb-0 flex min-w-[300px] items-center justify-center p-4">
            You are all up to date with your notifications!
          </p>
        </div>
      </div>
    </template>
  </NavigationFlyout>
</template>

<style scoped lang="scss"></style>
