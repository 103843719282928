import type {
  CaptionGrouping,
  CaptionOptions,
  CaptionsDocument,
  Sentence,
  StoredCaption,
} from '@/components/Captions/captionTypes'
import type { CaptionStyle, CaptionStyleDefinition } from '@/components/Captions/styles/captionStyles'
import { v4 } from 'uuid'
import { emojify } from '@/helpers/emojify/emojify'
import { captionStylesSettings } from '@/components/Captions/styles/CaptionStyleManager'

const speakerStrings = ['A', 'B', 'C', 'D', 'E', 'F']

export const preprocessCaptions = async (captionDocument: CaptionsDocument, captionStyleKey: CaptionStyle) => {
  if (!captionDocument || !captionStyleKey) return []

  const captionStyle = captionStylesSettings[captionStyleKey]

  let _storedCaptions: StoredCaption[]

  // Group the captions based on the selected caption styles
  _storedCaptions = groupCaptionWords(captionDocument, captionStyle)

  _storedCaptions = reduceGapBetweenCaptions(_storedCaptions)

  _storedCaptions.forEach((caption) => {
    caption.words.forEach((word) => {
      word.id = v4()
    })
  })

  // Do per caption preprocessing
  _storedCaptions = await Promise.all(_storedCaptions.map((caption) => preprocessCaption(caption, captionStyle)))

  // Set the captions in the store
  return _storedCaptions
}

export const preprocessCaption = async (
  caption: StoredCaption,
  captionStyle: CaptionStyleDefinition
): Promise<StoredCaption> => {
  const _caption = caption

  // get index of speaker
  let speakerIndex = speakerStrings.findIndex((speaker) => _caption.words[0].speaker === speaker)
  speakerIndex = 0
  // Set the caption color
  _caption.color = captionStyle.colors[speakerIndex % captionStyle.colors.length]

  _caption.words.forEach((word) => {
    if (word.Highlighted) {
      word.color = captionStyle.highlightColor
    }
  })

  return _caption
}

export const groupCaptionWords = (captionDocument: CaptionsDocument, grouping: CaptionGrouping): StoredCaption[] => {
  if (grouping === 'single') return captionDocument.words.map((word) => ({ ...word, words: [word] }))
  if (grouping === 'sentence') return captionDocument.sentences

  const maxGroupWords = 5
  const maxTimeDiffMs = 1000
  const _captions: Sentence[] = []
  let _currentCaption: Sentence | null = null
  let currentCaptionWords = 0

  function flushCurrentCaption() {
    if (_currentCaption) _captions.push(_currentCaption)
    _currentCaption = null
    currentCaptionWords = 0
  }

  // We group the words in each sentence by an X amount of words, with a max time difference between the end/start of each word group
  captionDocument.sentences.forEach((sentence) => {
    sentence.words.forEach((word) => {
      if (!_currentCaption) {
        _currentCaption = { ...word, words: [word] }
      } else if (currentCaptionWords < maxGroupWords && word.start - _currentCaption.end < maxTimeDiffMs) {
        _currentCaption.text += ' ' + word.text
        _currentCaption.words.push(word)
        _currentCaption.end = word.end
      } else {
        flushCurrentCaption()
        _currentCaption = { ...word, words: [word] }
      }
      currentCaptionWords++
    })

    // Always flush at the end of a sentence
    if (_currentCaption) {
      flushCurrentCaption()
    }
  })

  return _captions
}

export const addMetaDataToCaptions = (captions: StoredCaption[]) => {
  captions.forEach((caption, index) => {
    caption.id = v4()
    caption.randomizer = Math.random()
  })
}

export const addEmojiToCaptions = async (captions: StoredCaption[]) => {
  const emojis: string[][] = []
  for (let i = 0; i < captions.length; i++) {
    if (captions[i].emojis && captions[i].emojis?.length > 0) continue
    emojis[i] = await emojify(captions[i], {
      maxEmojis: 1,
      onlyHighlighted: false,
    })
  }
  captions.forEach((caption, index) => {
    caption.emojis = emojis[index]
  })
}

export const reduceGapBetweenCaptions = (captions: StoredCaption[]) => {
  captions.forEach((caption, index) => {
    const nextCaption = captions[index + 1]
    if (!nextCaption) return

    const gap = nextCaption.start - caption.end
    if (gap < 100) {
      caption.end = nextCaption.start
    }
  })
}

export const highlightWords = (caption: StoredCaption): string => {
  return caption.words
    .map((word) => (word.Highlighted ? wrapStringWithSpan(word.text, 'highlight') : word.text))
    .join(' ')
}
const wrapStringWithSpan = (text: string, className: string) => {
  return `<span class="${className}">${text}</span>`
}
