import type { Notification, NotificationAdapter } from '@/modules/NotificationService/NotificationAdapter'
import { getApiRenders, putApiRendersIdRead, putApiRendersReadall } from '@/apis/streamladder-api/renders/renders'
import type { RenderDto } from '@/apis/streamladder-api/model'
import { truncate } from 'lodash-es'
import type { RouteLocation, RouteLocationRaw } from 'vue-router'

export class SLVideoRendersAdapter implements NotificationAdapter {
  public readonly name = 'render'
  private identifier = 'render-notification-'

  public getIdentifier(id?: string): string {
    return this.identifier + (id || '')
  }

  public async getUnreadNotifications(): Promise<Notification<RenderDto>[]> {
    const page = await getApiRenders({
      IsRead: false,
    })

    const renders = page.value?.items ?? []

    return renders.map((render) => {
      let to = {} as RouteLocationRaw | undefined
      if (render.status === 'success') {
        to = {
          name: 'Result',
          query: { resultUrl: render.resultUrl, clipName: render.title },
        }
      } else if (render.status === 'failed') {
        to = undefined
      } else {
        to = {
          name: 'GenerateServerSideQueue',
          query: {
            task: render.id,
          },
        }
      }

      return {
        id: `${this.identifier}${render.id}`,
        type: 'render',
        title: truncate(render.title ?? 'New render', { length: 22 }),
        description: render.statusMessage,
        to,
        data: render,
      } as Notification<RenderDto>
    })
  }

  public async markNotificationAsRead(notificationId: string): Promise<void> {
    await putApiRendersIdRead(notificationId.replace(this.identifier, ''))
  }

  public async markAllNotificationsAsRead(): Promise<void> {
    await putApiRendersReadall()
  }
}
