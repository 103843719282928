<script lang="ts" setup>
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { computed, ref } from 'vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import SocialMediaConnectionItem from '@/areas/dashboard/pages/account/partial/SocialMediaConnectionItem.vue'
import ConnectSocialDialog from '@/areas/dashboard/components/ConnectSocialDialog.vue'
import { useHead } from '@unhead/vue'
import { canGuard } from '@/Hooks/useGuard'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'

const userInfo = useUserInfoStore()

const connections = computed(() => userInfo.allSocials)
const isDialogOpen = ref(false)

useHead({
  title: 'Social Media Connections',
  meta: [
    {
      name: 'description',
      content: 'Connect your social media accounts to schedule and post clips.',
    },
  ],
})
</script>

<template>
  <main class="flex flex-col gap-8 p-4 lg:gap-16 lg:p-12">
    <div class="flex flex-row flex-wrap items-end justify-between gap-4">
      <header class="flex flex-col">
        <h1 class="text-3xl lg:text-5xl">Social media connections</h1>
      </header>

      <GradientButton v-if="connections.length !== 0" @click="isDialogOpen = true">
        <DynamicPlanButtonWithTooltip feature="multiple-publishing-accounts" />
        Connect new account
        <IconSaxAdd />
      </GradientButton>
    </div>

    <NoContentSection
      v-if="connections.length === 0"
      subtitle="You can schedule and post clips by connecting an account"
      title="No social media accounts connected"
    >
      <GradientButton @click="isDialogOpen = true">
        Connect your socials
        <IconSaxAdd />
      </GradientButton>
    </NoContentSection>

    <ol v-else class="flex flex-col gap-3">
      <li v-for="connection of connections" :key="connection.id">
        <SocialMediaConnectionItem :account="connection" />
      </li>
    </ol>
  </main>

  <ConnectSocialDialog v-model="isDialogOpen" />
</template>

<style lang="scss" scoped></style>
