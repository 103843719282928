<script lang="ts" setup>
import DynamicPlanButton from '@/components/Account/Upgrade/DynamicPlanButton.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { capitalize, computed } from 'vue'
import features from '@/data/features'
import type { Feature } from '@/data/features'
import { tiers } from '@/enums/tiers'
import SimpleTooltip from '@/components-v2/data-display/SimpleTooltip.vue'

const props = defineProps<{ feature: Feature; size?: number | string; class?: string }>()

const store = useUserInfoStore()
const tooltip = computed(() => {
  const feature = features.find((f) => f.name === props.feature)
  if (!feature) {
    return null
  } else if (store.tier >= feature.tier) {
    return `Unlocked with your ${capitalize(store.plan)} Subscription!`
  } else {
    return `Upgrade to ${capitalize(tiers.toString(feature.tier))} to unlock ${feature.alias ?? feature.name}`
  }
})
</script>

<template>
  <SimpleTooltip v-if="store.tier < features.find((f) => f.name === props.feature)?.tier">
    <template #trigger>
      <DynamicPlanButton :size="size" :class="props.class" small force-show :feature="feature" />
    </template>
    <template #content>
      <span class="text-xs font-bold uppercase">{{ tooltip }}</span>
    </template>
  </SimpleTooltip>
</template>

<style scoped lang="scss"></style>
