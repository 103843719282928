import { defineStore, acceptHMRUpdate } from 'pinia'
import type { LayoutKey } from '@/data/layoutData'
import type { ScaleSet } from '@/modules/SLVideoplayer/types'
import type { StickerData } from '@/store/editor/editorStickers'

interface UserTemplatesState {
  savedTemplates: Template[]
}

export const useUserTemplatesStore = defineStore('userTemplates', {
  state: (): UserTemplatesState => {
    return {
      savedTemplates: [],
    }
  },
  getters: {
    getTemplateById: (state) => (id: string) => {
      return state.savedTemplates.find((t) => t.id === id)
    },
  },
})

export interface Template {
  id: string
  layoutName: LayoutKey
  templateName: string
  ratio: string
  createdTime: string
  croppers: {
    cropperKey: string
    data: ScaleSet
  }[]
  feeds: {
    feedKey: string
    data: ScaleSet
  }[]
  stickers: StickerData[]
}

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useUserTemplatesStore, import.meta.hot))
}
