import type { CaptionStyleDefinition } from '@/components/Captions/captionTypes'

export const captionBaseStylesSettings = {
  lit: {
    previewSettings: {
      displayName: 'Lit!',
      scaling: 1.3,
      previewText: [
        'Lit',
        'Head-turning',
        'Unleash',
        'the',
        'Lit',
        'style',
        'which',
        'is',
        'as',
        'bold',
        'as',
        'it',
        'is',
        'head-turning',
        'like',
        'walking',
        'into',
        'a',
        'board',
        'meeting',
        'with',
        'a',
        'superhero',
        'cape.',
      ],
    },
    createdAt: new Date(1970, 0, 1),
    cssClass: 'epic-gamer uppercase',
    fontFamily: 'Anton',
    fontSize: {
      fontSize: 38,
    },
    style: {
      border: {
        color: '#000000',
        width: 4,
      },
      color: 'default',
    },
    colors: ['#FFFFFF', '#FAFF00', '#00FF57', '#00FFD1', '#FF56EE', '#FF7A00FF', '#ff00ff'],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['popular', 'bold', 'simple'],
  } as CaptionStyleDefinition,
  puff: {
    previewSettings: {
      displayName: 'Puff!',
      scaling: 1.6,
      previewText: [
        'Puff',
        'Joyful',
        'Embrace',
        'the',
        'Puff',
        'style',
        'a',
        'bouncy',
        'colorful',
        'spectacle',
        'that',
        'is',
        'as',
        'playful',
        'and',
        'joyful',
        'as',
        'a',
        'kid',
        'with',
        'a',
        'new',
        'toy',
        'on',
        'Christmas',
        'morning.',
      ],
    },
    createdAt: new Date(1970, 0, 1),
    cssClass: 'puff uppercase',
    fontFamily: 'DynaPuff',
    fontSize: {
      fontSize: 30,
    },
    style: {
      border: {
        color: 'darker',
        width: 4,
      },
      color: 'default',
    },
    colors: ['#FFBFE5', '#00FF75', '#DBFF00', '#FF9900', '#FFD1CE', '#EFCEFF'],
    highlightColor: '#00FF75',
    effects: {},
    tags: ['popular', 'bold'],
  } as CaptionStyleDefinition,
  'hype-train': {
    previewSettings: {
      displayName: 'Hype train is rolling',
      scaling: 1.3,
      previewText: [
        'Hype',
        'train',
        'is',
        'rolling',
        'Eye-Catching',
        'Welcome',
        'aboard',
        'the',
        'Hype',
        'train',
        'style',
        'all',
        'aboard',
        'for',
        'bright',
        'colors',
        'and',
        'a',
        'radiant',
        'glow',
        'that',
        'is',
        'as',
        'eye-catching',
        'as',
        'a',
        'comet',
        'streaking',
        'through',
        'the',
        'night',
        'sky.',
      ],
    },
    createdAt: new Date(1970, 0, 1),
    cssClass: 'hype-train uppercase',
    fontFamily: 'Bungee',
    fontSize: {
      fontSize: 30,
      lineHeight: 1.1,
    },
    style: {
      border: {
        color: 'darker',
        width: 3,
      },
      shadow: {
        color: 'darker',
        width: 20,
      },
      color: 'default',
    },
    colors: ['#00FFF0', '#FFE500', '#FF00A8', '#00FF66', '#FF0000'],
    highlightColor: '#FFE500',
    effects: {},
    tags: ['bold'],
  } as CaptionStyleDefinition,

  funky: {
    previewSettings: {
      displayName: 'Funky!',
      scaling: 1.3,
      previewText: [
        'Funky',
        'Exuberant',
        'Release',
        'your',
        'inner',
        'disco',
        'with',
        'the',
        'Funky',
        'style',
        'as',
        'playful',
        'and',
        'exuberant',
        'as',
        'a',
        'peacock',
        'doing',
        'the',
        'moonwalk.',
      ],
    },
    createdAt: new Date(1970, 0, 1),
    cssClass: 'funky uppercase',
    fontFamily: 'Bangers',
    fontSize: {
      fontSize: 38,
    },
    style: {
      border: {
        color: '#000000',
        width: 3,
      },
      color: 'default',
    },
    colors: ['#FF7A00', '#00FFA3', '#F4F9FF', '#FF5555', '#FFE600'],
    highlightColor: '#F4F9FF',
    effects: {},
    tags: ['popular', 'bold'],
  } as CaptionStyleDefinition,
  'bold-glow': {
    previewSettings: {
      displayName: 'Glowzilla',
      scaling: 1,
      previewText: [
        'Bold',
        'glow',
        'Lustrous',
        'Stand',
        'out',
        'from',
        'the',
        'crowd',
        'with',
        'Bold',
        'glow',
        'style',
        'as',
        'lustrous',
        'as',
        'a',
        'diamond',
        'ring',
        'in',
        'a',
        'treasure',
        'chest.',
      ],
    },
    createdAt: new Date(1970, 0, 1),
    cssClass: 'uppercase',
    fontFamily: 'Metropolis-extra-bold',
    fontSize: {
      fontSize: 38,
      lineHeight: 0.9,
      // fontWeight: 800,
    },
    style: {
      border: {
        color: 'darker',
        width: 2,
      },
      shadow: {
        color: 'darker',
        width: 10,
      },
      color: 'default',
    },
    colors: ['#00FFA3', '#30F3FF', '#FF30EA', '#FF3049', '#FFEA30', '#30FF38'],
    highlightColor: '#30F3FF',
    effects: {},
    tags: ['bold'],
  } as CaptionStyleDefinition,

  Neon: {
    previewSettings: {
      displayName: 'Neon Style',
      scaling: 1.3,
      previewText: [
        'Neon',
        'Style',
        'Luminous',
        'Turn',
        'the',
        'night',
        'into',
        'day',
        'with',
        'Neon',
        'Style',
        'as',
        'bold',
        'and',
        'luminous',
        'as',
        'a',
        'city',
        'skyline',
        'during',
        'a',
        'festival',
        'of',
        'lights.',
      ],
    },
    createdAt: new Date(1970, 0, 1),
    cssClass: 'neon uppercase',
    fontFamily: 'Sigmar One',
    fontSize: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: 1,
    },
    style: {
      color: 'default',
      border: {
        color: 'darker',
        width: 3,
      },
      shadow: {
        color: 'darker',
        width: 20,
      },
    },
    colors: ['#FFEA30', '#30FF9C', '#FFFFFF', '#30F3FF', '#FF30EA', '#FF3049', '#30FF38'],
    highlightColor: '#30FF9C',
    effects: {},
    tags: ['bold'],
  } as CaptionStyleDefinition,
  wasted: {
    previewSettings: {
      displayName: 'Wasted',
      scaling: 1.6,
      previewText: [
        'Wasted',
        'Intense',
        'Make',
        'a',
        'statement',
        'with',
        'the',
        'Wasted',
        'style',
        'as',
        'bold',
        'and',
        'intense',
        'as',
        'a',
        'cowboy',
        'entering',
        'a',
        'saloon',
        'with',
        'spurs',
        'jingling.',
      ],
    },
    createdAt: new Date(1970, 0, 1),
    cssClass: 'WoodenNickelNF uppercase',
    fontFamily: 'WoodenNickelNF',
    fontSize: {
      fontSize: 30,
      lineHeight: 1,
    },
    style: {
      border: {
        color: 'darker',
        width: 2,
      },
      shadow: {
        color: 'darker',
        width: 10,
      },
      color: 'default',
    },
    colors: ['#FF3049', '#00FFA3', '#FFFFFF', '#30F3FF', '#FF30EA', '#FFEA30', '#30FF38'],
    highlightColor: '#00FFA3',
    effects: {},
    tags: ['bold'],
  } as CaptionStyleDefinition,
  kappa: {
    previewSettings: {
      displayName: 'kappa',
      scaling: 1,
      previewText: [
        'Kappa',
        'Noteworthy',
        'Dive',
        'into',
        'the',
        'color',
        'pool',
        'with',
        'Kappa',
        'style',
        'as',
        'distinctive',
        'and',
        'noteworthy',
      ],
    },
    createdAt: new Date(1970, 0, 1),
    cssClass: 'kappa uppercase',
    fontFamily: 'Metropolis',
    fontSize: {
      fontSize: 30,
      lineHeight: 1.4,
    },
    style: {
      color: 'default',
    },
    colors: ['#333333', '#ff00ff', '#ea4040', '#009f50', '#006be9', '#FFFFFF'],
    highlightColor: '#5227FF',
    disableHighlight: true,
    effects: {},
    tags: ['popular', 'simple'],
  } as CaptionStyleDefinition,
  Basic: {
    previewSettings: {
      displayName: 'Basic',
      previewText: [
        'Basic',
        'Easy-going',
        'Keep',
        'it',
        'real',
        'with',
        'Basic',
        'style',
        'as',
        'simple',
        'and',
        'easy-going',
        'as',
        'a',
        'zen',
        'monk',
        'on',
        'a',
        'Sunday',
        'stroll.',
      ],
      scaling: 1.6,
    },
    createdAt: new Date(1970, 0, 1),
    cssClass: 'basic',
    fontFamily: 'Metropolis-bold',
    fontSize: {
      fontSize: 30,
      // fontWeight: 700,
      lineHeight: 1,
    },
    style: {
      color: 'default',
    },
    colors: ['#000000', '#FFEA30', '#30FF9C', '#FFFFFF', '#30F3FF', '#FF30EA', '#FF3049', '#30FF38'],
    highlightColor: '#5227FF',
    effects: {
      textTransform: 'none',
    },
    tags: ['popular', 'simple'],
  } as CaptionStyleDefinition,
  Chuckle: {
    previewSettings: {
      displayName: 'Chuckle',
      scaling: 1.6,
      previewText: [
        'Chuckle',
        'Entertaining',
        'Get',
        'a',
        'grin',
        'with',
        'Chuckle',
        'style',
        'as',
        'fun',
        'and',
        'entertaining',
        'as',
        'a',
        'clown',
        'at',
        'a',
        'circus-themed',
        'party.',
      ],
    },
    createdAt: new Date(2023, 9, 1),
    cssClass: 'chuckle',
    fontFamily: 'Metropolis-bold',
    fontSize: {
      fontSize: 30,
      // fontWeight: 700,
      lineHeight: 1,
    },
    style: {
      color: 'default',
      border: {
        color: '#000000',
        width: 2,
      },
    },
    colors: ['#FFFFFF', '#FFEA30', '#30FF9C', '#30F3FF', '#FF30EA', '#FF3049', '#30FF38'],
    highlightColor: '#5227FF',
    effects: {
      textTransform: 'none',
    },
    tags: ['simple'],
  } as CaptionStyleDefinition,
  Noir: {
    previewSettings: {
      lottieUrl: '',
      displayName: 'Fun',
      scaling: 1.5,
      previewText: ['Fun', 'Dive', 'into', 'FUN', 'and', 'watch', 'the', 'magic ✨', 'happen'],
    },
    createdAt: new Date(2023, 9, 23),
    fontFamily: 'Noir Pro',
    fontSize: {
      fontSize: 29,
      lineHeight: 1.375,
    },
    cssClass: 'uppercase',
    style: {
      border: {
        color: '#99325F',
        width: 1,
      },
      dropShadow: {
        color: '#32101F',
        offset: { x: 0, y: 0 },
        blur: 5,
        opacity: 0.5,
      },
      color: 'default',
    },
    colors: ['#FFC700', '#E087FF', '#87FF93', '#00B728', '#F9D828', '#009245', '#EE9CA7'],
    highlightColor: '#E087FF',
    gradients: {
      '#FFC700': 'linear-gradient(to bottom, #E087FF, #FFC700)',
      '#E087FF': 'linear-gradient(to bottom, #00F0FF, #E087FF)',
      '#87FF93': 'linear-gradient(to top, #00E0FF, #87FF93)',
      '#009245': 'radial-gradient(circle, #FCEE21, #009245)',
      '#EE9CA7': 'radial-gradient(circle, #FFDDE1, #EE9CA7)',
      '#00B728': 'linear-gradient(to bottom, #28F9D3, #00B728)',
      '#F9D828': 'linear-gradient(to bottom, #F92828, #F9D828)',
    },
    borderColors: {
      '#E087FF': '#99325F',
      '#FFC700': '#99325F',
      '#87FF93': '#99325F',
      '#009245': '#097600',
      '#EE9CA7': '#6F2F46',
      '#00B728': '#097600',
      '#F9D828': '#99325F',
    },
    tags: ['halloween', 'popular', 'bold'],
    effects: {},
    disableHighlight: false,
  },
  duckie: {
    previewSettings: {
      displayName: 'Duckie',
      scaling: 1.3,
      previewText: ['Duckie', 'Quirky', 'Get', 'a', 'quack', 'out', 'of', 'Duckie', 'style'],
    },
    createdAt: new Date(2024, 3, 1),
    cssClass: 'uppercase',
    fontFamily: 'Luckiest Guy',
    fontSize: {
      fontSize: 38,
    },
    style: {
      border: {
        color: '#000000',
        width: 4,
      },
      color: 'default',
    },
    colors: ['#FFFFFF', '#FAFF00', '#00FF57', '#00FFD1', '#FF56EE', '#FF7A00FF', '#ff00ff'],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['bold', 'popular', 'simple'],
  } as CaptionStyleDefinition,
  ninja: {
    previewSettings: {
      displayName: 'Ninja!',
      scaling: 1.3,
      previewText: [
        'Ninja! 🥷',
        'Stealthy',
        'Embrace',
        'the',
        'Ninja!',
        'style',
        'as',
        'stealthy',
        'and',
        'mysterious',
        'as',
        'a',
        'ninja',
      ],
    },
    createdAt: new Date(2024, 3, 1),
    cssClass: 'uppercase',
    fontFamily: 'Sovereign',
    fontSize: {
      fontSize: 38,
    },
    style: {
      border: {
        color: 'darker',
        width: 4,
      },
      color: 'default',
    },
    colors: ['#43D2FE', '#FAFF00', '#00FF57', '#00FFD1', '#FF56EE', '#FF7A00FF', '#ff00ff'],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['bold'],
  } as CaptionStyleDefinition,
  quirkonia: {
    previewSettings: {
      displayName: 'Quirkonia',
      scaling: 1.3,
      previewText: [
        'Quirkonia',
        'Quirky',
        'Embrace',
        'the',
        'Quirkonia',
        'style',
        'as',
        'quirky',
        'and',
        'fun',
        'as',
        'a',
        'clown',
        'at',
        'a',
        'circus-themed',
        'party.',
      ],
    },
    createdAt: new Date(2024, 3, 1),
    cssClass: '',
    fontFamily: 'Francy',
    fontSize: {
      fontSize: 38,
    },
    style: {
      border: {
        color: 'darker',
        width: 1,
      },
      color: 'default',
      shadow: {
        color: 'default',
        width: 16,
      },
    },
    colors: ['#E7E0B2', '#FAFF00', '#00FF57', '#00FFD1', '#FF56EE', '#FF7A00FF', '#ff00ff'],
    highlightColor: '#FAFF00',
    effects: {},
  } as CaptionStyleDefinition,
  'gaming yuu': {
    previewSettings: {
      displayName: 'gaming yuu!',
      scaling: 1.3,
      previewText: [
        'Gaming yuu!',
        'Gaming',
        'Embrace',
        'the',
        'Gaming yuu!',
        'style',
        'like',
        'a',
        'gamer',
        'on',
        'a',
        'quest',
      ],
    },
    createdAt: new Date(2024, 3, 1),
    cssClass: 'capitalize',
    fontFamily: 'Spot',
    fontSize: {
      fontSize: 38,
    },
    style: {
      border: {
        color: '#3D3D3D',
        width: 3,
      },
      color: 'default',
      shadow: {
        color: '#000000',
        width: 6,
      },
    },
    colors: ['#FAFF00', '#00FF57', '#00FFD1', '#FF56EE', '#FF7A00FF', '#ff00ff'],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['bold'],
  } as CaptionStyleDefinition,
  'swag city': {
    previewSettings: {
      displayName: 'swag city',
      scaling: 1.3,
      previewText: ['swag city', 'Walk', 'Embrace', 'the city', 'with style', 'like a', 'swag city'],
    },
    createdAt: new Date(2024, 3, 1),
    cssClass: 'capitalize',
    fontFamily: 'Daddy Ranch',
    fontSize: {
      fontSize: 38,
    },
    style: {
      border: {
        color: '#3D3D3D',
        width: 2,
      },
      color: 'default',
      shadow: {
        color: '#000000',
        width: 3,
      },
    },
    colors: ['#FFDA55', '#00FF57', '#00FFD1', '#FF56EE', '#FF7A00FF', '#ff00ff'],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['bold'],
  } as CaptionStyleDefinition,
  'Big slick': {
    previewSettings: {
      displayName: 'Big slick',
      scaling: 1.3,
      previewText: ['Big slick', 'Walk', 'Embrace', 'the city', 'with style', 'like a', 'swag city'],
    },
    createdAt: new Date(2024, 3, 1),
    cssClass: 'capitalize',
    fontFamily: 'Pedlefun',
    fontSize: {
      fontSize: 38,
    },
    style: {
      border: {
        color: 'darker',
        width: 2,
      },
      color: 'default',
      shadow: {
        color: 'darker',
        width: 14,
      },
    },
    colors: ['#9FFF87', '#00FF57', '#00FFD1', '#FF56EE', '#FF7A00FF', '#ff00ff'],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['bold'],
  } as CaptionStyleDefinition,
  'battle royal': {
    previewSettings: {
      displayName: 'battle royal',
      scaling: 1.3,
      previewText: ['battle royal', 'Walk', 'Embrace', 'the city', 'with style', 'like a', 'swag city'],
    },
    createdAt: new Date(2024, 3, 1),
    cssClass: 'capitalize',
    fontFamily: 'Fortnite',
    fontSize: {
      fontSize: 38,
    },
    style: {
      border: {
        color: '#373737',
        width: 2,
      },
      color: 'default',
      shadow: {
        color: '#000000',
        width: 14,
      },
    },
    colors: ['#9FFF87', '#00FF57', '#00FFD1', '#FF56EE', '#FF7A00FF', '#ff00ff'],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['bold'],
  } as CaptionStyleDefinition,
  'big glow': {
    previewSettings: {
      displayName: 'Big glow',
      scaling: 1.3,
      previewText: ['Big glow', 'Walk', 'Embrace', 'the city', 'with style', 'like a', 'swag city'],
    },
    createdAt: new Date(2024, 3, 1),
    cssClass: 'capitalize',
    fontFamily: 'Luckiest Guy',
    fontSize: {
      fontSize: 38,
    },
    style: {
      border: {
        color: '#373737',
        width: 3,
      },
      color: 'default',
      shadow: {
        color: 'default',
        width: 20,
      },
    },
    colors: ['#FFFFFF', '#00FF57', '#00FFD1', '#FF56EE', '#FF7A00FF', '#ff00ff'],
    highlightColor: '#FAFF00',
    effects: {},
    tags: ['bold'],
  } as CaptionStyleDefinition,
  'Flash Fail': {
    previewSettings: {
      displayName: 'Flash Fail',
      scaling: 1.3,
      previewText: ['Flash Fail', 'Walk', 'Embrace', 'the city', 'with style', 'like a', 'swag city'],
    },
    createdAt: new Date(2024, 3, 1),
    cssClass: '',
    fontFamily: 'Beaufort',
    fontSize: {
      fontSize: 38,
    },
    style: {
      border: {
        color: 'darker',
        width: 1,
      },
      color: 'default',
      shadow: {
        color: 'default',
        width: 2,
      },
    },
    colors: ['#E7E0B2', '#00FF57', '#00FFD1', '#FF56EE', '#FF7A00FF', '#ff00ff'],
    highlightColor: '#FAFF00',
    effects: {},
    tags: [''],
  } as CaptionStyleDefinition,
  'Basic Jan': {
    previewSettings: {
      displayName: 'Basic Jan',
      scaling: 1.3,
      previewText: ['Basic Jan', 'Walk', 'Embrace', 'the city', 'with style', 'like a', 'swag city'],
    },
    createdAt: new Date(2024, 3, 1),
    cssClass: '',
    fontFamily: 'Pulse',
    fontSize: {
      fontSize: 38,
    },
    style: {
      border: {
        color: '#00000',
        width: 1,
      },
      color: 'default',
      shadow: {
        color: '#00000',
        width: 2,
      },
    },
    colors: ['#E7E0B2', '#00FF57', '#00FFD1', '#FF56EE', '#FF7A00FF', '#ff00ff'],
    highlightColor: '#FAFF00',
    effects: {},
    tags: [''],
  } as CaptionStyleDefinition,
} as const
