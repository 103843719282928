<template>
  <main class="flex h-full w-full flex-col gap-8 p-6 lg:p-12">
    <div class="flex w-full items-center justify-between gap-4">
      <h1 class="text-3xl lg:text-5xl">Content Publisher</h1>
      <router-link :to="{ name: 'schedule-video' }">
        <GradientButton class="rounded-full !p-2 font-semibold lg:!px-6">
          <span class="hidden md:flex">Schedule post</span>
          <IconSaxAdd class="fill-current [&>path]:stroke-2" />
        </GradientButton>
      </router-link>
    </div>
    <ContentPublisher @schedule="scheduleVideo" />
  </main>
</template>

<script lang="ts" setup>
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import { useHead } from '@unhead/vue'
import { useRouter } from 'vue-router'
import { defineAsyncComponent } from 'vue'

const ContentPublisher = defineAsyncComponent(() => import('@/components-v2/content-publisher/ContentPublisher.vue'))

useHead({
  title: 'Content Publisher',
  meta: [
    {
      name: 'description',
      content: 'Schedule your clips to be published on TikTok, Instagram and YouTube.',
    },
  ],
})

const router = useRouter()

function scheduleVideo(query: { videoId: string; date: string } | { postDate: string }) {
  router.push({ name: 'schedule-video', query })
}
</script>

<style lang="scss"></style>
