<template>
  <div>
    <base-dialog ref="qrDialog" :closeable="true" :showDismissButton="true">
      <h5 class="dark:text-zinc-100">QR-code</h5>
      <a :href="urlToResult">
        <canvas id="qrCodeResult" ref="qrCodeContainer" style="width: 100%; margin-top: 2em"></canvas>
      </a>
      <p class="dark:text-zinc-100 my-2">Scan this QR-code with your phone to download the clip to your phone.</p>
      <div class="flex w-full justify-end">
        <button class="btn-outline btn-primary btn-block btn" @click="$refs.qrDialog.close()" :show-icon="false">
          Close
        </button>
      </div>
    </base-dialog>
  </div>
</template>

<script>
import QRious from 'qrious'
import BaseDialog from '../Dialog/BaseDialog.vue'
import canFeature from '../../mixins/canFeature'
import { upgradeDialog } from '@/helpers/upgradeDialog'

export default {
  components: {
    BaseDialog,
  },
  mixins: [canFeature],
  data() {
    return {
      urlToResult: '',
    }
  },
  methods: {
    validate() {
      if (!this.userCan('qr-code-download')) {
        upgradeDialog.open('qr-code-download')

        return false
      }
      return true
    },
    async publish(videoUrl) {
      this.urlToResult =
        location.origin + this.$router.resolve({ name: 'DownloadResult', query: { downloadSrc: encodeURIComponent(videoUrl) } }).href
      await this.setQrCode(this.urlToResult)
    },
    async setQrCode(text) {
      this.$refs.qrDialog.open()
      this.$nextTick(() => {
        new QRious({
          element: document.getElementById('qrCodeResult'),
          value: text,
          size: 400,
        })
      })
    },
  },
}
</script>

<style></style>
