import { useGetApiTwitchClipsStreamer } from '@/apis/streamladder-api/twitch-clips/twitch-clips'
import { computed, ref, watch } from 'vue'
import { useTwitchClipsFilter, type TwitchClipFilterValues } from '@/Hooks/useTwitchClipsFilter'
import { toValue } from '@vueuse/core'
import type { MaybeRefOrGetter } from '@vueuse/shared'
import { useUserInfoStore } from '@/store/user/userInfo'

export function useTwitchClips(options: UseTwitchClipsConfig = {}) {

  const pageNumber = ref(1)

  const userInfo = useUserInfoStore()

  const enabled = computed(() => toValue(options.enabled) ?? true)
  const userName = computed(() => toValue(options.userName) ?? userInfo.userName)
  const clipsPerPage = computed(() => toValue(options.clipsPerPage) ?? 12)

  const { twitchClipFilterKey, twitchClipsOptions } = useTwitchClipsFilter()
  const filter = computed(() => {
    return twitchClipsOptions.find((o) => {
      return o.value === (toValue(options.filter) ?? twitchClipFilterKey.value)
    })!
  })

  const params = computed(() => ({
    streamerName: userName.value,
    sinceDays: filter.value.sinceDays,
  }))

  watch(filter, () => pageNumber.value = 1)

  const {
    isError,
    isLoading,
    data,
    isSuccess,
  } = useGetApiTwitchClipsStreamer(params, { query: { enabled } })

  const clips = computed(() => {
    const clips = (data.value ?? []) as Clip[]
    if (filter.value.sort === 'views') {
      return orderByViewCount(clips)
    } else if (filter.value.sort === 'recency') {
      return orderByRecency(clips)
    }
    return clips
  })

  const clipsOnPage = computed(() => {
    return clips.value.slice((pageNumber.value - 1) * clipsPerPage.value, pageNumber.value * clipsPerPage.value)
  })

  const totalAmountOfClips = computed(() => clips.value.length)

  const totalPages = computed(() => {
    return Math.ceil(totalAmountOfClips.value / clipsPerPage.value)
  })

  const hasClips = computed(() => totalAmountOfClips.value > 0 || isLoading.value)

  const clipsToShow = computed(() => {
    return isLoading.value ? clipsPerPage.value : clipsOnPage.value.length
  })

  return {
    isSuccess,
    isError,
    isLoading,
    totalPages,
    pageNumber,
    clips,
    clipsOnPage,
    clipsPerPage,
    clipsToShow,
    totalAmountOfClips,
    hasClips,
  }
}

function orderByRecency(clips: Clip[]) {
  return [...clips].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  })
}

function orderByViewCount(clips: Clip[]) {
  return [...clips].sort((a, b) => {
    return b.viewCount - a.viewCount
  })
}

export type Clip = {
  id: string
  title: string
  thumbnailUrl: string
  mp4Url: string
  viewCount: number
  createdAt: string
  dateFromNow: string
}

export type UseTwitchClipsConfig = {
  clipsPerPage?: MaybeRefOrGetter<number>
  enabled?: MaybeRefOrGetter<boolean>
  userName?: MaybeRefOrGetter<string | undefined>
  filter?: MaybeRefOrGetter<TwitchClipFilterValues>
}
