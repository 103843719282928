/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder Publisher Api
 * OpenAPI spec version: v1
 */

export type SocialMedia = (typeof SocialMedia)[keyof typeof SocialMedia]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocialMedia = {
  Tiktok: 'Tiktok',
  Instagram: 'Instagram',
  Youtube: 'Youtube',
} as const
