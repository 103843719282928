<script lang="ts" setup>
import { useGetApiVideos } from '@/apis/streamladder-api/videos/videos'
import { computed, ref } from 'vue'
import { range } from 'lodash-es'
import VideoPreview from '@/areas/dashboard/components/ResultVideos/ResultVideoPreview.vue'
import GetQrCode from '@/components/ResultPage/GetQrCode.vue'
import { type VideoResultDto } from '@/apis/streamladder-api/model'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import { useFilterVideos, videosFilter } from '@/Hooks/useFilterVideos'
import IconSaxVideo from '@/components/Icons/iconsax/IconSaxVideo.vue'
import { useGuard, canGuard } from '@/Hooks/useGuard'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import { useIsIntersecting } from '@/areas/dashboard/hooks/useIsIntersecting'
import logging from '@/logging'

const props = defineProps<{ singleRow?: boolean }>()

const container = ref<HTMLDivElement>()
const { data, isLoading: isLoadingVideos, dataUpdatedAt } = useGetApiVideos({
  query: { enabled: useIsIntersecting(container) },
})

const videos = computed(() => (data.value ?? []) as unknown as VideoResultDto[])

const { filteredVideos, isLoadingPosts } = useFilterVideos(videos)

const modal = ref<GetQrCode>()

function openModal(video: VideoResultDto) {
  if (!canGuard('qr-code-download')) {
    upgradeDialog.open('qr-code-download')
  } else {
    modal.value.publish(video.resultUrl)
    logging.trackEvent('ResultVideo QrCode Generated', {
      Location: 'Dashboard My Clips Page',
      Destination: 'QrCode',
      IsPublic: false,
    })
  }
}

const previews = ref<VideoPreview[]>()
const previewVideos = computed<HTMLVideoElement[]>(() => {
  return (previews.value ?? []).map((p) => p.video).filter(Boolean)
})

function pauseOthers(id: string | null | undefined) {
  for (const video of previewVideos.value) {
    if (video.id !== id) {
      video.pause()
    }
  }
}

const isLoading = computed(() => isLoadingVideos.value || isLoadingPosts.value)
const amountOfVideos = computed(() => {
  if (isLoading.value) {
    return 4
  } else if (props.singleRow) {
    return Math.min(4, filteredVideos.value.length)
  } else {
    return filteredVideos.value.length
  }
})

const canSaveVideos = useGuard('save-video')
</script>

<template>
  <div ref="container">
    <section v-if="!canSaveVideos">
      <ol class="single-row grid grid-cols-2 gap-4 lg:grid-cols-3 2xl:grid-cols-4">
        <li class="block">
          <VideoPreview placeholder-src="/images/posters/placeholder-1.jpg" />
        </li>
        <li class="block">
          <VideoPreview placeholder-src="/images/posters/placeholder-2.jpg" />
        </li>
        <li class="block">
          <VideoPreview placeholder-src="/images/posters/placeholder-3.jpg" />
        </li>
        <li class="block">
          <VideoPreview placeholder-src="/images/posters/placeholder-4.jpg" />
        </li>
      </ol>
    </section>

    <section v-else-if="amountOfVideos">
      <TransitionGroup
        :key="videosFilter + dataUpdatedAt"
        :class="{ 'grid-single-row': singleRow || isLoading }"
        appear
        class="grid grid-cols-2 gap-4 lg:grid-cols-3 2xl:grid-cols-4"
        name="grid"
        tag="ol"
      >
        <li v-for="i in range(amountOfVideos)" :key="i" class="block">
          <VideoPreview ref="previews" :video="filteredVideos[i]" @play="pauseOthers" @requestQr="openModal" />
        </li>
      </TransitionGroup>

      <div v-if="singleRow && !isLoading && amountOfVideos > 4" class="mt-4 flex flex-row justify-center gap-4">
        <RouterLink
          :to="{ name: dashboardRouteNames.videos }"
          class="mt-4 flex items-center gap-2 rounded-xl border border-current px-3 py-1.5 font-semibold transition-all hover:border-transparent hover:bg-zinc-800 hover:text-zinc-100 active:scale-90 active:bg-zinc-600/25 dark:hover:bg-zinc-200 dark:hover:text-zinc-800"
        >
          <IconSaxVideo />
          View all clips
        </RouterLink>
      </div>
    </section>

    <NoContentSection v-else subtitle="Try adjusting the filter or create a video in minutes" title="No recent clips">
      <RouterLink :to="{ name: dashboardRouteNames.editor }" class="shadow-lg">
        <GradientButton>
          Create video
          <IconSaxAdd />
        </GradientButton>
      </RouterLink>
    </NoContentSection>
    <GetQrCode ref="modal" />
  </div>
</template>

<style lang="scss"></style>
