<script lang="ts" setup>
import ResultVideosList from '@/areas/dashboard/components/ResultVideos/ResultVideosList.vue'
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import { videosFilter, videosFilterOptions } from '@/Hooks/useFilterVideos'
import { useHead } from '@unhead/vue'
import WithUpgradeOverlay from '@/areas/dashboard/components/WithUpgradeOverlay.vue'

useHead({
  title: 'My Videos',
  meta: [
    {
      name: 'description',
      content: 'Check out the clips you created in the last 7 days.',
    },
  ],
})
</script>

<template>
  <WithUpgradeOverlay
    feature="save-video"
    subtitle="Save your clips to your device and upload them to your social media accounts."
    title="Save your clips"
  >
    <main class="flex flex-col gap-4 p-4 lg:p-12">
      <div class="flex flex-row flex-wrap items-end justify-between gap-2">
        <header class="flex flex-col">
          <h1 class="text-3xl lg:text-5xl">
            My Videos
          </h1>
          <p class="font-light text-opacity-50">Below are the videos you created in the last 7 days.</p>
        </header>

        <label class="flex items-center gap-2">
          <SelectDropdown v-model="videosFilter" :options="videosFilterOptions" class="ml-auto" />
        </label>
      </div>

      <ResultVideosList />
    </main>
  </WithUpgradeOverlay>
</template>

<style lang="scss" scoped></style>
