import {streamLadderAxios} from './axios'
import {useUserInfoStore} from '@/store/user/userInfo'
import {useUserTemplatesStore} from '@/store/user/userTemplates'
import {layoutKeys} from '@/data/layoutData'

export default {
    async getTemplates() {
        const userInfo = useUserInfoStore()
        const templatesStore = useUserTemplatesStore()
        if (!userInfo.hasSubscription) {
            templatesStore.savedTemplates = []
            return
        }
        const res = await streamLadderAxios.get('/api/templates')
        if (res.status === 200) {
            // Old templates had 'freeform' as the ratio, which is now 'Free'
            templatesStore.savedTemplates = res.data.map((t) => {
                return {
                    ...t,
                    stickers: t.stickers?.map((s) => ({
                        ...upgradeSticker(s),
                        animationStyle: s.animationStyle == null ? undefined : s.animationStyle,
                        animationTime: s.animationTime == null ? undefined : s.animationTime,
                    })),
                    ratio: t.ratio?.replace('freeform', 'Free'),
                }
            })
                .filter((t) => layoutKeys.includes(t.layoutName))
        } else {
            throw new Error(`GetTemplates for user failed with code '${res.status}', message: ${res.data}`)
        }
    },
    async deleteTemplate(templateId) {
        const res = await streamLadderAxios.delete(`/api/templates/${templateId}`)
        if (res.status === 200) {
            await this.getTemplates()
        } else {
            throw new Error(`DeleteTemplate for user failed with code '${res.status}', message: ${res.data}`)
        }
    },
    async saveTemplate(template, update = false) {
        const command = update ? streamLadderAxios.put : streamLadderAxios.post
        const res = await command('/api/templates', template)
        if (res.status === 200) {
            await this.getTemplates()
            return res.data
        } else {
            throw new Error(`SaveTemplate for user failed with code '${res.status}', message: ${res.data}`)
        }
    },
}

const upgradeSticker = (sticker) => {
    return {
        ...upgradeTextToHtmlContent(sticker),
    }
}

const upgradeTextToHtmlContent = (sticker) => {
    if (sticker.text) {
        return {
            ...sticker,
            htmlContent: sticker.text,
            text: null,
        }
    } else {
        return sticker
    }
}
