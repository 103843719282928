/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/vue-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  QueryKey,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import type { VideoResultDto, VideoDtoApiResponse, CreateVideoDto } from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const getApiVideos = (options?: SecondParameter<typeof streamLadderAxiosInstance>, signal?: AbortSignal) => {
  return streamLadderAxiosInstance<VideoResultDto[]>({ url: `/api/Videos`, method: 'get', signal }, options)
}

export const getGetApiVideosQueryKey = () => ['api', 'Videos'] as const

export const getGetApiVideosQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiVideos>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiVideos>>, TError, TData>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getApiVideos>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiVideosQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiVideos>>> = ({ signal }) =>
    getApiVideos(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetApiVideosQueryResult = NonNullable<Awaited<ReturnType<typeof getApiVideos>>>
export type GetApiVideosQueryError = unknown

export const useGetApiVideos = <TData = Awaited<ReturnType<typeof getApiVideos>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiVideos>>, TError, TData>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiVideosQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const postApiVideos = (
  createVideoDto: MaybeRef<CreateVideoDto>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<VideoDtoApiResponse>(
    { url: `/api/Videos`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createVideoDto },
    options
  )
}

export const getPostApiVideosMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiVideos>>, TError, { data: CreateVideoDto }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiVideos>>, TError, { data: CreateVideoDto }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiVideos>>, { data: CreateVideoDto }> = (props) => {
    const { data } = props ?? {}

    return postApiVideos(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiVideosMutationResult = NonNullable<Awaited<ReturnType<typeof postApiVideos>>>
export type PostApiVideosMutationBody = CreateVideoDto
export type PostApiVideosMutationError = void

export const usePostApiVideos = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiVideos>>, TError, { data: CreateVideoDto }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getPostApiVideosMutationOptions(options)

  return useMutation(mutationOptions)
}
export const deleteApiVideosId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<void>({ url: `/api/Videos/${unref(id)}`, method: 'delete' }, options)
}

export const getDeleteApiVideosIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiVideosId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiVideosId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiVideosId>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return deleteApiVideosId(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiVideosIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiVideosId>>>

export type DeleteApiVideosIdMutationError = unknown

export const useDeleteApiVideosId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiVideosId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getDeleteApiVideosIdMutationOptions(options)

  return useMutation(mutationOptions)
}
