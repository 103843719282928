import { acceptHMRUpdate, defineStore } from 'pinia'
import type {
  CaptionOptions,
  CaptionsDocument,
  CaptionStyleDefinition,
  CaptionsWrapper,
  StoredCaption,
} from '@/components/Captions/captionTypes'

import { captionStylesSettings } from '@/components/Captions/styles/CaptionStyleManager'
import type { CaptionStyle } from '@/components/Captions/styles/captionStyles'
import {
  addEmojiToCaptions,
  addMetaDataToCaptions,
  groupCaptionWords,
  reduceGapBetweenCaptions,
} from '@/components/Captions/CaptionPreprocessors'
import { setBaseColor, setHighlightColor } from '@/components/Captions/CaptionPreRenderer'
import { computed, nextTick, reactive, ref, toRefs, watch } from 'vue'
import { useToast } from '@/Hooks/useToast'
import { useStorage } from '@vueuse/core'
import { v4 } from 'uuid'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import logging from '@/logging'

const defaultCaptionsWrapper: CaptionsWrapper = {
  x: 0.0545,
  y: 0.57731,
  scale: 0.00405,
}

const defaultCaptionOptions: Omit<CaptionOptions, 'baseColor' | 'highlightColor'> = {
  emojis: true,
  emojiLocation: 'bottom',
  rotate: true,
  highlight: true,
  grouping: 'single',
  size: 'medium',
}

type CaptionFeatures = 'highlight' | 'emojis'
const featuresPerLanguage = {
  en_us: ['highlight', 'emojis'],
}

export const useEditorCaptionsStore = defineStore('editorCaptions', () => {
  const captionsDocument = ref<CaptionsDocument | null>(null)
  const groupedCaptions = reactive<StoredCaption[]>([])

  const captionStyle = ref<CaptionStyle>('funky')
  const storedData = localStorage.getItem(`caption-options`)
  const baseOptions = reactive<CaptionOptions>(
    storedData ? { ...defaultCaptionOptions, ...JSON.parse(storedData) } : defaultCaptionOptions
  )
  const styleOptions = reactive({
    data: {
      baseColor: captionStylesSettings[captionStyle.value].colors[0],
      highlightColor: captionStylesSettings[captionStyle.value].highlightColor,
    },
  })

  watch(captionStyle, (newValue, oldValue) => {
    const storedData = localStorage.getItem(`caption-options-${newValue}`)
    const parsedData = storedData ? JSON.parse(storedData) : {}
    const storedObject = {
      ...{
        baseColor: captionStylesSettings[captionStyle.value].colors[0],
        highlightColor: captionStylesSettings[captionStyle.value].highlightColor,
      },
      ...parsedData,
    } as CaptionOptions
    styleOptions.data = storedObject
    // nextTick(() => {
    //   editorVideoStore.timeline.invalidate()
    // })
  })

  watch(
    () => styleOptions,
    (newValue, oldValue) => {
      localStorage.setItem(`caption-options-${captionStyle.value}`, JSON.stringify(newValue.data))
      // nextTick(() => {
      //   editorVideoStore.timeline.invalidate()
      // })
    },
    { deep: true }
  )

  watch(
    baseOptions,
    (newValue, oldValue) => {
      localStorage.setItem(`caption-options`, JSON.stringify(newValue))
      // nextTick(() => {
      //   editorVideoStore.timeline.invalidate()
      // })
    },
    { deep: true }
  )

  const selectedLanguage = useStorage<string>('caption-language', 'en_us')
  const captionsWrapper = ref<CaptionsWrapper>(defaultCaptionsWrapper)
  const setCaptionsDocument = (captions: CaptionsDocument) => {
    captionsDocument.value = captions
  }

  // change Captions Document or Grouping
  // recalculate
  watch([captionsDocument, () => baseOptions.grouping], async () => {
    if (!captionsDocument.value) return
    const grouped = groupCaptionWords(JSON.parse(JSON.stringify(captionsDocument.value)), baseOptions.grouping)
    reduceGapBetweenCaptions(grouped)
    addMetaDataToCaptions(grouped)

    setBaseColor(grouped, styleOptions.data.baseColor)
    groupedCaptions.splice(0, groupedCaptions.length, ...grouped)
    setBaseColor(groupedCaptions, styleOptions.data.baseColor)
    setHighlightColor(
      groupedCaptions,
      baseOptions.highlight && !captionStyleSettings.value.disableHighlight
        ? styleOptions.data.highlightColor
        : undefined
    )
    // wait for the elements to dismount and mount from the Dom and timeline.
    // then force a rerender of the timeline
    nextTick(() => {
      if (baseOptions.emojis) addEmojiToCaptions(groupedCaptions)
      // editorVideoStore.timeline.invalidate()
    })
  })

  const hasCaptions = computed(() => {
    return captionsDocument.value !== null
  })

  // change Base Color
  watch(
    () => styleOptions.data.baseColor,
    () => {
      setBaseColor(groupedCaptions, styleOptions.data.baseColor)
    }
  )

  const captionStyleSettings = computed(() => {
    const baseSettings = captionStylesSettings[captionStyle.value] as CaptionStyleDefinition
    const emojiSettings = baseSettings.effects?.emoji || {
      maxEmojis: 1,
      location: baseOptions.emojiLocation || 'bottom',
    }
    const rotateSettings = baseSettings.effects?.rotate || 10
    const textScale = baseOptions.size === 'large' ? 1 : baseOptions.size === 'medium' ? 0.7 : 0.5
    return {
      ...baseSettings,
      fontSize: {
        ...baseSettings.fontSize,
        scale: textScale,
      },
      effects: {
        emoji: emojiSettings,
        rotate: baseOptions.rotate ? rotateSettings : undefined,
      },
    }
  })

  watch(
    () => baseOptions.emojis,
    async () => {
      if (baseOptions.emojis) {
        await addEmojiToCaptions(groupedCaptions)
      } else {
        groupedCaptions.forEach((caption) => {
          caption.emojis = []
        })
      }
      // nextTick(() => {
      //   editorVideoStore.timeline.invalidate()
      // })
    }
  )

  // change Highlight Color
  watch(
    () => [baseOptions.highlight, styleOptions.data.highlightColor, captionStyleSettings.value.disableHighlight],
    () => {
      setHighlightColor(
        groupedCaptions,
        baseOptions.highlight && !captionStyleSettings.value.disableHighlight
          ? styleOptions.data.highlightColor
          : undefined
      )
    }
  )

  const addCaption = async (at: number) => {
    const captionAtPosition = groupedCaptions.findIndex((c) => c.start <= at && c.end >= at)
    const nextCaption = groupedCaptions.findIndex((c) => c.start > at)
    const defaultWidth = 1000
    const width =
      nextCaption >= 0
        ? groupedCaptions[nextCaption].start - at <= defaultWidth
          ? groupedCaptions[nextCaption].start - at
          : defaultWidth
        : defaultWidth
    const end = at + width

    const distanceToStartCaptionAtPosition = at - groupedCaptions[captionAtPosition]?.start
    if (distanceToStartCaptionAtPosition <= 200) {
      const { toast } = useToast()
      toast('Too close to the start of the caption', "Can't add caption", 'error')
      return
    }

    const newCaption = {
      start: at,
      end,
      id: v4(),
      text: '',
      confidence: 1,
      color: styleOptions.data.baseColor,
      randomizer: Math.random(),
      words: [
        {
          confidence: 1,
          start: at,
          end,
          text: '',
          Highlighted: false,
          speaker: 'A',
        },
      ],
    }

    const focusStore = useEditorFocusStore()
    focusStore.setFocus('caption', newCaption.id)
    logging.trackEvent('Caption added', {})
    if (captionAtPosition >= 0) {
      groupedCaptions[captionAtPosition].end = at
      groupedCaptions.splice(captionAtPosition + 1, 0, newCaption)
      return
    }
    if (nextCaption >= 0) {
      groupedCaptions.splice(nextCaption, 0, newCaption)
      return
    }
    groupedCaptions.push(newCaption)
  }

  const updateCaption = (id: string, caption: Partial<StoredCaption>) => {
    const index = groupedCaptions.findIndex((c) => c.id === id)
    if (index > -1) groupedCaptions.splice(index, 1, { ...groupedCaptions[index], ...caption })
  }

  const deleteCaption = (id: string) => {
    if (groupedCaptions.length === 1) return
    const index = groupedCaptions.findIndex((c) => c.id === id)
    if (index > -1) groupedCaptions.splice(index, 1)
  }

  const hasFeature = (feature: CaptionFeatures) => {
    return computed(() => featuresPerLanguage[selectedLanguage.value]?.includes(feature)) || false
  }

  const resetPositionScale = () => {
    // Initial position of the Captions
    captionsWrapper.value = defaultCaptionsWrapper
  }

  const captions = computed(() => {
    // nextTick(() => {
    //   editorVideoStore.timeline.invalidate()
    // })
    return groupedCaptions
  })

  const captionsGenerated = computed(() => {
    return !!captionsDocument.value
  })

  function $reset() {
    captionsDocument.value = null
    groupedCaptions.splice(0, groupedCaptions.length)
    captionsWrapper.value = defaultCaptionsWrapper
    captionStyle.value = 'lit'
  }

  const getLoggingData = () => {
    return {
      CaptionCount: captions.value.length ?? 0,
      CaptionStyle: captionStyle.value,
      CaptionLanguage: selectedLanguage.value,
      CaptionDisplayMethod: baseOptions.grouping,
    }
  }

  return {
    captionsDocument,
    addCaption,
    captions,
    captionStyleSettings,
    captionsWrapper,
    groupedCaptions,
    hasCaptions,
    baseOptions,
    styleOptions,
    selectedLanguage,
    setCaptionsDocument,
    resetPositionScale,
    deleteCaption,
    updateCaption,
    captionsGenerated,
    captionStyle,
    hasFeature,
    getLoggingData,
    $reset,
  }
})

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useEditorCaptionsStore, import.meta.hot))
}
