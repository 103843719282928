<script setup lang="ts">
import { useConditionalEventListener } from '@/Hooks/useConditionalEventListener'
import { toRef } from 'vue'

const props = withDefaults(defineProps<{ arrow: boolean; modelValue: boolean }>(), { arrow: false })
const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void
}>()

useConditionalEventListener(toRef(props, 'modelValue'), 'keydown', (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    emit('update:modelValue', false)
  }
})
</script>

<template>
  <div class="relative" v-click-outside="() => emit('update:modelValue', false)">
    <slot
      name="button"
      type="button"
      @click="emit('update:modelValue', !modelValue)"
      class="cursor-pointer"
      :class="{ 'overflow-visible': modelValue }"
    />

    <div
      class="absolute bottom-full w-full drop-shadow transition-all dark:drop-shadow-[0_2px_5px_theme(colors.zinc.900/10)] lg:bottom-0 lg:left-full lg:w-auto"
      :class="
        modelValue
          ? '-translate-y-2 lg:translate-x-4 lg:translate-y-0'
          : 'pointer-events-none invisible translate-y-0 opacity-0 lg:translate-x-6 lg:translate-y-0'
      "
    >
      <div
        v-if="arrow"
        class="layer-1 absolute left-1/2 h-6 w-6 origin-bottom-left -translate-x-1/2 -translate-y-2 -rotate-45 transform select-none rounded lg:bottom-1 lg:left-2 lg:translate-x-0 lg:translate-y-0"
      />
      <slot
        name="menu"
        class="max-w-screen layer-1 absolute bottom-full z-50 max-h-screen w-full flex-col gap-2 rounded-xl p-2 lg:bottom-0 lg:w-max"
      />
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
