import EventBus from '@/eventBus'
import upgradeEvents from '@/events/upgradeEvents'
import features, { type Feature } from '@/data/features'

export const upgradeDialog = {
  open: open,
  close() {
    EventBus.$emit(upgradeEvents.CLOSE_UPGRADE_DIALOG)
  },
}

function open(command?: { reason: string; feature?: string; title?: string; subtitle?: string }): void
function open(command?: Feature): void
function open(command?: Feature | { reason: string; feature?: string; title?: string; subtitle?: string }) {
  if (typeof command === 'string') {
    const featureName = command as Feature
    const feature = features.find((f) => f.name === featureName)

    const reason = `User has no access to feature: ${featureName}`
    EventBus.$emit(upgradeEvents.OPEN_UPGRADE_DIALOG, {
      reason: 'promo' in feature ? feature.promo.reason : reason,
      feature: featureName,
    })
  } else {
    EventBus.$emit(upgradeEvents.OPEN_UPGRADE_DIALOG, command)
  }
}
