/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder Publisher Api
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from '@tanstack/vue-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  QueryKey,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import type {
  PostDtoPage,
  GetPostsParams,
  PostDto,
  CreatePostDto,
  GetPostsSearchParams,
  UpdatePostDto,
  ReducerWebhookPayload,
  PublisherWebhookPayload,
} from '.././model'
import { publisherAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const getPosts = (
  params?: MaybeRef<GetPostsParams>,
  options?: SecondParameter<typeof publisherAxiosInstance>,
  signal?: AbortSignal
) => {
  return publisherAxiosInstance<PostDtoPage>({ url: `/posts`, method: 'get', params, signal }, options)
}

export const getGetPostsQueryKey = (params?: MaybeRef<GetPostsParams>) =>
  ['posts', ...(params ? [params] : [])] as const

export const getGetPostsQueryOptions = <TData = Awaited<ReturnType<typeof getPosts>>, TError = unknown>(
  params?: MaybeRef<GetPostsParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPosts>>, TError, TData>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getPosts>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetPostsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPosts>>> = ({ signal }) =>
    getPosts(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetPostsQueryResult = NonNullable<Awaited<ReturnType<typeof getPosts>>>
export type GetPostsQueryError = unknown

export const useGetPosts = <TData = Awaited<ReturnType<typeof getPosts>>, TError = unknown>(
  params?: MaybeRef<GetPostsParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPosts>>, TError, TData>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPostsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const postPosts = (
  createPostDto: MaybeRef<CreatePostDto>,
  options?: SecondParameter<typeof publisherAxiosInstance>
) => {
  return publisherAxiosInstance<PostDto>(
    { url: `/posts`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createPostDto },
    options
  )
}

export const getPostPostsMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postPosts>>, TError, { data: CreatePostDto }, TContext>
  request?: SecondParameter<typeof publisherAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postPosts>>, TError, { data: CreatePostDto }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPosts>>, { data: CreatePostDto }> = (props) => {
    const { data } = props ?? {}

    return postPosts(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostPostsMutationResult = NonNullable<Awaited<ReturnType<typeof postPosts>>>
export type PostPostsMutationBody = CreatePostDto
export type PostPostsMutationError = unknown

export const usePostPosts = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postPosts>>, TError, { data: CreatePostDto }, TContext>
  request?: SecondParameter<typeof publisherAxiosInstance>
}) => {
  const mutationOptions = getPostPostsMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getPostsSearch = (
  params?: MaybeRef<GetPostsSearchParams>,
  options?: SecondParameter<typeof publisherAxiosInstance>,
  signal?: AbortSignal
) => {
  return publisherAxiosInstance<PostDtoPage>({ url: `/posts/search`, method: 'get', params, signal }, options)
}

export const getGetPostsSearchQueryKey = (params?: MaybeRef<GetPostsSearchParams>) =>
  ['posts', 'search', ...(params ? [params] : [])] as const

export const getGetPostsSearchQueryOptions = <TData = Awaited<ReturnType<typeof getPostsSearch>>, TError = unknown>(
  params?: MaybeRef<GetPostsSearchParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPostsSearch>>, TError, TData>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getPostsSearch>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetPostsSearchQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPostsSearch>>> = ({ signal }) =>
    getPostsSearch(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetPostsSearchQueryResult = NonNullable<Awaited<ReturnType<typeof getPostsSearch>>>
export type GetPostsSearchQueryError = unknown

export const useGetPostsSearch = <TData = Awaited<ReturnType<typeof getPostsSearch>>, TError = unknown>(
  params?: MaybeRef<GetPostsSearchParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPostsSearch>>, TError, TData>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPostsSearchQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const getPostsId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof publisherAxiosInstance>,
  signal?: AbortSignal
) => {
  return publisherAxiosInstance<PostDto>({ url: `/posts/${unref(id)}`, method: 'get', signal }, options)
}

export const getGetPostsIdQueryKey = (id: MaybeRef<string>) => ['posts', id] as const

export const getGetPostsIdQueryOptions = <TData = Awaited<ReturnType<typeof getPostsId>>, TError = unknown>(
  id: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPostsId>>, TError, TData>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getPostsId>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetPostsIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPostsId>>> = ({ signal }) =>
    getPostsId(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, staleTime: 60000, ...queryOptions }
}

export type GetPostsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPostsId>>>
export type GetPostsIdQueryError = unknown

export const useGetPostsId = <TData = Awaited<ReturnType<typeof getPostsId>>, TError = unknown>(
  id: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPostsId>>, TError, TData>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPostsIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const putPostsId = (
  id: MaybeRef<string>,
  updatePostDto: MaybeRef<UpdatePostDto>,
  options?: SecondParameter<typeof publisherAxiosInstance>
) => {
  return publisherAxiosInstance<PostDto>(
    { url: `/posts/${unref(id)}`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: updatePostDto },
    options
  )
}

export const getPutPostsIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPostsId>>,
    TError,
    { id: string; data: UpdatePostDto },
    TContext
  >
  request?: SecondParameter<typeof publisherAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPostsId>>,
  TError,
  { id: string; data: UpdatePostDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putPostsId>>, { id: string; data: UpdatePostDto }> = (
    props
  ) => {
    const { id, data } = props ?? {}

    return putPostsId(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutPostsIdMutationResult = NonNullable<Awaited<ReturnType<typeof putPostsId>>>
export type PutPostsIdMutationBody = UpdatePostDto
export type PutPostsIdMutationError = unknown

export const usePutPostsId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPostsId>>,
    TError,
    { id: string; data: UpdatePostDto },
    TContext
  >
  request?: SecondParameter<typeof publisherAxiosInstance>
}) => {
  const mutationOptions = getPutPostsIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const deletePostsId = (id: MaybeRef<string>, options?: SecondParameter<typeof publisherAxiosInstance>) => {
  return publisherAxiosInstance<void>({ url: `/posts/${unref(id)}`, method: 'delete' }, options)
}

export const getDeletePostsIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deletePostsId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof publisherAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof deletePostsId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePostsId>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return deletePostsId(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeletePostsIdMutationResult = NonNullable<Awaited<ReturnType<typeof deletePostsId>>>

export type DeletePostsIdMutationError = unknown

export const useDeletePostsId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deletePostsId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof publisherAxiosInstance>
}) => {
  const mutationOptions = getDeletePostsIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getPostsRetry = (options?: SecondParameter<typeof publisherAxiosInstance>, signal?: AbortSignal) => {
  return publisherAxiosInstance<void>({ url: `/posts/retry`, method: 'get', signal }, options)
}

export const getGetPostsRetryQueryKey = () => ['posts', 'retry'] as const

export const getGetPostsRetryQueryOptions = <
  TData = Awaited<ReturnType<typeof getPostsRetry>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPostsRetry>>, TError, TData>
  request?: SecondParameter<typeof publisherAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getPostsRetry>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetPostsRetryQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPostsRetry>>> = ({ signal }) =>
    getPostsRetry(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetPostsRetryQueryResult = NonNullable<Awaited<ReturnType<typeof getPostsRetry>>>
export type GetPostsRetryQueryError = unknown

export const useGetPostsRetry = <TData = Awaited<ReturnType<typeof getPostsRetry>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPostsRetry>>, TError, TData>
  request?: SecondParameter<typeof publisherAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPostsRetryQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const postWebhooksSizereducer = (
  reducerWebhookPayload: MaybeRef<ReducerWebhookPayload>,
  options?: SecondParameter<typeof publisherAxiosInstance>
) => {
  return publisherAxiosInstance<void>(
    {
      url: `/webhooks/sizereducer`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: reducerWebhookPayload,
    },
    options
  )
}

export const getPostWebhooksSizereducerMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWebhooksSizereducer>>,
    TError,
    { data: ReducerWebhookPayload },
    TContext
  >
  request?: SecondParameter<typeof publisherAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postWebhooksSizereducer>>,
  TError,
  { data: ReducerWebhookPayload },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postWebhooksSizereducer>>,
    { data: ReducerWebhookPayload }
  > = (props) => {
    const { data } = props ?? {}

    return postWebhooksSizereducer(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostWebhooksSizereducerMutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksSizereducer>>>
export type PostWebhooksSizereducerMutationBody = ReducerWebhookPayload
export type PostWebhooksSizereducerMutationError = unknown

export const usePostWebhooksSizereducer = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWebhooksSizereducer>>,
    TError,
    { data: ReducerWebhookPayload },
    TContext
  >
  request?: SecondParameter<typeof publisherAxiosInstance>
}) => {
  const mutationOptions = getPostWebhooksSizereducerMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postWebhooksVideopublisher = (
  publisherWebhookPayload: MaybeRef<PublisherWebhookPayload>,
  options?: SecondParameter<typeof publisherAxiosInstance>
) => {
  return publisherAxiosInstance<void>(
    {
      url: `/webhooks/videopublisher`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: publisherWebhookPayload,
    },
    options
  )
}

export const getPostWebhooksVideopublisherMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWebhooksVideopublisher>>,
    TError,
    { data: PublisherWebhookPayload },
    TContext
  >
  request?: SecondParameter<typeof publisherAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postWebhooksVideopublisher>>,
  TError,
  { data: PublisherWebhookPayload },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postWebhooksVideopublisher>>,
    { data: PublisherWebhookPayload }
  > = (props) => {
    const { data } = props ?? {}

    return postWebhooksVideopublisher(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostWebhooksVideopublisherMutationResult = NonNullable<
  Awaited<ReturnType<typeof postWebhooksVideopublisher>>
>
export type PostWebhooksVideopublisherMutationBody = PublisherWebhookPayload
export type PostWebhooksVideopublisherMutationError = unknown

export const usePostWebhooksVideopublisher = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWebhooksVideopublisher>>,
    TError,
    { data: PublisherWebhookPayload },
    TContext
  >
  request?: SecondParameter<typeof publisherAxiosInstance>
}) => {
  const mutationOptions = getPostWebhooksVideopublisherMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postPostsWebhooksTiktok = (options?: SecondParameter<typeof publisherAxiosInstance>) => {
  return publisherAxiosInstance<void>({ url: `/posts/webhooks/tiktok`, method: 'post' }, options)
}

export const getPostPostsWebhooksTiktokMutationOptions = <
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postPostsWebhooksTiktok>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof publisherAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postPostsWebhooksTiktok>>, TError, TVariables, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPostsWebhooksTiktok>>, TVariables> = () => {
    return postPostsWebhooksTiktok(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostPostsWebhooksTiktokMutationResult = NonNullable<Awaited<ReturnType<typeof postPostsWebhooksTiktok>>>

export type PostPostsWebhooksTiktokMutationError = unknown

export const usePostPostsWebhooksTiktok = <TError = unknown, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postPostsWebhooksTiktok>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof publisherAxiosInstance>
}) => {
  const mutationOptions = getPostPostsWebhooksTiktokMutationOptions(options)

  return useMutation(mutationOptions)
}
