<script setup lang="ts">
import type { Feature } from "@/data/features";
import GradientButton from "@/components-v2/data-display/GradientButton.vue";
import { upgradeDialog } from "@/helpers/upgradeDialog";
import DynamicPlanButtonWithTooltip from "@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue";
import IconSaxArrowRight from "@/components/Icons/iconsax/IconSaxArrowRight.vue";
import { useGuard } from "@/Hooks/useGuard";

const props = defineProps<{
  feature: Feature
  title: string
  subtitle: string
}>()

const hasUnlockedFeature = useGuard(props.feature)
</script>

<template>
  <div class="relative p-4" v-if="!hasUnlockedFeature">
    <slot />

    <section class="absolute inset-0 backdrop-blur-sm flex flex-col justify-center items-center z-[1]">
      <div
        class="group flex flex-col items-center justify-center gap-4 rounded-2xl layer-1 py-12 px-16 shadow-lg cursor-pointer"
        @click="upgradeDialog.open(feature)"
      >
        <h2 class="text-2xl font-semibold">{{ title }}</h2>
        <p class="font-light max-w-[50ch] opacity-75 text-center">{{ subtitle }}</p>
        <GradientButton class="group-hover:shadow-gradient">
          <DynamicPlanButtonWithTooltip :feature="feature" />
          Upgrade now
          <IconSaxArrowRight class="transition-transform will-change-transform group-hover:translate-x-2" />
        </GradientButton>
      </div>
    </section>
  </div>
  <slot v-else />
</template>

<style scoped lang="scss">

</style>
