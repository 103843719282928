<script lang="ts" setup>
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import EarlyAccessFeaturesButton from '@/components/Buttons/EarlyAccessFeaturesButton.vue'

const routes = dashboardRouteNames.account

const linkClass = [
  'flex items-center justify-center gap-2',
  'px-4 py-2 rounded-lg',
  'hover:bg-indigo-500/10',
  'text-center font-light whitespace-nowrap',
  'transition-all hover:text-indigo-500',
]

const linkActiveClass = '!bg-indigo-500 !text-zinc-100 pointer-events-none'
</script>

<template>
  <main class="flex flex-col gap-6 p-4 lg:gap-12 lg:p-12">
    <nav class="flex flex-row overflow-x-auto">
      <ul class="flex flex-nowrap gap-2">
        <RouterLink :class="linkClass" :exact-active-class="linkActiveClass" :to="{ name: routes.settings }">
          Account Settings
        </RouterLink>
        <RouterLink :class="linkClass" :exact-active-class="linkActiveClass" :to="{ name: routes.invoicing }">
          Plans & Billing
        </RouterLink>
        <RouterLink :class="linkClass" :exact-active-class="linkActiveClass" :to="{ name: routes.templates }">
          My Templates
        </RouterLink>
      </ul>
      <span class="ml-2 rounded-lg bg-company-primary-50 transition-all hover:bg-company-primary-200">
        <EarlyAccessFeaturesButton />
      </span>
    </nav>

    <RouterView />
  </main>
</template>

<style lang="scss" scoped></style>
