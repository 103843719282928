type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type ScaleSet = {
  x: number
  y: number
  w: number
  h: number
  ratio: number
}

export const feedTypes = {
  FaceCam: 'facecam',
  GameFeed: 'gamefeed',
  GameUi: 'gameui',
  Free: 'free',
  Custom: 'custom'
} as const
export type FeedType = (typeof feedTypes)[keyof typeof feedTypes]

export type FeedOptions = {
  resizable?: boolean
  draggable?: boolean
  snapHorizontal?: boolean
  snapVertical?: boolean
  type: FeedType
}

export type CropOptions = {
  isRound?: boolean
  fixedRatio?: boolean
  aspectRatio?: number
  initialCropSize: number
  minimalRatio?: number
  showSnapping?: boolean
}

export type FragmentEditorOptions = {
  feedOptions: Required<FeedOptions>
  cropOptions: Required<CropOptions>
}

export type EditorFragment = FragmentEditorOptions &
  PartialBy<VideoFragment, 'sourceStartMs' | 'cropData' | 'feedData' | 'sourceEndMs'>

// export const SimpleVideoEffects = {
//   Rounded: 'rounded',
//   Blur: 'blur',
//
// } as const

type BorderEffect = {
  type: 'border'
  color: string
  width: number
}

type BlurEffect = {
  type: 'blur'
  strength: number
  quality: number
}

type roundEffect = {
  type: 'rounded'
}

export type VideoEffect = BorderEffect | BlurEffect | roundEffect

export type VideoFragment = {
  key: string
  source: HTMLVideoElement | HTMLImageElement | string
  sourceStartMs: number
  sourceEndMs: number
  zIndex: number
  effect?: VideoEffect[]
  cropData: ScaleSet
  feedData: ScaleSet
}

export interface Segment {
  /** @format double */
  startMs?: number
  /** @format double */
  endMs?: number
  layout?: string
  cropData?: Crop[] | null
}

export interface Zone {
  /** @format double */
  x: number
  /** @format double */
  y: number
  /** @format double */
  width: number
  /** @format double */
  height: number
}

export interface Crop {
  /** @format double */
  x?: number
  /** @format double */
  y?: number
  /** @format double */
  width?: number
  /** @format double */
  height?: number
  feedData?: FeedData
}

export interface FeedData {
  /** @format double */
  x?: number
  /** @format double */
  y?: number
  /** @format double */
  width?: number
  /** @format double */
  height?: number
  effects?: VideoEffect[] | null
}
