import { useRouter } from 'vue-router'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'

export const useExternalApiErrorCodes = () => {
  const router = useRouter()
  const referrer = router.currentRoute.value.name
  const supportUrl = router.resolve({ name: dashboardRouteNames.support, query: { referrer } }).href

  const tiktokApiErrorMessages = {
    spam_risk_too_many_posts: {
      title: 'Daily draft limit reached',
      description: 'TikTok allows only 5 clips to be sent as drafts each day.',
      link: {
        text: 'For more details, check our User Guide.',
        href: 'https://streamladder.com/user-guide/tiktok-draft-limit-reached',
      },
    },
    spam_risk_user_banned_from_posting: {
      title: 'Posting restricted',
      description:
        "Your posting privileges are temporarily restricted. Please review TikTok's community guidelines to ensure compliance.",
      link: {
        text: 'Check out TikTok support for more information.',
        href: 'https://support.tiktok.com/',
      },
    },
    access_token_invalid: {
      title: 'Reconnect your TikTok account',
      description: 'Your login session has expired. Please reconnect your TikTok account and try again.',
      link: {
        text: 'Reconnect TikTok account',
        href: router.resolve({ name: 'AccountSettings' }).href,
      },
    },
    scope_not_authorized: {
      title: 'Reconnect your TikTok account',
      description: "Reconnect your TikTok account and ensure you've granted permission for video publishing.",
      link: {
        text: 'Reconnect TikTok account',
        href: router.resolve({ name: 'AccountSettings' }).href,
      },
    },
    rate_limit_exceeded: {
      title: 'Posting speed limit reached',
      description: "You're posting too fast. Please wait a while and post again.",
    },
    privacy_level_option_mismatch: {
      title: 'Privacy settings error',
      description:
        "Your chosen posting or scheduling options don't match your TikTok settings. Please review your TikTok settings inside the app.",
      link: {
        text: 'Contact support',
        href: supportUrl,
      },
    },
    token_expired: {
      title: 'Reconnect your account',
      description: 'Access to your account has expired. Please reconnect your account and try again.',
      link: {
        text: 'Reconnect your account',
        href: router.resolve({ name: 'AccountSettings' }).href,
      },
    },
    insufficient_scopes: {
      title: 'Reconnect your account',
      description: "Reconnect your account and ensure you've granted permission for video publishing.",
      link: {
        text: 'Reconnect your account',
        href: router.resolve({name: 'AccountSettings'}).href,
      },
    },
  } satisfies Record<string, ApiErrorMessage>

  return (errorCode: string | null | undefined): ApiErrorMessage => {
    const message = errorCode ? tiktokApiErrorMessages[errorCode as keyof typeof tiktokApiErrorMessages] : null
    return (
      message ?? {
        title: 'Unknown error',
        description: 'An unknown error occurred.',
        link: {
          text: 'Please contact support.',
          href: supportUrl,
        },
      }
    )
  }
}

type ApiErrorMessage = {
  title: string
  description: string
  link?: {
    text: string
    href: string
  }
}
