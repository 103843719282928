<script setup lang="ts">
import IconSaxCloseSquare from '@/components/Icons/iconsax/IconSaxCloseSquare.vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'
import useCreateSocialConnection from '@/Hooks/useCreateSocialConnection'
import type { ConnectionType } from '@/enums/connectionTypes'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import { toRef, ref } from 'vue'
import { useConditionalEventListener } from '@/Hooks/useConditionalEventListener'
import { canGuard } from '@/Hooks/useGuard'
import { useUserInfoStore } from '@/store/user/userInfo'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import AlertBox from '@/components-v2/data-display/AlertBox.vue'

const props = defineProps<{ modelValue: boolean }>()
const emit = defineEmits<{ (name: 'update:modelValue', value: boolean): void }>()

function close() {
  emit('update:modelValue', false)
}

const userInfoStore = useUserInfoStore()

const connectionError = ref<string | null>(null)
const { connect } = useCreateSocialConnection()
function createConnection(type: ConnectionType) {
  connectionError.value = null
  const accountsOfType = userInfoStore.connections[type].length
  if (accountsOfType >= 1 && !canGuard('multiple-publishing-accounts')) {
    upgradeDialog.open('multiple-publishing-accounts')
  } else {
    connect(type).then(close).catch((error) => {
      connectionError.value = error
    })
  }
}

useConditionalEventListener(toRef(props, 'modelValue'), 'keydown', (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    close()
  }
})
</script>

<template>
  <teleport to="body">
    <section class="modal modal-bottom z-[1] md:modal-middle" :class="{ 'modal-open': modelValue }" @click="close">
      <article class="layer-1 modal-box flex !max-w-screen-md flex-col gap-8" @click.stop>
        <div class="flex flex-row items-start justify-between gap-4 text-center">
          <div class="w-10 shrink-0" />

          <header class="flex flex-col gap-1">
            <h3 class="m-0 p-0 text-2xl md:text-3xl">Connect a new social media account</h3>
            <p class="text-sm font-light text-opacity-50">
              Please choose to which platform you want to start publishing content
            </p>
          </header>


          <button
            @click="close"
            class="rounded-xl bg-transparent p-2 transition-all hover:bg-zinc-500/50 active:scale-90"
          >
            <IconSaxCloseSquare />
          </button>
        </div>

        <AlertBox v-if="connectionError" use-icon variant="error" title="Connection failed" :message="connectionError" />

        <nav class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <button
            @click="createConnection('youtube')"
            class="layer-2 group flex aspect-square flex-auto flex-col items-center justify-center gap-6 rounded-lg transition-all hover:scale-[1.015] hover:shadow-lg active:scale-90 active:shadow-none"
          >
            <span class="relative -mr-3 flex h-16 w-16 cursor-pointer text-zinc-400 dark:text-zinc-200">
              <span class="grid w-full place-items-center rounded-full border border-current transition-all">
                <YoutubeIcon class="h-8 w-8" />
              </span>
              <span
                class="absolute -right-3 bottom-0 grid h-8 w-8 place-items-center rounded-full bg-purple-500 transition-all"
              >
                <IconSaxAdd class="h-4 w-4 text-white transition-all" />
              </span>
            </span>
            <GradientButton class="group-hover:shadow-gradient">
              Connect YouTube
              <IconSaxAdd />
            </GradientButton>
          </button>

          <button
            @click="createConnection('tiktok')"
            class="layer-2 group flex aspect-square flex-auto flex-col items-center justify-center gap-6 rounded-lg transition-all hover:scale-[1.015] hover:shadow-lg active:scale-90 active:shadow-none"
          >
            <span class="relative -mr-3 flex h-16 w-16 cursor-pointer text-zinc-400 dark:text-zinc-200">
              <span class="grid w-full place-items-center rounded-full border border-current transition-all">
                <TikTokIcon class="h-8 w-8" />
              </span>
              <span
                class="absolute -right-3 bottom-0 grid h-8 w-8 place-items-center rounded-full bg-purple-500 transition-all"
              >
                <IconSaxAdd class="h-4 w-4 text-white transition-all" />
              </span>
            </span>
            <GradientButton class="group-hover:shadow-gradient">
              Connect TikTok
              <IconSaxAdd />
            </GradientButton>
          </button>
        </nav>
      </article>
    </section>
  </teleport>
</template>

<style scoped lang="scss"></style>
