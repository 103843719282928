import { defineStore } from 'pinia'
import { useEntityStore } from './useEntityStore'
import type { Shape } from '@/modules/CustomLayouts/@data/shapes'
import { computed, readonly, reactive, watch } from 'vue'
import type { VideoFragment, EditorFragment } from '@/modules/SLVideoplayer/types'
import type { Size, Area } from '@/modules/SLMovable/@types/Movable'
import { contain } from '@/modules/SLMovable/helpers/fit'
import { v4 as uuid } from 'uuid'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { clamp } from 'lodash-es'

export function useCropsStoreSetup(initialState: Record<string, Crop> = {}) {

  const { state, ids, entities, operations } = useEntityStore(initialState)

  watch(() => entities.value.length, () => {
    const sorted = entities.value.sort((a, b) => a.z - b.z)
    for (let i = 0; i < sorted.length; i++) {
      state[sorted[i].id].z = i + 1
    }
  })

  return reactive({
    state: readonly(state),
    ids: ids,
    entities: entities,
    ...operations,

    whereLayoutIdIs(layoutId: string) {
      return this.where((crop) => crop.layoutId === layoutId)
    },

    sortedByZ() {
      return computed(() => [...entities.value].sort((a, b) => a.z - b.z))
    },

    idsWhereLayoutIdIs(layoutId: string) {
      return computed(() => {
        return entities.value
          .filter((crop) => crop.layoutId === layoutId)
          .map((crop) => crop.id)
      })
    },

    selectVideoFragmentById(id: string) {

      const editorVideoStore = useEditorVideoStore()
      const crop = this.selectById(id)

      return computed<VideoFragment | null>(() => {

        if (!editorVideoStore.videoElement) return null

        return {
          key: crop.value.id,
          zIndex: crop.value.z,
          source: editorVideoStore.videoElement,
          sourceStartMs: 0,
          sourceEndMs: editorVideoStore.videoElement.duration * 1000,
          effect: crop.value.input.shape === 'circle' ? [{ type: 'rounded' }] : [],
          cropData: {
            x: crop.value.x,
            y: crop.value.y,
            w: crop.value.width,
            h: crop.value.height,
            ratio: 1,
          },
          feedData: {
            x: crop.value.feedData.x,
            y: crop.value.feedData.y,
            w: crop.value.feedData.width,
            h: crop.value.feedData.height,
            ratio: 1,
          },
        }
      })
    },

    selectVideoFragmentsByLayoutId(layoutId: string) {
      const ids = this.idsWhereLayoutIdIs(layoutId)
      return computed<VideoFragment[]>(() => {
        return ids.value
          .map((id) => this.selectVideoFragmentById(id))
          .map((c) => c.value)
          .filter((c) => c !== null) as VideoFragment[]
      })
    },

    selectEditorFragmentById(id: string) {

      const crop = this.selectById(id)
      const videoFragment = this.selectVideoFragmentById(id)

      return computed<EditorFragment | null>(() => {
        if (!videoFragment.value) return null

        return {
          cropOptions: {
            fixedRatio: crop.value.input.shape !== 'freeform',
            aspectRatio: crop.value.width / crop.value.height,
            initialCropSize: crop.value.width,
            isRound: crop.value.input.shape === 'circle',
            showSnapping: true,
            minimalRatio: 0.1,
          },
          feedOptions: {
            resizable: true,
            draggable: true,
            snapHorizontal: true,
            snapVertical: true,
            type: 'custom',
          },
          ...videoFragment.value,
        }
      })
    },

    selectEditorFragmentsByLayoutId(layoutId: string) {
      const ids = this.idsWhereLayoutIdIs(layoutId)
      return computed<EditorFragment[]>(() => {
        return ids.value
          .map((id) => this.selectEditorFragmentById(id))
          .map((c) => c.value)
          .filter((c) => c !== null) as EditorFragment[]
      })
    },

    selectShapeById(id: string) {
      return computed({
        get: () => {
          return state[id].input.shape
        },
        set: (shape: Shape) => {
          state[id].input.shape = shape
        },
      })
    },

    selectLabelById(id: string) {
      return computed({
        get: () => {
          return state[id].input.name
        },
        set: (name: string) => {
          state[id].input.name = name
        },
      })
    },

    updateCropAreaById(id: string, area: { x?: number; y?: number; width?: number; height?: number }) {

      const currentCrop = state[id]

      state[id].x = area.x ?? currentCrop.x
      state[id].y = area.y ?? currentCrop.y

      state[id].width = area.width ?? currentCrop.width
      state[id].height = area.height ?? currentCrop.height
    },

    updateCropFeedDataById(
      id: string,
      feedData: { x?: number; y?: number; width?: number; height?: number }
    ) {

      const currentFeedData = state[id].feedData

      state[id].feedData.x = feedData.x ?? currentFeedData.x
      state[id].feedData.y = feedData.y ?? currentFeedData.y

      state[id].feedData.width = feedData.width ?? currentFeedData.width
      state[id].feedData.height = feedData.height ?? currentFeedData.height
    },

    duplicateCropById(id: string, cropDestination: Area | null = null, feedDestination: Area | null = null) {

      const crop = state[id]
      const layoutId = crop.layoutId
      const existingCrops = this.idsWhereLayoutIdIs(layoutId).value.length

      if (existingCrops >= 10) {
        return null
      }

      const randomDistance = Math.random() * 0.15

      function clampX(crop: Area) {
        if (crop.x + crop.width + randomDistance <= 1) {
          return crop.x + randomDistance
        } else if (crop.x - randomDistance >= 0) {
          return crop.x - randomDistance
        } else {
          return crop.x
        }
      }

      function clampY(crop: Area) {
        if (crop.y + crop.height + randomDistance <= 1) {
          return crop.y + randomDistance
        } else if (crop.y - randomDistance >= 0) {
          return crop.y - randomDistance
        } else {
          return crop.y
        }
      }

      cropDestination = cropDestination ?? {
        x: clampX(crop),
        y: clampY(crop),
        width: crop.width,
        height: crop.height,
      }

      const feed = crop.feedData
      feedDestination = feedDestination ?? {
        x: clampX(feed),
        y: clampY(feed),
        width: feed.width,
        height: feed.height,
      }

      const duplicate = newCrop(
        layoutId,
        { width: 1, height: 1 },
        existingCrops,
        cropDestination,
        feedDestination)

      const crops = this.whereLayoutIdIs(layoutId).value
      const inputName = crop.input.name.replace(/ copy( \([0-9]\))?$/, '')
      const copies = crops.filter(c => c.input.name.startsWith(`${inputName} copy`)).length

      this.createById(duplicate.id, {
        ...duplicate,
        input: {
          ...crop.input,
          name: `${inputName} copy` + (copies === 0 ? '' : ` (${copies})`),
        },
      })

      return duplicate.id
    },

    shift(id: string, amount: number) {

      const crops = this.sortedByZ().value

      const from = state[id].z
      const to = clamp(from + amount, 0, crops.length)

      for (const id of ids.value) {
        if (Math.sign(amount) === -1) {
          if (state[id].z >= to && state[id].z <= from) {
            state[id].z++
          }
        } else {
          if (state[id].z <= to && state[id].z >= from) {
            state[id].z--
          }
        }
      }

      state[id].z = to
    },

    moveToBackground(id: string) {
      this.shift(id, -Infinity)
    },

    moveToForeground(id: string) {
      this.shift(id, Infinity)
    },
  })
}

export const useCropsStore = defineStore('crops', useCropsStoreSetup)

export type CropsStore = ReturnType<typeof useCropsStoreSetup>

function randomArea() {
  const x = Math.random() * 0.5
  const y = Math.random() * 0.5
  const width = Math.max(0.1, Math.random() * (1 - x))
  const height = Math.max(0.25, Math.random() * (1 - y))
  return { x, y, width, height }
}

function generateDefaultFeedData(crop: Size) {

  const outputHeight = 1920
  const outputWidth = 1080

  const feedData = contain(crop, { width: outputWidth, height: outputHeight })

  return {
    x: (0.5 * outputWidth - 0.5 * feedData.width) / outputWidth,
    y: (0.5 * outputHeight - 0.5 * feedData.height) / outputHeight,
    width: feedData.width / outputWidth,
    height: feedData.height / outputHeight,
  }
}

export function newCrop(layoutId: string, container: Size, existingCrops: number, crop?: Area, feedData?: Area): Crop {

  const zIndex = existingCrops + 1

  crop = crop ?? randomArea()

  feedData = feedData ?? generateDefaultFeedData({
    width: crop.width * container.width,
    height: crop.height * container.height
  })

  return {
    ...crop,
    id: uuid(),
    layoutId: layoutId,
    z: zIndex,
    feedData,
    input: {
      shape: 'freeform',
      name: 'New Crop',
      color: `oklch(70% 0.15 ${Math.random() * 360})`,
    },
  }
}

export type Crop = {
  id: string
  layoutId: string

  x: number
  y: number
  z: number
  width: number
  height: number

  feedData: {

    x: number
    y: number
    width: number
    height: number
  }

  input: {
    shape: Shape
    name: string
    color: string
  }
}
