/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/vue-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  QueryKey,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import type {
  RenderDtoApiResponse,
  ApiError,
  CreateRenderDto,
  RenderDtoPageApiResponse,
  GetApiRendersParams,
  RenderDetailsDtoApiResponse,
  RenderWebhookPayload,
} from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const postApiRenders = (
  createRenderDto: MaybeRef<CreateRenderDto>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<RenderDtoApiResponse>(
    { url: `/api/renders`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createRenderDto },
    options
  )
}

export const getPostApiRendersMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiRenders>>, TError, { data: CreateRenderDto }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiRenders>>, TError, { data: CreateRenderDto }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiRenders>>, { data: CreateRenderDto }> = (
    props
  ) => {
    const { data } = props ?? {}

    return postApiRenders(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiRendersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiRenders>>>
export type PostApiRendersMutationBody = CreateRenderDto
export type PostApiRendersMutationError = ApiError

export const usePostApiRenders = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiRenders>>, TError, { data: CreateRenderDto }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getPostApiRendersMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiRenders = (
  params?: MaybeRef<GetApiRendersParams>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<RenderDtoPageApiResponse>(
    { url: `/api/renders`, method: 'get', params, signal },
    options
  )
}

export const getGetApiRendersQueryKey = (params?: MaybeRef<GetApiRendersParams>) =>
  ['api', 'renders', ...(params ? [params] : [])] as const

export const getGetApiRendersQueryOptions = <TData = Awaited<ReturnType<typeof getApiRenders>>, TError = ApiError>(
  params?: MaybeRef<GetApiRendersParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiRenders>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiRenders>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiRendersQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiRenders>>> = ({ signal }) =>
    getApiRenders(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetApiRendersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiRenders>>>
export type GetApiRendersQueryError = ApiError

export const useGetApiRenders = <TData = Awaited<ReturnType<typeof getApiRenders>>, TError = ApiError>(
  params?: MaybeRef<GetApiRendersParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiRenders>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiRendersQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const getApiRendersId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<RenderDtoApiResponse>(
    { url: `/api/renders/${unref(id)}`, method: 'get', signal },
    options
  )
}

export const getGetApiRendersIdQueryKey = (id: MaybeRef<string>) => ['api', 'renders', id] as const

export const getGetApiRendersIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiRendersId>>, TError = ApiError>(
  id: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiRendersId>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiRendersId>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiRendersIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiRendersId>>> = ({ signal }) =>
    getApiRendersId(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, staleTime: 60000, ...queryOptions }
}

export type GetApiRendersIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiRendersId>>>
export type GetApiRendersIdQueryError = ApiError

export const useGetApiRendersId = <TData = Awaited<ReturnType<typeof getApiRendersId>>, TError = ApiError>(
  id: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiRendersId>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiRendersIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const deleteApiRendersId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<RenderDtoApiResponse>(
    { url: `/api/renders/${unref(id)}`, method: 'delete' },
    options
  )
}

export const getDeleteApiRendersIdMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiRendersId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiRendersId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiRendersId>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return deleteApiRendersId(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiRendersIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiRendersId>>>

export type DeleteApiRendersIdMutationError = ApiError

export const useDeleteApiRendersId = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiRendersId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getDeleteApiRendersIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postApiRendersIdRetry = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<RenderDtoApiResponse>(
    { url: `/api/renders/${unref(id)}/retry`, method: 'post' },
    options
  )
}

export const getPostApiRendersIdRetryMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiRendersIdRetry>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiRendersIdRetry>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiRendersIdRetry>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return postApiRendersIdRetry(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiRendersIdRetryMutationResult = NonNullable<Awaited<ReturnType<typeof postApiRendersIdRetry>>>

export type PostApiRendersIdRetryMutationError = ApiError

export const usePostApiRendersIdRetry = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiRendersIdRetry>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getPostApiRendersIdRetryMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiRendersIdDetails = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<RenderDetailsDtoApiResponse>(
    { url: `/api/renders/${unref(id)}/details`, method: 'get', signal },
    options
  )
}

export const getGetApiRendersIdDetailsQueryKey = (id: MaybeRef<string>) => ['api', 'renders', id, 'details'] as const

export const getGetApiRendersIdDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiRendersIdDetails>>,
  TError = ApiError
>(
  id: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiRendersIdDetails>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiRendersIdDetails>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiRendersIdDetailsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiRendersIdDetails>>> = ({ signal }) =>
    getApiRendersIdDetails(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, staleTime: 60000, ...queryOptions }
}

export type GetApiRendersIdDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiRendersIdDetails>>>
export type GetApiRendersIdDetailsQueryError = ApiError

export const useGetApiRendersIdDetails = <
  TData = Awaited<ReturnType<typeof getApiRendersIdDetails>>,
  TError = ApiError
>(
  id: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiRendersIdDetails>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiRendersIdDetailsQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const putApiRendersReadall = (options?: SecondParameter<typeof streamLadderAxiosInstance>) => {
  return streamLadderAxiosInstance<void>({ url: `/api/renders/readall`, method: 'put' }, options)
}

export const getPutApiRendersReadallMutationOptions = <
  TError = ApiError,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiRendersReadall>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof putApiRendersReadall>>, TError, TVariables, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiRendersReadall>>, TVariables> = () => {
    return putApiRendersReadall(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiRendersReadallMutationResult = NonNullable<Awaited<ReturnType<typeof putApiRendersReadall>>>

export type PutApiRendersReadallMutationError = ApiError

export const usePutApiRendersReadall = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiRendersReadall>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getPutApiRendersReadallMutationOptions(options)

  return useMutation(mutationOptions)
}
export const putApiRendersIdRead = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<void>({ url: `/api/renders/${unref(id)}/read`, method: 'put' }, options)
}

export const getPutApiRendersIdReadMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiRendersIdRead>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof putApiRendersIdRead>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiRendersIdRead>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return putApiRendersIdRead(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiRendersIdReadMutationResult = NonNullable<Awaited<ReturnType<typeof putApiRendersIdRead>>>

export type PutApiRendersIdReadMutationError = ApiError

export const usePutApiRendersIdRead = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiRendersIdRead>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getPutApiRendersIdReadMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postWebhooksRenders = (
  renderWebhookPayload: MaybeRef<RenderWebhookPayload>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<void>(
    {
      url: `/webhooks/renders`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: renderWebhookPayload,
    },
    options
  )
}

export const getPostWebhooksRendersMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWebhooksRenders>>,
    TError,
    { data: RenderWebhookPayload },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postWebhooksRenders>>,
  TError,
  { data: RenderWebhookPayload },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postWebhooksRenders>>,
    { data: RenderWebhookPayload }
  > = (props) => {
    const { data } = props ?? {}

    return postWebhooksRenders(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostWebhooksRendersMutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksRenders>>>
export type PostWebhooksRendersMutationBody = RenderWebhookPayload
export type PostWebhooksRendersMutationError = ApiError

export const usePostWebhooksRenders = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWebhooksRenders>>,
    TError,
    { data: RenderWebhookPayload },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getPostWebhooksRendersMutationOptions(options)

  return useMutation(mutationOptions)
}
