<template>
  <div @click.self="this.$emit('cancel')" class="modal modal-open">
    <div class="layer-2 modal-box text-center">
      <h3 class="text-lg font-bold">{{ title }}</h3>
      <p class="py-2" v-if="message">{{ message }}</p>
      <div class="modal-action justify-center space-x-4">
        <button class="btn-primary btn" @click="this.$emit('confirm')">Yes</button>
        <button class="btn-outline btn-primary btn" @click="this.$emit('cancel')">No</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
  },
  emits: ['confirm', 'cancel'],
})
</script>
