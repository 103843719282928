/**
 * Implements MixPanel tracking. MixPanel is a third party service where we track product usage.
 *
 * Note that the folder isn't called tracking, and files are not "gtm.js" or "mixpanel.js", otherwise ad-blockers interfere with local development
 */

import mixpanel from 'mixpanel-browser'
import type { Logging, LoggingAdapter } from '@/logging'

// Enable MixPanel events in production and when not on "/sticker-render"
const MIXPANEL_ENABLED = !import.meta.env.DEV && !!import.meta.env.VITE_MIXPANEL_PROXY_URL

export const MixPanel: LoggingAdapter<Record<string, unknown>> = {
  initialize() {
    if (MIXPANEL_ENABLED) {
      try {
        mixpanel.init('67764727a11f65fdc8d0131c3041e779', {
          debug: false,
          ignore_dnt: true,
          api_host: import.meta.env.VITE_MIXPANEL_PROXY_URL,
        })
      } catch (e) {
        console.error('Could not initialize mixpanel: ', e)
      }
    }
  },
  trackEvent(eventName, properties) {
    if (MIXPANEL_ENABLED) {
      mixpanel.track(eventName, properties ?? {})
    } else {
      console.log('[mixpanel] Track: ', eventName, properties ?? {})
    }
  },
  identifyUser(id) {
    if (MIXPANEL_ENABLED) {
      mixpanel.identify(id)
    } else {
      console.log('[mixpanel] ID User: ', id)
    }
  },
  registerUser(data) {
    const DeploymentEnv = import.meta.env.VITE_DEPLOYMENT_ENV
    const props = {
      tier: data?.tier,
      deploymentEnv: DeploymentEnv,
      hasTwitchConnected: data?.twitchAccount != null,
      hasGoogleConnected: data?.googleAccount != null,
      hasTikTokConnected: data?.tikTokAccounts.length > 0,
      hasYouTubeConnected: data?.youTubeAccounts.length > 0,
    }

    if (MIXPANEL_ENABLED) {
      mixpanel.register(props)
    } else {
      console.log('[mixpanel] Tracking User: ', props)
    }
  },
  pageView() {
    // Not implemented, but is called
  },
  reset() {
    if (MIXPANEL_ENABLED) {
      mixpanel.reset()
    }
  },
}
