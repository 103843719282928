<script lang="ts" setup>
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import { type UserSubscription, useUserSubscriptionStore } from '@/store/user/userSubscription'
import { computed } from 'vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import Feature from '@/components-v2/lists/Feature.vue'
import plans from '@/data/plans'
import ResumeSubscription from '@/components/Account/Pause/ResumeSubscription.vue'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import { useCreateSubscription } from '@/Hooks/useCreateSubscription'
import { type RouteLocationRaw, useRouter } from 'vue-router'
import logging from '@/logging'
import { useConfirmDialog } from '@vueuse/core'
import CancelSubscriptionConfirmDialog from '@/areas/dashboard/components/CancelSubscriptionConfirmDialog.vue'
import { isDeprecatedPlusPlan } from '@/store/products'

const userInfo = useUserInfoStore()

const { createSubscription } = useCreateSubscription()

const subscriptionStore = useUserSubscriptionStore()

const subscription = computed(() => subscriptionStore.subscription)
const plan = computed(() => subscriptionStore.plan)
const status = computed(() => subscription.value?.status?.toLowerCase())
const isExpired = computed(() => subscriptionStore.isSubscriptionExpired)

// Dates

const dateTimeFormatter = Intl.DateTimeFormat('en-US', { dateStyle: 'long' })

function computeDateFormat(date: Date | string | null | undefined) {
  if (!date) return undefined
  return dateTimeFormatter.format(new Date(date))
}

const endDate = computed(() => computeDateFormat(subscription.value?.endDate))
const signupDate = computed(() => computeDateFormat(subscription.value?.signupDate))

const planId = computed(() => subscription.value?.subscriptionPlanId)

async function renew() {
  // restart a cancelled subscription. Starts a new checkout since we can't actually resume a cancelled Paddle subscription.
  if (planId.value && isDeprecatedPlusPlan(planId.value)) {
    // This subscription doesn't exist anymore (StreamLadder Plus). Open the upgrade dialog instead
    upgradeDialog.open({ reason: 'StreamLadder Plus Renewal' })
  } else {
    await createSubscription(planId.value ? { product: planId.value } : {}, 'StreamLadder Plus Renewal')
  }
}

// Cancel flow

const canCancel = computed(() => {
  return plan.value !== 'free' && subscription.value?.type !== 'sl' && status.value === 'active'
})

const router = useRouter()
const { reveal, isRevealed, cancel, confirm } = useConfirmDialog()

async function showTipsBeforeNavigating(subscription: UserSubscription, to: RouteLocationRaw) {
  const timeSinceSubscribing = new Date().getTime() - new Date(subscription.signupDate).getTime()
  const daysSubscribed = Math.floor(timeSinceSubscribing / (1000 * 60 * 60 * 24))

  logging.trackEvent('Subscription Cancel Clicked', { daysSubscribed })
  const confirmed = await reveal()

  if (!confirmed.isCanceled) {
    logging.trackEvent('Subscription Cancel FaqIgnored')
    await router.push(to)
  }
}
</script>

<template>
  <section class="flex flex-col gap-4">
    <header>
      <h2 class="leading-[1]">Your plan</h2>
      <p v-if="endDate" class="font-thin opacity-50">
        If you update your subscription, your new plan will go into effect on {{ endDate }}.
      </p>
    </header>

    <div class="layer-2 flex flex-col gap-4 rounded-xl px-8 py-6">
      <div class="flex flex-row flex-wrap items-center justify-between gap-2">
        <header class="flex items-center gap-4">
          <h2 :class="{ 'line-through': isExpired }" class="mb-0 leading-[1]">
            StreamLadder <span class="capitalize text-[#277dff]">{{ plan }}</span>
          </h2>

          <GoldPlanButton v-if="plan === 'gold'" :can-click="false" :small="true" />
          <SilverPlanButton v-if="plan === 'silver'" :can-click="false" :small="true" />
        </header>

        <span
          v-if="status"
          :class="{
            'bg-green-700 text-white': status === 'active',
            'bg-zinc-500 text-white': status === 'cancelled' || status === 'expired',
            'bg-gray-300 text-gray-700': status === 'paused',
          }"
          class="font-base cursor-default rounded-lg px-6 py-2 text-sm uppercase leading-[1] tracking-wide"
        >
          <template v-if="status === 'active'">Active</template>
          <template v-else-if="status === 'cancelled' || status === 'paused'">Cancelled</template>
        </span>
      </div>

      <p class="max-w-[50ch] font-thin opacity-50">
        <template v-if="signupDate">Subscribed since {{ signupDate }}</template>
      </p>

      <div class="flex w-full flex-col gap-3">
        <p v-if="status === 'paused' || (status === 'cancelled' && !isExpired)" class="mb-0 text-lg">
          Your subscription will expire on {{ endDate }} and you will lose access to
        </p>
        <p v-else-if="isExpired" class="mb-0 font-bold text-rose-600">
          You lost the following features on {{ endDate }}
        </p>

        <Feature
          v-for="(feature, i) in plans[plan].features"
          :key="i"
          :type="status === 'active' || plan === 'free' ? 'gained' : 'lost'"
        >
          {{ feature }}
        </Feature>
      </div>

      <footer class="flex flex-col gap-1">
        <div class="flex flex-wrap gap-2">
          <button
            v-if="status === 'cancelled'"
            class="flex items-center justify-center gap-2 rounded-xl border border-indigo-500 bg-indigo-500 px-6 py-2 text-white transition-all hover:border-indigo-600 hover:bg-indigo-600 active:scale-90"
            @click="renew"
          >
            Renew my subscription
          </button>

          <ResumeSubscription v-if="status === 'paused'">
            <template #button="props">
              <button
                class="flex items-center justify-center gap-2 rounded-xl border border-indigo-500 bg-indigo-500 px-6 py-2 text-white transition-all hover:border-indigo-600 hover:bg-indigo-600 active:scale-90"
                v-bind="props"
              >
                Resume my subscription
              </button>
            </template>
          </ResumeSubscription>

          <button
            v-if="plan !== 'gold'"
            :class="
              status === 'paused' || status === 'cancelled'
                ? 'border-current hover:border-indigo-500 hover:bg-indigo-500 hover:text-white'
                : 'border-indigo-500 bg-indigo-500 text-white hover:border-indigo-600 hover:bg-indigo-600'
            "
            class="flex items-center justify-center gap-2 rounded-xl border px-6 py-2 transition-all active:scale-90"
            @click="upgradeDialog.open({ reason: 'Dashboard Billing Page Plan Upgrade' })"
          >
            Change plan
          </button>

          <button
            v-else-if="status === 'expired'"
            class="flex items-center justify-center gap-2 rounded-xl border border-indigo-500 bg-indigo-500 px-6 py-2 text-white transition-all hover:border-indigo-600 hover:bg-indigo-600 active:scale-90"
            @click="upgradeDialog.open({ reason: 'Dashboard Billing Page Plan Upgrade Expired Plan' })"
          >
            Upgrade
          </button>

          <button
            v-if="subscription && canCancel"
            class="flex items-center justify-center gap-2 rounded-xl border border-current px-6 py-2 text-rose-400 transition-all hover:border-rose-500 hover:bg-rose-500 hover:text-white active:scale-90"
            @click="showTipsBeforeNavigating(subscription, { name: 'CancelSubscription' })"
          >
            Cancel my plan
          </button>
        </div>
      </footer>
    </div>

    <Teleport to="body">
      <CancelSubscriptionConfirmDialog v-model="isRevealed" @cancel="cancel" @confirm="confirm" />
    </Teleport>
  </section>
</template>

<style lang="scss" scoped></style>
