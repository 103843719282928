import { createGlobalState, toValue, useEventListener, useMediaControls, useRafFn, watchImmediate } from '@vueuse/core'
import type { MaybeRef } from '@vueuse/shared'
import { computed, nextTick, onUnmounted, ref, watch, watchEffect } from 'vue'
import { gsap } from 'gsap'

type Segment = {
  startMs: number
  endMs: number
}

type UseMediaControllerOptions = {
  src: MaybeRef<string>
  segments: MaybeRef<Segment[]>
  headStart?: number
  globalTimeline?: gsap.core.Timeline
}
const getId = (function () {
  let incrementingId = 0
  return function (element: HTMLElement) {
    if (!element.id) {
      element.id = 'id_' + incrementingId++
      // Possibly add a check if this ID really is unique
    }
    return element.id
  }
})()
const useCurrentPlaying = createGlobalState(() => {
  return ref<string>()
})
export let globaltimeline: gsap.core.Timeline

export const useSLVideoController = (target: MaybeRef<HTMLVideoElement>, options: UseMediaControllerOptions) => {
  const video = computed(() => toValue(target))
  const segments = computed(() => toValue(options?.segments) ?? [])
  const { duration: _duration, ...mediaControls } = useMediaControls(target, {
    src: options.src,
  })

  // Auto stop playing if a different video is playing
  const currentPlaying = useCurrentPlaying()
  const targetId = computed(() => {
    return video.value ? getId(video.value) : undefined
  })
  watch(mediaControls.playing, (playing) => {
    if (playing) {
      currentPlaying.value = targetId.value
    }
  })
  watch(currentPlaying, (currentPlaying) => {
    if (currentPlaying !== targetId.value) {
      mediaControls.playing.value = false
    }
  })

  // Segment support
  const hasSegments = computed(() => segments.value && segments.value.length > 0)

  const currentSegmentIndex = ref(0)

  useEventListener(target, 'seeked', (e) => {
    updateCurrentSegment()
  })
  watch(segments, () => {
    updateCurrentSegment()
  })

  const findCurrentSegmentIndex = (time: number) => {
    const _segments = toValue(options?.segments)
    if (!_segments) return
    for (let i = 0; i < _segments.length; i++) {
      const segment = _segments[i]
      if (time * 1000 < segment.endMs) {
        return i
      }
    }
  }

  const updateCurrentSegment = () => {
    if (!video.value || !hasSegments.value) return
    const currentSegment = segments.value[currentSegmentIndex.value]
    if (!currentSegment) {
      currentSegmentIndex.value = findCurrentSegmentIndex(mediaControls.currentTime.value) ?? 0
      return
    }
    const currentTime = video.value.currentTime
    if (currentSegment && currentTime > currentSegment.endMs / 1000) {
      const nextSegmentIndex = (currentSegmentIndex.value + 1) % segments.value.length
      const nextSegment = segments.value[nextSegmentIndex]

      if (Math.abs(nextSegment.startMs - currentSegment.endMs) > 100) {
        mediaControls.currentTime.value = nextSegment.startMs / 1000
      }

      currentSegmentIndex.value = nextSegmentIndex
    }

    if (currentSegment && currentTime < currentSegment.startMs / 1000) {
      const nextSegmentIndex = segments.value.findIndex(
        (segment) =>
          (segment.endMs > currentTime * 1000 + 100 && segment.startMs < currentTime * 1000 + 100) ||
          segment.startMs > currentTime * 1000 + 100
      )
      const nextSegment = segments.value[nextSegmentIndex]
      if (!nextSegment) return
      const isWithinSegment =
        currentTime * 1000 + 100 > nextSegment.startMs && currentTime * 1000 + 100 < nextSegment.endMs
      if (!isWithinSegment) mediaControls.currentTime.value = nextSegment.startMs / 1000
      currentSegmentIndex.value = nextSegmentIndex
    }
  }

  watch(video, () => {
    if (video.value) {
      if ('requestVideoFrameCallback' in video.value) {
        const loop = () => {
          if (mediaControls.playing.value) updateCurrentSegment()
          video.value?.requestVideoFrameCallback(loop)
        }
        video.value.requestVideoFrameCallback(loop)
      } else {
        // 120 is cleanly divisible by 24, 30, 48, 60; i.e. all common framerates
        // which should cause the cleanest frame draw
        setInterval(() => {
          if (mediaControls.playing.value) updateCurrentSegment()
        }, 1000 / 120)
      }
    }
  })

  async function resetCurrentTime() {
    const _segments = toValue(options?.segments)
    if (_segments && _segments.length > 0) {
      await nextTick(() => {
        if (_segments[0].startMs >= _duration.value * 1000) {
          throw new Error('Segment start time is greater than video duration')
        }
        mediaControls.currentTime.value = _segments[0].startMs / 1000
        currentSegmentIndex.value = 0
      })
    }
  }

  useEventListener('loadeddata', resetCurrentTime, video)
  watch(_duration, resetCurrentTime)

  // Corrected duration based on segments
  const duration = computed(() => {
    const _segments = toValue(options?.segments)
    if (hasSegments.value && _segments) {
      return _segments.reduce((acc, segment) => {
        return acc + (segment.endMs - segment.startMs)
      }, 0)
    }
    return _duration.value * 1000
  })

  // corrected currentTime based on segments
  const emptyDurationBeforeCurrentSegment = computed(() => {
    let emptyDuration = 0
    let previousEnd = 0
    if (!hasSegments.value) return emptyDuration
    const _segments = toValue(options?.segments)
    if (_segments && currentSegmentIndex.value !== undefined) {
      for (let i = 0; i <= currentSegmentIndex.value; i++) {
        const segment = _segments[i]
        if (!segment) break
        emptyDuration += segment.startMs - previousEnd
        previousEnd = segment.endMs
      }
    }
    return emptyDuration
  })

  const currentTime = computed({
    get() {
      return mediaControls.currentTime.value * 1000 - emptyDurationBeforeCurrentSegment.value
    },
    set(value) {
      const percentage = value / duration.value
      const correctedTime = percentage * _duration.value
      let emptyDuration = 0
      let previousEnd = 0
      const _segments = toValue(options?.segments)
      if (_segments) {
        for (let i = 0; i < _segments.length; i++) {
          const segment = _segments[i]
          if (correctedTime > segment.startMs) break
          emptyDuration += segment.startMs - previousEnd
          previousEnd = segment.endMs
        }
      }
      mediaControls.currentTime.value = value / 1000 + emptyDuration / 1000
    },
  })

  // Timeline support
  const timeline = ref(
    options.globalTimeline
      ? undefined
      : gsap.timeline({
          repeat: -1,
          repeatDelay: 0,
          paused: true,
        })
  )

  const getTimeLine = () => {
    if (options.globalTimeline) {
      return options.globalTimeline
    } else {
      return timeline.value
    }
  }

  const seekTimeline = (time: number) => {
    if (options.globalTimeline) {
      globaltimeline = options.globalTimeline
      globaltimeline.seek(time)
    } else {
      timeline.value?.seek(time)
    }
  }

  const initTimeline = (duration: number) => {
    if (options.globalTimeline) {
      globaltimeline = options.globalTimeline
      globaltimeline.set({}, {}, duration)
    } else {
      timeline.value?.set({}, {}, duration)
    }
  }

  watch(mediaControls.playing, (playing) => {
    if (playing) {
      getTimeLine()?.play()
    } else {
      getTimeLine()?.pause()
    }
  })
  useEventListener(target, 'timeupdate', () => {
    const _target = toValue(target)
    if (!_target) return

    // if time is out of sync, seek to the correct time
    if (Math.abs(mediaControls.currentTime.value - getTimeLine()?.time()) > 0.1) {
      seekTimeline(mediaControls.currentTime.value)
    }
  })
  useEventListener(target, 'seeked', () => {
    const _target = toValue(target)
    if (!_target) return
    // if time is out of sync, seek to the correct time
    seekTimeline(mediaControls.currentTime.value)
  })

  watch(_duration, async () => {
    initTimeline(_duration.value)
  })

  return {
    ...mediaControls,
    _duration: _duration,
    _currentTime: mediaControls.currentTime,
    duration,
    currentTime,
    timeline,
    currentSegmentIndex,
  }
}
