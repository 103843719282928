export const formatFileSize = (bytes: number, decimals = 2) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  for (let i = 0; i < sizes.length; i++) {
    const fileSize = Number((bytes / Math.pow(1000, i)).toFixed(decimals))
    if (fileSize < 1000) {
      return fileSize + ' ' + sizes[i]
    }
  }
}

export const kbToBytes = (kb: number) => kb * 1000
export const mbToBytes = (mb: number) => mb * 1000 * 1000
export const gbToBytes = (gb: number) => gb * 1000 * 1000 * 1000
export const tbToBytes = (tb: number) => tb * 1000 * 1000 * 1000 * 1000

export const bytesToKb = (bytes: number) => bytes / 1000
export const mbToKb = (mb: number) => mb * 1000
export const gbToKb = (gb: number) => gb * 1000 * 1000
export const tbToKb = (tb: number) => tb * 1000 * 1000 * 1000

export const bytesToMb = (bytes: number) => bytes / 1000 / 1000
export const kbToMb = (kb: number) => kb / 1000
export const gbToMb = (gb: number) => gb * 1000
export const tbToMb = (tb: number) => tb * 1000 * 1000

export const bytesToGb = (bytes: number) => bytes / 1000 / 1000 / 1000
export const kbToGb = (kb: number) => kb / 1000 / 1000
export const mbToGb = (mb: number) => mb / 1000
export const tbToGb = (tb: number) => tb * 1000

export const bytesToTb = (bytes: number) => bytes / 1000 / 1000 / 1000 / 1000
export const kbToTb = (kb: number) => kb / 1000 / 1000 / 1000
export const mbToTb = (mb: number) => mb / 1000 / 1000
export const gbToTb = (gb: number) => gb / 1000
