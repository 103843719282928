import { acceptHMRUpdate, defineStore } from 'pinia'
import type { LayoutKey } from '@/data/layoutData'
import { getLayout, LayoutKeys } from '@/data/layoutData'
import type { Template } from '@/store/user/userTemplates'
import { v4 } from 'uuid'
import { useEditorMainStore } from '@/store/editor/editorMain'
import type { Crop, CropOptions, EditorFragment, FeedOptions, ScaleSet } from '@/modules/SLVideoplayer/types'
import { feedTypes } from '@/modules/SLVideoplayer/types'
import { cloneDeep, merge } from 'lodash-es'
import { toRaw } from 'vue'
import { getZoneWithFixedAspectRatioBasedOnExistingZone } from '@/modules/SLVideoplayer/CroppingService'
import logging from '@/logging'
import { useLayoutsStore } from '@/store/entity-system/useLayoutsStore'
import { useCropsStore } from '@/store/entity-system/useCropsStore'

type ICrop = {
  key: string
  zIndex?: number
} & Crop

export type Segment = {
  id: string
  start: number
  end: number
  disabled?: boolean
  layout?: LayoutKey
}

export type SegmentWithCropData = Segment & {
  cropData?: ICrop[]
}

export type Zoom = {
  id: string
  start: number
  end: number
}

interface editorFeedDataState {
  fragments: Record<string, EditorFragment>
  zooms: Zoom[]
  segments: Segment[]
  layout?: LayoutKey
  hasChanged: boolean
}

const defaultCropOptions: Required<CropOptions> = {
  isRound: false,
  fixedRatio: false,
  aspectRatio: 1,
  initialCropSize: 1,
  showSnapping: false,
  minimalRatio: 9 / 16,
}

const defaultFeedOptions: Required<FeedOptions> = {
  resizable: true,
  draggable: true,
  snapHorizontal: true,
  snapVertical: true,
  type: 'facecam',
}

export const useEditorFeedDataStore = defineStore('editorFeedData', {
  state: (): editorFeedDataState => {
    return {
      fragments: {},
      segments: [],
      zooms: [],
      hasChanged: false,
    }
  },
  getters: {
    fragment: (state: editorFeedDataState) => (fragmentKey: string) => {
      return state.fragments[fragmentKey]
    },
    fragmentArray: (state: editorFeedDataState) => {
      return Object.values(state.fragments).sort((a, b) => b.zIndex - a.zIndex)
    },
    renderFragments: (state: editorFeedDataState): SegmentWithCropData[] | undefined => {
      const fragmentArray = Object.values(state.fragments).sort((a, b) => b.zIndex - a.zIndex)
      const cropData = fragmentArray.map((_fragment: EditorFragment) => {
        const fragment = toRaw(_fragment)
        return {
          key: fragment.key,
          zIndex: fragment.zIndex,
          x: fragment.cropData?.x || 0,
          y: fragment.cropData?.y || 0,
          width: fragment.cropData?.w || 1,
          height: fragment.cropData?.h || 1,
          feedData: {
            x: fragment.feedData?.x || 0,
            y: fragment.feedData?.y || 0,
            width: fragment.feedData?.w || 1,
            height: fragment.feedData?.h || 1,
            effects: fragment.cropOptions.isRound ? [{ type: 'rounded' }] : [],
          },
        }
      })

      const zoomCrop = cropData[0]

      // loop over segments and insert the zooms as new segments over the old ones at the right position
      const result: SegmentWithCropData[] = []
      state.segments.forEach((_segment) => {
        const zooms = state.zooms.filter((zoom) => zoom.start < _segment.end && zoom.end > _segment.start)
        const segment = { ..._segment, cropData } as Segment
        if (zooms.length === 0) {
          result.push(segment)
          return
        }

        if (!zoomCrop) {
          console.error('No cropdata found for segment', segment)
          result.push(segment)
          return
        }
        const zoomCropData: ICrop = {
          ...zoomCrop,
          ...getZoneWithFixedAspectRatioBasedOnExistingZone(
            zoomCrop,
            {
              width: 1920,
              height: 1080,
            },
            9 / 16
          ),
          feedData: {
            x: 0,
            y: 0,
            width: 1,
            height: 1,
          },
        }

        zooms.forEach((zoom, index) => {
          if (zoom.start > segment.start && index === 0) {
            result.push({
              ...segment,
              end: zoom.start,
            })
          }
          const previousZoom = zooms[index - 1]
          if (previousZoom && previousZoom.end < zoom.start) {
            result.push({
              ...segment,
              start: previousZoom.end,
              end: zoom.start,
            })
          }
          result.push({
            ...segment,
            start: zoom.start > segment.start ? zoom.start : segment.start,
            end: zoom.end < segment.end ? zoom.end : segment.end,
            layout: 'FullScreen',
            cropData: [zoomCropData],
          })
          if (zoom.end < segment.end && index === zooms.length - 1) {
            result.push({
              ...segment,
              start: zoom.end,
            })
          }
        })
      })

      return result
    },
    defaultCropData(): ICrop[] {
      return (this.fragmentArray ?? []).map((fragment: EditorFragment) => ({
        key: fragment.key,
        zIndex: fragment.zIndex,
        x: fragment.cropData?.x || 0,
        y: fragment.cropData?.y || 0,
        width: fragment.cropData?.w || 1,
        height: fragment.cropData?.h || 1,
        feedData: {
          x: fragment.feedData?.x || 0,
          y: fragment.feedData?.y || 0,
          width: fragment.feedData?.w || 1,
          height: fragment.feedData?.h || 1,
          effects: fragment.cropOptions.isRound ? [{ type: 'rounded' }] : [],
        },
      }))
    },
  },
  actions: {
    initSegments(duration?: number) {
      const editorMainStore = useEditorMainStore()

      if (!duration) {
        this.segments = [...this.segments].map((segment) => ({
          ...segment,
          layout: editorMainStore.layoutName as LayoutKey,
        }))
      } else if (this.segments.length === 0) {
        this.segments.push({
          id: v4(),
          start: 0,
          end: duration,
          layout: editorMainStore.layoutName as LayoutKey,
        })
      }
    },
    splitSegment(splitTime: number) {
      const newSegments: Segment[] = []
      this.segments.forEach((segment) => {
        if (segment.start < splitTime && segment.end > splitTime) {
          console.log('splitting segment', cloneDeep(segment))
          newSegments.push({
            ...cloneDeep(segment),
            id: v4(),
            start: segment.start,
            end: splitTime,
          })
          newSegments.push({
            ...cloneDeep(segment),
            id: v4(),
            start: splitTime,
            end: segment.end,
          })
        } else {
          newSegments.push(segment)
        }
      })
      this.segments = newSegments
    },
    mergeSegments(id: string) {
      const newSegments: Segment[] = []
      this.segments.forEach((segment) => {
        if (segment.id === id) {
          const previousSegment = newSegments.pop()
          if (previousSegment) {
            newSegments.push({
              id: previousSegment.id,
              start: previousSegment.start,
              end: segment.end,
            })
          }
        } else {
          newSegments.push(segment)
        }
      })
      this.segments = newSegments
    },
    removeSegment(id: string) {
      if (this.segments.length === 1) return
      const index = this.segments.findIndex((segment) => segment.id === id)
      if (index === -1) return
      this.segments.splice(index, 1)
    },
    updateSegment(id: string, segment: Omit<Segment, 'id'>) {
      const segmentIndex = this.segments.findIndex((segment) => segment.id === id)
      if (segmentIndex === -1) return

      const newSegement = merge(this.segments[segmentIndex], segment)
      this.segments[segmentIndex] = {
        ...newSegement,
        start: Math.round(segment.start),
        end: Math.round(segment.end),
      }
    },
    addZoom(zoom: Omit<Zoom, 'id'>) {
      const id = v4()
      const _zoom = {
        id,
        ...zoom,
        start: Math.round(zoom.start),
        end: Math.round(zoom.end),
      }
      logging.trackEvent('Zoom Segment Added', {
        duration: _zoom.end - _zoom.start,
      })
      // insert at the right position
      const index = this.zooms.findIndex((z) => z.start > _zoom.start)
      if (index === -1) {
        this.zooms.push(_zoom)
      } else {
        this.zooms.splice(index, 0, _zoom)
      }
      return id
    },
    removeZoom(id: string) {
      const index = this.zooms.findIndex((zoom) => zoom.id === id)
      if (index === -1) return

      logging.trackEvent('Zoom Segment removed', {})

      this.zooms.splice(index, 1)
    },
    updateZoom(id: string, zoom: Omit<Zoom, 'id'>) {
      const zoomIndex = this.zooms.findIndex((zoom) => zoom.id === id)
      if (zoomIndex === -1) return
      this.zooms[zoomIndex] = {
        ...this.zooms[zoomIndex],
        ...zoom,
        start: Math.round(zoom.start),
        end: Math.round(zoom.end),
      }
    },
    resetFragments() {
      this.$patch((state) => {
        state.fragments = {}
        state.hasChanged = false
      })
      console.log('resetFragments')
    },
    // Initialize the fragment-store with the default values for the layout and saved settings from localstorage
    initializeForCrop(layoutKey: LayoutKey, videoW: number, videoH: number) {
      console.log('initializeForCrop', layoutKey)
      this.resetFragments()
      this.layout = layoutKey
      const layout = getLayout(layoutKey)

      if (!layout) throw new Error(`Layout ${layoutKey} not found`)
      if ('baseFragments' in layout === false) throw new Error(`Layout ${layoutKey} has no baseFragments`)

      this.$patch((state) => {
        if (!layout || !layout.baseFragments) return
        state.fragments = {}
        layout.baseFragments.forEach((fragment) => {
          const newFragment = mergeDefaultFeedOptions(fragment)
          newFragment.cropData = getCropData(newFragment, videoW, videoH)
          state.fragments[fragment.key] = newFragment
        })
        state.hasChanged = true
      })

      this.initSegments()
    },
    initializeForCustomLayout(layoutId: string) {

      const layoutsStore = useLayoutsStore()
      const cropsStore = useCropsStore()

      const layout = layoutsStore.selectById(layoutId).value
      if (!layout) throw new Error(`Layout ${layoutId} not found`)

      this.resetFragments()
      this.layout = 'Custom'

      const fragments = cropsStore.selectEditorFragmentsByLayoutId(layoutId).value

      this.$patch((state) => {
        state.fragments = {}
        for (const fragment of fragments) {
          state.fragments[fragment.key] = fragment
        }
        state.hasChanged = true
      })

      console.log(fragments)
    },
    initializeForFeed(layoutKey: LayoutKey, videoW: number, videoH: number, screenW: number, screenH: number) {
      this.resetFragments()
      this.layout = layoutKey
      const layout = getLayout(layoutKey)
      this.$patch((state) => {
        if (!layout || !layout.baseFragments) return
        state.fragments = {}
        layout.baseFragments.forEach((fragment) => {
          const newFragment = mergeDefaultFeedOptions(fragment)
          newFragment.cropData = getCropData(newFragment, videoW, videoH)
          newFragment.feedData = getFeedData(newFragment, layoutKey, screenW, screenH)
          state.fragments[fragment.key] = newFragment
        })
        state.hasChanged = true
      })

      this.initSegments()
    },
    initializeForTemplate(template: Template, videoW?: number, videoH?: number, screenW?: number, screenH?: number) {
      this.resetFragments()
      let layoutKey = template.layoutName
      // @ts-ignore
      if (layoutKey === 'Split') {
        const splits = {
          '4x3': LayoutKeys.Split4x3,
          '16x9': LayoutKeys.Split16x9,
        }
        layoutKey = splits[template.ratio] || LayoutKeys.SplitFree
      }
      this.layout = layoutKey
      const layout = getLayout(layoutKey)
      this.$patch((state) => {
        if (!layout || !layout.baseFragments) return
        state.fragments = {}
        layout.baseFragments.forEach((fragment, index) => {
          const newFragment = mergeDefaultFeedOptions(fragment)
          const cropperFromKey = template.croppers.find((cropper) =>
            cropper.cropperKey
              // Remap cropper keys from old templates which are not compatible with the new setup
              .replace('square16x9', 'square')
              .replace('splitfreeform', 'splitfree')
              .includes(fragment.key)
          )?.data
          const defaultCropData = getCropData(newFragment, videoW, videoH)
          newFragment.cropData = cropperFromKey || defaultCropData
          newFragment.feedData =
            template.feeds.find((feed) => feed.feedKey.includes(fragment.key))?.data ||
            getFeedData(newFragment, layoutKey, screenW, screenH)
          state.fragments[fragment.key] = newFragment
        })
        state.hasChanged = true
      })

      this.initSegments()
    },
    /**
     * Same as initializeForFeed, but keeps the CropData from the old fragments
     */
    initializeForTemplateFeeds(
      template: Template,
      videoW?: number,
      videoH?: number,
      screenW?: number,
      screenH?: number
    ) {
      let layoutKey = template.layoutName
      // @ts-ignore
      if (layoutKey === 'Split') {
        const splits = {
          '4x3': LayoutKeys.Split4x3,
          '16x9': LayoutKeys.Split16x9,
        }
        layoutKey = splits[template.ratio] || LayoutKeys.SplitFree
      }

      const oldFragments = this.fragments
      this.initializeForTemplate(template, videoW, videoH, screenW, screenH)

      // Loop through all fragments and set the cropData from the old fragments
      for (const fragmentKey in oldFragments) {
        const oldFragment = oldFragments[fragmentKey]

        const oldCropRatio = oldFragment?.cropData?.ratio
        const newCropRatio = oldCropRatio

        this.fragments[fragmentKey].cropData = oldFragment.cropData

        // If the crop ratio has changed, reset the feedData to default position
        if (oldCropRatio !== undefined
          && newCropRatio !== undefined
          && Math.abs(newCropRatio - (oldFragment?.cropData?.ratio ?? 0)) > 0.01
        ) {
          this.fragments[fragmentKey].feedData = getFeedData(this.fragments[fragmentKey], layoutKey, screenW, screenH)
        }
      }

      this.initSegments()
    },
    // Update the localstore with the crop data for a fragment,
    storeCropData(fragmentKey: string) {
      const fragment = this.fragment(fragmentKey)
      if (fragment && fragment.cropData) {
        localStorage.setItem(fragmentKey, JSON.stringify(fragment.cropData))
      }
    },
    setFragmentsAndGetDefaults(fragments: EditorFragment[], videoW: number, videoH: number) {
      if (this.layout === undefined) throw new Error('Layout is undefined')
      this.$patch((state) => {
        state.fragments = {}
        fragments.forEach((fragment) => {
          const newFragment = mergeDefaultFeedOptions(fragment)
          newFragment.cropData = getCropData(newFragment, videoW, videoH)
          state.fragments[fragment.key] = newFragment
        })
        state.hasChanged = true
      })
    },
    updateFeed(fragmentKey: string, feed: ScaleSet) {
      this.$patch((state) => {
        const fragment = this.fragment(fragmentKey)
        if (fragment) {
          state.fragments = {
            ...state.fragments,
            [fragmentKey]: {
              ...fragment,
              feedData: feed,
            },
          }
          state.hasChanged = true
        }
      })
    },
    updateCrop(fragmentKey: string, crop: ScaleSet) {
      this.$patch((state) => {
        const fragment = this.fragment(fragmentKey)
        if (fragment) {
          state.fragments = {
            ...state.fragments,
            [fragmentKey]: {
              ...fragment,
              cropData: crop,
            },
          }
          state.hasChanged = true
        }
      })
    },
    getLoggingData() {
      return {
        FragmentCount: this.renderFragments?.length,
        SegmentCount: this.segments.length,
        ZoomCount: this.zooms.length,
      }
    },
    reset() {
      this.$reset()
    },
  },
})

const mergeDefaultFeedOptions = (fragment: EditorFragment) => {
  const { cropOptions, feedOptions } = fragment
  return {
    ...fragment,
    cropOptions: {
      ...defaultCropOptions,
      ...cropOptions,
    },
    feedOptions: {
      ...defaultFeedOptions,
      ...feedOptions,
    },
  }
}

const getFeedData = (fragment: EditorFragment, layoutKey: LayoutKey, screenW: number, screenH: number) => {
  // #TODO check localstore for data
  return getDefaultFeedData(fragment, layoutKey, screenW, screenH)
}
const getDefaultFeedData = (fragment: EditorFragment, layoutKey: LayoutKey, screenW: number, screenH: number) => {
  const screenCalculations = {
    w: 0,
    h: 0,
    x: 0,
    y: 0,
  }
  if (!fragment.cropData?.ratio) return toScaleSet(screenCalculations, screenW, screenH)

  if (layoutKey === LayoutKeys.Square) {
    if (fragment.feedOptions.type === feedTypes.FaceCam) {
      screenCalculations.w = screenW * 0.4
      screenCalculations.h = screenCalculations.w / fragment.cropData.ratio
      screenCalculations.x = (screenW - screenCalculations.w) / 2
      screenCalculations.y = (screenH - screenW) / 2
    }
    if (fragment.feedOptions.type === feedTypes.GameFeed) {
      screenCalculations.w = screenW
      screenCalculations.h = screenCalculations.w / fragment.cropData.ratio
      screenCalculations.x = 0
      screenCalculations.y = (screenH - screenCalculations.h) * 0.5
    }
    return toScaleSet(screenCalculations, screenW, screenH)
  }

  // @ts-ignore
  if ([LayoutKeys.Split16x9, LayoutKeys.Split4x3, LayoutKeys.SplitFree].includes(layoutKey)) {
    if (fragment.feedOptions.type === feedTypes.FaceCam) {
      screenCalculations.w = screenW
      screenCalculations.h = screenCalculations.w / fragment.cropData.ratio
      screenCalculations.x = 0
      screenCalculations.y = 0
    }
    if (fragment.feedOptions.type === feedTypes.GameFeed) {
      screenCalculations.w = screenW
      screenCalculations.h = screenCalculations.w / fragment.cropData.ratio
      screenCalculations.x = 0
      screenCalculations.y = screenH - screenCalculations.h
    }
    return toScaleSet(screenCalculations, screenW, screenH)
  }

  // calculate the screen size based on fragment type
  // this is used for the layouts: Small Facecam, Circle Facecam, Game Ui
  switch (fragment.feedOptions.type) {
    case feedTypes.FaceCam:
      screenCalculations.w = screenW * 0.66
      screenCalculations.h = screenCalculations.w / fragment.cropData.ratio
      screenCalculations.x = (screenW - screenCalculations.w) * 0.5
      screenCalculations.y = screenH * 0.05
      break
    case feedTypes.GameUi:
      screenCalculations.w = screenW * 0.66
      screenCalculations.h = screenCalculations.w / fragment.cropData.ratio
      screenCalculations.x = (screenW - screenCalculations.w) * 0.5
      screenCalculations.y = screenH * 0.95 - screenCalculations.h
      break
    case feedTypes.GameFeed:
      screenCalculations.w = screenW
      screenCalculations.h = screenCalculations.w / fragment.cropData.ratio
      screenCalculations.x = 0
      screenCalculations.y = (screenH - screenCalculations.h) * 0.5
      break
  }
  return toScaleSet(screenCalculations, screenW, screenH)
}

type OptionalExceptFor<T, TRequired extends keyof T> = Partial<T> & Pick<T, TRequired>
const toScaleSet = (cropData: OptionalExceptFor<ScaleSet, 'x' | 'y' | 'w' | 'h'>, width: number, height: number) => {
  return {
    x: cropData.x / width,
    y: cropData.y / height,
    w: cropData.w / width,
    h: cropData.h / height,
    ratio: cropData.ratio || cropData.w / cropData.h,
  }
}

const getCropData = (fragment: EditorFragment, width: number, height: number): ScaleSet => {
  const localCropData = getCropDataFromLocalStorage(fragment)
  if (localCropData) {
    if (isValidCropData(fragment, localCropData, width, height)) {
      return localCropData
    } else {
      localStorage.removeItem(fragment.key)
    }
  }

  return getDefaultCropData(fragment, width, height)
}

const isValidCropData = (fragment: EditorFragment, cropData: ScaleSet, screenW: number, screenH: number): boolean => {
  const { cropOptions } = fragment
  const { fixedRatio, aspectRatio } = cropOptions
  const { w, h, x, y } = cropData

  const width = w * screenW
  const height = h * screenH

  if (fixedRatio) {
    const ratio = width / height
    if (Math.abs(ratio - aspectRatio) > 0.01) {
      return false
    }
  }

  if (width < 10 || height < 10) {
    return false
  }

  if (x < -0.1 || y < -0.1 || x > 1.1 || y > 1.1) {
    return false
  }
  if (w < 0 || h < 0 || w > 1.1 || h > 1.1) {
    return false
  }
  if (x + w > 1 || y + h > 1) {
    return false
  }

  return true
}

const getDefaultCropData = (fragment: EditorFragment, width: number, height: number): ScaleSet => {
  const { cropOptions } = fragment
  const { fixedRatio, aspectRatio, initialCropSize } = cropOptions
  let h = height * initialCropSize
  let w = fixedRatio ? h * aspectRatio : width * initialCropSize
  if (w > width) {
    w = width
    h = w / aspectRatio
  }
  const x = (width - w) * 0.5
  const y = (height - h) * 0.5
  return toScaleSet(
    {
      x,
      y,
      w,
      h,
    },
    width,
    height
  )
}
const getCropDataFromLocalStorage = (fragment: EditorFragment): ScaleSet | null => {
  if (!(fragment.key in localStorage)) return null
  const cropperData = JSON.parse(localStorage.getItem(fragment.key) || '{}')

  if ('width' in cropperData && 'height' in cropperData) {
    const { width, height } = cropperData
    cropperData.w = width
    cropperData.h = height
  }

  return cropperData as ScaleSet
}

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useEditorFeedDataStore, import.meta.hot))
}
