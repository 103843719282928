import axios from 'axios'

export const accountsAxios = axios.create({
  withCredentials: true,
  baseURL: import.meta.env.VITE_ACCOUNTS_URL,
})

const tokenTypes = Object.freeze({
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
} as const)
export type TokenType = (typeof tokenTypes)[keyof typeof tokenTypes]

// Stores the Promise of the fetchAccessTokenInternal call
let fetchAccessTokenPromise: Promise<string> | null = null

/**
 * Gatekeeper for fetching the access token. Only 1 thread may request a token at once. The other threads will await on that single promise if available
 *
 * @returns {Promise<string>}
 */
export const fetchAccessToken = async () => {
  // If one thread is already fetching, await on the promise
  if (fetchAccessTokenPromise) {
    return fetchAccessTokenPromise
  }

  // Store the promise in the global. Get the result from it, clear promise and return the result
  fetchAccessTokenPromise = fetchAccessTokenInternal()
  const result = await fetchAccessTokenPromise
  fetchAccessTokenPromise = null
  return result
}

function expiryDateInSeconds(token: { expiryDate: string }) {
  return new Date(token.expiryDate).getTime() / 1000
}

export const getLocalAccessToken = () => {
  const token = getToken(tokenTypes.ACCESS_TOKEN)
  if (!token) return null
  return token
}

/**
 * Returns an access-token based on access/refresh tokens from the localstorage. Refreshes or fetches if necessary
 *
 * @returns {Promise<string>}
 */
const fetchAccessTokenInternal = async () => {
  try {
    // Check if we have stored access/refresh tokens
    const storedAccessToken = getToken(tokenTypes.ACCESS_TOKEN)
    const storedRefreshToken = getToken(tokenTypes.REFRESH_TOKEN)

    if (storedAccessToken !== null && storedRefreshToken !== null) {
      const now = new Date().getTime() / 1000

      // If the access token is still valid, return the access token.
      if (now < expiryDateInSeconds(storedAccessToken) - 70) {
        return storedAccessToken.token
      }

      // If the refresh token is still valid, refresh and get the access-token.
      if (now < expiryDateInSeconds(storedRefreshToken) - 70) {
        const res = await refresh(storedRefreshToken.token)
        storeToken(res)
        return res.accesToken.token
      }
    }
  } catch (e) {
    // If anything fails, throw away the tokens from local-storage and fetch new ones from the Accounts service
    console.error(e)
    localStorage.removeItem(tokenTypes.ACCESS_TOKEN)
    localStorage.removeItem(tokenTypes.REFRESH_TOKEN)
  }

  // Get the refresh and access tokens directly from the Accounts service
  const res = await fetchUserTokens()
  storeToken(res)
  return res.accesToken.token
}

/**
 * Stores the token in the local storage.
 *
 * @param data
 */
const storeToken = (data: unknown) => {
  if (!data) throw new Error('No data provided')
  if (typeof data !== 'object') throw new Error('Data is not an object')
  if (!('accesToken' in data)) throw new Error('No accesToken provided')
  if (!('refreshToken' in data)) throw new Error('No refreshToken provided')
  window.localStorage.setItem(tokenTypes.ACCESS_TOKEN, JSON.stringify(data.accesToken))
  window.localStorage.setItem(tokenTypes.REFRESH_TOKEN, JSON.stringify(data.refreshToken))
}

/**
 * Fetches the token from the local storage.
 *
 * @param type
 * @returns {string|null}
 */
const getToken = (type: TokenType) => {
  const tokenData = window.localStorage.getItem(type)
  return tokenData ? JSON.parse(tokenData) : null
}

/**
 * Fetches the tokens.
 * @returns {Promise<any>}
 */
export const fetchUserTokens = async () => {
  const res = await accountsAxios.get('api/token')
  return res.data
}

/**
 * Refreshes the tokens
 * @param refreshToken
 * @returns {Promise<AxiosResponse<any>>}
 */
export const refresh = async (refreshToken: string) => {
  const res = await accountsAxios.post('api/token/refresh', { refreshToken })
  return res.data
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const revokeTokens = () => {
  localStorage.removeItem(tokenTypes.ACCESS_TOKEN)
  localStorage.removeItem(tokenTypes.REFRESH_TOKEN)
}
