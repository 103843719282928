import { useWindowSize } from '@vueuse/core'
import { computed } from 'vue'

export const screen = {
  'sm': 640,
  'md': 768,
  'lg': 1024,
  'xl': 1280,
  '2xl': 1536,
}

type ScreenSize = keyof typeof screen | number

export function useScreenBetween(minWidth: ScreenSize, maxWidth: ScreenSize) {
  const { width } = useWindowSize()

  const max = typeof maxWidth === 'string' ? screen[maxWidth] : maxWidth
  const min = typeof minWidth === 'string' ? screen[minWidth] : minWidth

  return computed(() => {
    return width.value < max && width.value >= min
  })
}

export function useScreen(minWidth: ScreenSize) {
  const { width } = useWindowSize()

  const min = typeof minWidth === 'string' ? screen[minWidth] : minWidth

  return computed(() => width.value >= min)
}

export function useScreenName() {
  const sm = useScreen('sm')
  const md = useScreen('md')
  const lg = useScreen('lg')
  const xl = useScreen('xl')
  const xxl = useScreen('2xl')

  return computed(() => {
    if (xxl.value) return '2xl'
    if (xl.value) return 'xl'
    if (lg.value) return 'lg'
    if (md.value) return 'md'
    if (sm.value) return 'sm'
    return 'xs'
  })
}
