<template>
  <div :style="`height: calc(${height} - ${marginBottom})`">
    <div ref="animation" :style="`width: ${width}; height: ${height}`"></div>
  </div>
</template>

<script>
import axios from 'axios'
import * as Sentry from '@sentry/vue'

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    autoPlay: {
      type: Boolean,
      default: true,
    },
    renderer: {
      type: String,
      default: 'svg',
    },
    speed: {
      type: Number,
      default: 1,
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    marginBottom: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      anim: null,
    }
  },
  async mounted() {
    await this.init()
  },
  beforeUnmount() {
    if (this.anim) this.anim.destroy()
  },
  methods: {
    async init() {
      const animationData = (await axios.get(this.url, { withCredentials: false })).data

      const settings = {
        container: this.$refs.animation,
        renderer: this.renderer,
        loop: this.loop,
        autoplay: this.autoPlay,
        animationData,
      }
      const Lottie = await this.tryLoadLottie()
      this.anim = Lottie.loadAnimation(settings)
      this.anim.addEventListener('loopComplete', () => {
        this.$emit('loopComplete', this.anim)
      })
      this.anim.addEventListener('complete', () => {
        this.$emit('complete', this.anim)
      })
      this.anim.addEventListener('enterFrame', () => {
        this.$emit('enterFrame', this.anim)
      })
    },
    async tryLoadLottie() {
      return await import('lottie-web').catch((reason) => {
        Sentry.captureException(JSON.stringify(reason))
      }).then((module) => {
        return module.default
      })
    },
    play() {
      if (this.anim) this.anim.play()
    },
    stop() {
      if (this.anim) this.anim.stop()
    },
    pause() {
      if (this.anim) this.anim.pause()
    },
  },
}
</script>
