import { useLocalFileValidator } from '@/Hooks/clip-form/useLocalFileValidator'
import { useEventListener } from '@vueuse/core'
import { onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useClipUrlValidator } from '@/Hooks/clip-form/useClipUrlValidator'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { bytesToMb } from '@/helpers/fileSize'
import logging from '@/logging'

export function useClipFormManager() {
  const fileInput = document.createElement('input')
  fileInput.accept = '.mp4,.mov'
  fileInput.type = 'file'
  onUnmounted(() => {
    fileInput?.remove()
  })

  const validateLocalFile = useLocalFileValidator()
  const error = ref<{ message: string; title: string } | null>(null)
  const router = useRouter()
  const resetEditor = useResetEditor()

  useEventListener(fileInput, 'input', async (e: InputEvent) => {
    e.preventDefault()

    error.value = null
    if (!fileInput) return false

    const file = fileInput.files?.[0]
    if (file) {
      const validationResult = await validateLocalFile(file)

      fileInput.files = null
      fileInput.value = ''

      if (validationResult.error) {
        error.value = validationResult.error
      } else if (validationResult.route) {
        const route = validationResult.route
        const video = document.createElement('video')

        video.src = URL.createObjectURL(file)
        video.onloadedmetadata = async () => {
          logging.trackEvent('LocalFile Selected', {
            duration: video.duration,
            sizeInMb: bytesToMb(file.size),
            fileName: file.name,
          })

          resetEditor()
          await router.push(route)
        }
      }
    }

    return false
  })

  function requestLocalFileUpload() {
    fileInput.click()
  }

  const validateClipUrl = useClipUrlValidator()

  const youtubeVideoId = ref<string | null>(null)

  async function submitClipUrl(clipUrl: string | undefined) {
    const validationResult = validateClipUrl(clipUrl)
    error.value = validationResult.error
    youtubeVideoId.value = validationResult.youtubeVideoId ?? null

    if (validationResult.route) {
      resetEditor()
      await router.push(validationResult.route)
    }
  }

  return { error, youtubeVideoId, requestLocalFileUpload, submitClipUrl }
}
