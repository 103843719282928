import type { LoginProvider } from '@/enums/loginProviders'
import { ref } from 'vue'
import { getPopupFeatures } from '@/helpers/PopupWindowHelper'
import { useUserInfoStore } from '@/store/user/userInfo'
import { revokeTokens } from '@/services/tokens'
import EventBus from '@/eventBus'
import authEvents from '@/events/authEvents'
import logging from '@/logging'
import { storeLoggedInStateCookie } from '@/store/user/storeLoggedInStateCookie'

export default function useLogin(callback: (() => void) | undefined = undefined) {
  const userInfo = useUserInfoStore()

  const authWindow = ref<Window | null>(null)

  let loginPromiseResolve: undefined | ((value: boolean) => void)

  const receiveMessage = async (event: MessageEvent) => {
    const { data } = event
    if (data === 'login-success') {
      authWindow.value?.close()
      await userInfo.updateUserInfo()
      loginPromiseResolve?.(true)
    } else {
      loginPromiseResolve?.(false)
    }
  }

  const login = async (provider: LoginProvider) => {
    const loginUrl = new URL(import.meta.env.VITE_ACCOUNTS_URL + '/signin')

    loginUrl.search = new URLSearchParams({
      method: provider,
      returnUrl: encodeURIComponent(window.location.origin + '/login-success'),
    }).toString()

    // add referralCode is exists
    const refCode = window.localStorage.getItem('refCode')
    if (refCode != null) loginUrl.searchParams.append('ref', refCode)

    window.addEventListener('message', receiveMessage)
    const windowTitle = provider.charAt(0).toUpperCase() + provider.slice(1)
    authWindow.value = window.open(loginUrl.toString(), windowTitle, getPopupFeatures())

    return new Promise((resolve) => {
      loginPromiseResolve = resolve
    })
  }

  const logout = async () => {
    logging.reset()
    await revokeTokens()
    storeLoggedInStateCookie(false)
    window.location.href = import.meta.env.VITE_ACCOUNTS_URL + '/signout?returnUrl=' + encodeURIComponent('https://www.streamladder.com/')
  }

  const openLoginModal = async () => {
    return new Promise((resolve) => {
      EventBus.$emit?.(authEvents.OPEN_LOGIN_DIALOG, resolve)
    })
  }

  const awaitUserLogin = async (customTitle?: string) => {
    if (!userInfo.isAuthenticated) {
      await new Promise<void>((resolve) => {
        EventBus.$emit(authEvents.OPEN_LOGIN_DIALOG, {
          callback: () => resolve(),
          title: customTitle,
        })
      })
    }
    return userInfo.isAuthenticated
  }

  return {
    login,
    logout,
    openLoginModal,
    awaitUserLogin,
  }
}
