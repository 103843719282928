export const dashboardRouteNames = {
  root: 'dashboard-root',
  dashboard: 'dashboard',
  editor: 'create-clip',
  montageMaker: 'montage-maker',
  analytics: 'analytics',
  contentPublisher: 'content-publisher',
  videos: 'videos',
  socials: 'socials',
  support: 'support',
  whatsNew: 'whats-new',
  account: {
    root: 'account',
    settings: 'account/settings',
    invoicing: 'account/invoicing',
    referrals: 'account/referrals',
    templates: 'account/templates',
  },
} as const
