import { defineStore, acceptHMRUpdate } from 'pinia'

interface editorStickersState {
  selectedStickers: StickerData[]
  socials: {
    key: 'twitch' | 'kick' | 'tiktok' | 'instagram' | 'youtube' | 'facebook'
    label: string
    selected: boolean
  }[]
}

export const useEditorStickersStore = defineStore('editorStickers', {
  state: (): editorStickersState => {
    return {
      selectedStickers: [],
      socials: getPreferencesFromStorage([
        { key: 'twitch', label: 'Twitch', selected: true },
        { key: 'kick', label: 'Kick', selected: false },
        { key: 'tiktok', label: 'TikTok', selected: false },
        { key: 'instagram', label: 'Instagram', selected: true },
        { key: 'youtube', label: 'YouTube', selected: true },
        { key: 'facebook', label: 'Facebook', selected: false },
      ]),
    }
  },
  getters: {
    hasStickers: (state: editorStickersState) => state.selectedStickers.length > 0,
    activeSocials(state: editorStickersState) {
      return getActiveSocials(state)
    },
  },
  actions: {
    removeSticker(id: string) {
      const index = this.selectedStickers.findIndex((s) => s.key === id)
      if (index === -1) return
      this.selectedStickers.splice(index, 1)
    },
    getLoggingData() {
      return {
        StickersUsed: this.selectedStickers.map((s) => s.componentName),
        StickerCount: this.selectedStickers.length,
        StickerCountTimed: this.selectedStickers.filter((sticker) => sticker.animationTime !== undefined).length,
      }
    },
  },
})

export function persistSocialsToLocalStorage() {
  const store = useEditorStickersStore()
  store.$subscribe((mutation, state) => {
    localStorage.setItem('__sl_social_settings', JSON.stringify(getActiveSocials(state)))
  })
}

function getPreferencesFromStorage(socials: editorStickersState['socials']) {
  const storedValue = localStorage.getItem('__sl_social_settings')
  if (!storedValue) {
    return socials
  }

  const json = JSON.parse(storedValue)
  return socials.map((s) => ({ ...s, selected: json.includes(s.key) }))
}

function getActiveSocials(state: editorStickersState) {
  return state.socials.filter((s) => s.selected).map((s) => s.key)
}

export type AnimationStyle = 'slide' | 'fade' | 'none' | undefined

export type AnimationTime = 'start' | 'middle' | 'end' | undefined

export interface StickerData {
  key: string
  component: any // Vue component
  x: number
  y: number
  scale: number
  componentName: string
  icon: string
  imageUrl: string
  text: string
  color: string
  variant: string
  htmlContent: string
  visible: boolean
  // Whether this sticker is loaded from a saved-template/state or newly placed
  savedSticker: boolean

  // Animations
  animationStyle: AnimationStyle
  animationTime: AnimationTime
}

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useEditorStickersStore, import.meta.hot))
}
