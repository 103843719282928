<template>
  <base-dialog
    ref="baseDialog"
    :block-escape="anyChildDialogOpen"
    :closeable="true"
    :scrollable="true"
    :show-dismiss-button="true"
    box-class="!overflow-visible flex flex-col"
    size="large"
  >
    <h3 class="m-0 text-3xl font-semibold text-zinc-900 dark:text-zinc-100 lg:text-4xl">From Twitch</h3>
    <ClipSelector @clipSelected="onClipSelected" @childDialogToggled="updateAnyChildDialogOpen" />
  </base-dialog>
</template>

<script>
import ClipSelector from '../ClipSelector.vue'
import BaseDialog from './BaseDialog.vue'

export default {
  components: { ClipSelector, BaseDialog },
  data() {
    return {
      show: false,
      anyChildDialogOpen: false,
    }
  },
  emits: ['clipSelected'],
  methods: {
    open() {
      this.$refs.baseDialog.open()
    },
    close() {
      this.$refs.baseDialog.close()
    },
    async updateAnyChildDialogOpen(isOpen) {
      if (isOpen) {
        this.anyChildDialogOpen = isOpen
      } else {
        setTimeout(() => {
          this.anyChildDialogOpen = isOpen
        }, 100)
      }
    },
    onClipSelected(clipInfo) {
      this.$refs.baseDialog.close()
      this.$emit('clipSelected', clipInfo)
    },
  },
}
</script>

<style lang="scss" scoped></style>
