import { useUserInfoStore } from '@/store/user/userInfo'
import { type ConnectionType, mapConnectionTypeToSocialMedia } from '@/enums/connectionTypes'
import { accountsAxios } from '@/services/axios'
import EventBus from '@/eventBus'
import accountEvents from '@/events/accountEvents'
import toastEvents from '@/events/toastEvents'
import { getPopupFeatures } from '@/helpers/PopupWindowHelper'
import * as Sentry from '@sentry/vue'
import { toRaw } from 'vue'
import mainEvents from '@/events/mainEvents'

export default function useCreateSocialConnection() {

  const userInfoStore = useUserInfoStore()

  const connect = async (platform: ConnectionType) => {

    const authWindow = await createAuthWindow(platform)
    const socialMedia = ({
      tiktok: 'TikTok',
      youtube: 'YouTube',
      instagram: 'Instagram',
    } as const)[platform]

    await userInfoStore.updateUserInfo()

    return new Promise<void>((resolve, reject) => {

      async function handleMessage(message: MessageEvent) {
        if (message.data === 'connection-success') {

          const beforeUpdate = userInfoStore.connections[platform]
          await userInfoStore.updateUserInfo()
          const afterUpdate = userInfoStore.connections[platform]

          authWindow.close()

          console.log('beforeUpdate', beforeUpdate)
          console.log('afterUpdate', afterUpdate)

          if (afterUpdate.length > beforeUpdate.length) {
            EventBus.$emit(accountEvents.CLOSE_CONNECT_SOCIAL_MEDIA_DIALOG)
          } else {
            return reject(`Failed to connect ${socialMedia} account. If you did not see a login screen, please`
             + ` manually log out on the ${socialMedia} website and try again.`)
          }
        } else {
          return reject(`Could not connect ${socialMedia} account, if this was not intentional, please try again.`)
        }

        window.removeEventListener('message', handleMessage)

        await new Promise((resolve) => setTimeout(resolve, 0))

        return resolve()
      }

      window.addEventListener('message', handleMessage)
    })
  }

  const connectUrls = {
    tiktok: `api/tiktok/v2/connect?returnUrl=${window.location.origin}/connection-success`,
    youtube: `api/youtube/connect?returnUrl=${window.location.origin}/connection-success`,
    instagram: `api/instagram/connect?returnUrl=${window.location.origin}/connection-success`,
  }

  async function fetchRedirectUrl(platform: ConnectionType): Promise<string> {
    const response = await accountsAxios.get(connectUrls[platform])
    if (response.status !== 200) throw new Error('Could not get redirect url')

    return response.data.redirectUrl
  }

  async function createAuthWindow(platform: ConnectionType): Promise<Window> {
    // Safari blocks popups not fired directly after user interaction. Async calls, such as `fetchRedirectUrl` should
    // occur *after* window creation.
    const authWindow = window.open(undefined, 'ConnectSocial', getPopupFeatures(800, 800))

    if (!authWindow) {
      throw new Error('Auth window is null')
    }

    authWindow.location = await fetchRedirectUrl(platform)
    return authWindow
  }

  return {
    connect,
  }
}
